import React from "react";
import { Container, Row, Col, Card } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPhone, faMap, faEnvelope } from "@fortawesome/free-solid-svg-icons";

const Contacto = () => {
  return (
    <div id="watermark">
      <Container className="text-center" id="planes">
        <Row>
          <Col lg={4}>
            <Card className="py-5" style={{ minHeight: "400px" }}>
              <Card.Body className="no-link-style py-3">
                <FontAwesomeIcon size="6x" icon={faPhone} color="#63c087" />
              </Card.Body>
              <Card.Text>
                <h2>Telefono de contacto</h2>
              </Card.Text>
              <Card.Text style={{ fontSize: "1.3rem" }}>
                <a href="https://wa.me/+573142771096">(+57) 3142771096</a>
                <br />
                <a href="tel:+573164725531">(+57) 3164725531</a>
              </Card.Text>
            </Card>
          </Col>
          <Col lg={4}>
            <Card className="py-5" style={{ minHeight: "400px" }}>
              <Card.Body className="no-link-style py-3">
                <FontAwesomeIcon size="6x" icon={faMap} color="#63c087" />
              </Card.Body>
              <Card.Text>
                <h2>Dirección</h2>
              </Card.Text>
              <Card.Text style={{ fontSize: "1.3rem" }}>
                Estamos ubicados en Bogotá en la calle 112 # 70 - 29
              </Card.Text>
            </Card>
          </Col>
          <Col lg={4}>
            <Card className="py-5" style={{ minHeight: "400px" }}>
              <Card.Body className="no-link-style py-3">
                <FontAwesomeIcon size="6x" icon={faEnvelope} color="#63c087" />
              </Card.Body>
              <Card.Text>
                <h2>Correo electronico</h2>
              </Card.Text>
              <Card.Text style={{ fontSize: "1.3rem" }}>
                <a href="mailto:aureamedicina2020@gmail.com">
                  aureamedicina2020@gmail.com
                </a>
              </Card.Text>
            </Card>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default Contacto;

import _ from "lodash";
import { createSelector } from "reselect";

export const selectSindromes = (state) => state.sindromes;

export const selectSindromesJerarquizados = createSelector(
  [(state) => state.sindromes, (state) => state.holonmed.sintomasAcupuntura],
  (sindromes, sintomasAcupuntura) => {
    const sintomasAcupunturajerarquizados = sintomasAcupuntura
      .filter((element) => {
        return element.jerarquizado;
      })
      .map((element) => element.codSintoma);

    return sindromes.filter((element) => {
      const codigosSintomasAcupuntura = element.sintomas.map(
        (element) => element.codSintoma
      );
      return (
        sintomasAcupunturajerarquizados.length ===
        _.intersection(
          codigosSintomasAcupuntura,
          sintomasAcupunturajerarquizados
        ).length
      );
    });
  }
);

import axios from "axios";
import React, { useState, useEffect } from "react";
import { Modal, Button, Row, Col, ListGroup } from "react-bootstrap";
import { useDispatch } from "react-redux";
import { ToastContainer, toast } from "react-toastify";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSearch } from "@fortawesome/free-solid-svg-icons";

import "react-toastify/dist/ReactToastify.css";

import { agregarSintomaAcupuntura } from "../../../../state/sintomaAcupuntura/actions";
import { GetSintomaAcupunturaById } from "../../../../services/sintomasAcupuntura";

const ModalSintomas = (props) => {
  const [busqueda, setBusqueda] = useState("");
  const [capitulos, setCapitulos] = useState(null);
  const [sintomas, setSintomas] = useState(null);
  const [capituloSeleccionado, setCapituloSeleccionado] = useState(30);
  const [busquedaGeneral, setBusquedaGeneral] = useState(null);
  const dispatch = useDispatch();

  useEffect(() => {
    const fetchData = async () => {
      const response = await axios.get("/api/libro/3/capitulo");
      setCapitulos(response.data);
    };
    fetchData();
  }, []);

  useEffect(() => {
    const fetchSintomas = async () => {
      const responseSinto = await axios.get(
        `/api/capitulo/${capituloSeleccionado}/sintoma`
      );
      setSintomas(responseSinto.data);
    };
    fetchSintomas();
  }, [capituloSeleccionado]);

  if (!capitulos || !sintomas) {
    return <div>Cargando</div>;
  }

  const handleSubmit = async (evt) => {
    evt.preventDefault();
    if (capituloSeleccionado === -1) {
      const response = await axios.get(`/api/sintoma/nombre/${busqueda}`);
      setBusquedaGeneral(response.data);
    }
  };

  const handleChange = (evt) => {
    const value = evt.target.value;
    setBusqueda(value);
  };

  const searchSintoma = () => {
    return (
      <form onSubmit={handleSubmit}>
        <div className="form-group">
          <div className="d-flex justify-content-start">
            <FontAwesomeIcon icon={faSearch} size="2x" />
            <input
              type="text"
              className="form-control"
              onChange={handleChange}
              value={busqueda}
            />
          </div>
        </div>
      </form>
    );
  };

  const resultadoBusqueda = () => {
    return (
      <div className="overflow-auto" style={{ maxHeight: "700px" }}>
        <ListGroup>
          {busquedaGeneral ? (
            busquedaGeneral.map((item) => (
              <ListGroup.Item
                key={item.codSintoma}
                action
                onClick={async () => {
                  toast(`Se agrego el sintoma ${item.nombre}`);
                  const sintoma = await GetSintomaAcupunturaById(
                    item.codSintoma
                  );
                  dispatch(agregarSintomaAcupuntura(sintoma));
                }}
              >
                {item.nombre}
              </ListGroup.Item>
            ))
          ) : (
            <ListGroup.Item>
              Aqui aparecera el resultado de la busqueda
            </ListGroup.Item>
          )}
        </ListGroup>
      </div>
    );
  };

  const renderFilteredData = () => {
    if (sintomas.length > 0) {
      const dataFiltrada = sintomas.filter((d) =>
        d.nombre.toLowerCase().includes(busqueda.toLowerCase())
      );

      return (
        <div className="overflow-auto" style={{ maxHeight: "700px" }}>
          <ListGroup>
            {dataFiltrada.map((item) => (
              <ListGroup.Item
                key={item.codSintoma}
                action
                onClick={async () => {
                  toast(`Se agrego el sintoma ${item.nombre}`);
                  const sintoma = await GetSintomaAcupunturaById(
                    item.codSintoma
                  );
                  dispatch(agregarSintomaAcupuntura(sintoma));
                }}
              >
                <Row>
                  <Col>{item.nombre}</Col>
                </Row>
              </ListGroup.Item>
            ))}
          </ListGroup>
          <ToastContainer />
        </div>
      );
    }
    return <div>Cargando</div>;
  };

  const renderCapitulos = () => {
    return (
      <div className="overflow-auto" style={{ maxHeight: "750px" }}>
        <ListGroup>
          {capitulos.map((item) => (
            <ListGroup.Item
              className={`${
                item.codCapitulo === capituloSeleccionado ? "active" : null
              }`}
              key={item.codCapitulo}
              onClick={() => {
                setCapituloSeleccionado(item.codCapitulo);

                setBusqueda("");
              }}
              style={{ cursor: "pointer" }}
            >
              {item.titulo}
            </ListGroup.Item>
          ))}
        </ListGroup>
      </div>
    );
  };

  return (
    <Modal
      {...props}
      size="xl"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
          Agregar Sintomas Acupuntura
        </Modal.Title>
        <Button
          variant="primary"
          size="lg"
          block
          onClick={() => {
            setCapituloSeleccionado(-1);
            setBusqueda("");
          }}
        >
          Busqueda general
        </Button>
      </Modal.Header>
      <Modal.Body>
        <Row>
          <Col xs={12} sm={5} lg={3}>
            {renderCapitulos()}
          </Col>
          <Col>
            {searchSintoma()}
            {capituloSeleccionado === -1
              ? resultadoBusqueda()
              : renderFilteredData()}
          </Col>
        </Row>
      </Modal.Body>
    </Modal>
  );
};

export default ModalSintomas;

import React, { useState, useEffect } from "react";
import axios from "axios";
import { Row, Col, Image } from "react-bootstrap";

import Campo from "../detalle/Campo";
import StyledLink from "../../general/StyledLink";

const DetalleAcupuntura = ({ id }) => {
  const [detalle, setDetalle] = useState(null);

  const fetchData = async () => {
    const { data } = await axios.get(`/api/Acupuntura/${id}`);
    setDetalle(data);
  };

  useEffect(() => {
    fetchData();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const validateForm = () => {
    return {};
  };

  if (detalle) {
    const camposPunto = [
      {
        id: "punto",
        type: "text",
        placeholder: detalle.punto,
      },
    ];
    const camposAbreviacion = [
      {
        id: "abreviacion",
        type: "text",
        placeholder: detalle.abreviacion,
      },
    ];

    const camposChino = [
      {
        id: "nombreChino",
        type: "text",
        placeholder: detalle.nombreChino,
      },
    ];
    const camposCastellano = [
      {
        id: "nombreAlterno",
        type: "text",
        placeholder: detalle.nombreAlterno,
      },
    ];

    const camposLocalizacion = [
      {
        id: "localizacion",
        type: "textarea",
        placeholder: detalle.localizacion,
      },
    ];
    const camposFuncion = [
      {
        id: "funcion",
        type: "textarea",
        placeholder: detalle.funcion,
      },
    ];
    const camposMetodo = [
      {
        id: "metodo",
        type: "textarea",
        placeholder: detalle.metodo,
      },
    ];
    const camposIndicaciones = [
      {
        id: "indicaciones",
        type: "textarea",
        placeholder: detalle.indicaciones,
      },
    ];

    return (
      <Row>
        <Col sm={12}>
          <StyledLink to="/admin/Acupuntura">
            Listado Puntos de Acupuntura
          </StyledLink>
          <h1>Punto de acupuntura: {id}</h1>
          <br />
        </Col>
        <Col sm={12} md={6} lg={3}>
          <Campo
            titulo={"Punto Acupuntura"}
            detail={detalle.punto}
            campos={camposPunto}
            validateForm={validateForm}
            apiRoute={`/api/Acupuntura/${id}`}
          />
        </Col>
        <Col sm={12} md={6} lg={3}>
          <Campo
            titulo={"nombre Chino"}
            detail={detalle.nombreChino}
            editable={true}
            campos={camposChino}
            validateForm={validateForm}
            apiRoute={`/api/Acupuntura/${id}`}
          />
        </Col>
        <Col sm={12} md={6} lg={3}>
          <Campo
            titulo={"nombre Alterno"}
            detail={detalle.nombreAlterno}
            editable={true}
            campos={camposCastellano}
            validateForm={validateForm}
            apiRoute={`/api/Acupuntura/${id}`}
          />
        </Col>
        <Col sm={12} md={6} lg={3}>
          <Campo
            titulo={"Abrevación Punto"}
            detail={detalle.abreviacion}
            editable={true}
            campos={camposAbreviacion}
            validateForm={validateForm}
            apiRoute={`/api/Acupuntura/${id}`}
          />
        </Col>
        <Row>
          {detalle.imagen.map((img) => {
            return (
              <Col md={3} key={img}>
                <Image src={img} alt="s3-img" fluid />
              </Col>
            );
          })}
        </Row>
        <Col sm={12}>
          <Campo
            titulo={"Localización Punto"}
            withPreview={true}
            detail={detalle.localizacion}
            editable={true}
            campos={camposLocalizacion}
            validateForm={validateForm}
            apiRoute={`/api/Acupuntura/${id}`}
          />
        </Col>
        <Col sm={12}>
          <Campo
            titulo={"Funcion Punto"}
            detail={detalle.funcion}
            editable={true}
            campos={camposFuncion}
            validateForm={validateForm}
            apiRoute={`/api/Acupuntura/${id}`}
          />
        </Col>
        <Col sm={12}>
          <Campo
            titulo={"Metodo Punto"}
            detail={detalle.metodo}
            editable={true}
            campos={camposMetodo}
            validateForm={validateForm}
            apiRoute={`/api/Acupuntura/${id}`}
          />
        </Col>
        <Col sm={12}>
          <Campo
            titulo={"Indicaciones Punto"}
            detail={detalle.indicaciones}
            editable={true}
            campos={camposIndicaciones}
            validateForm={validateForm}
            apiRoute={`/api/Acupuntura/${id}`}
          />
        </Col>
      </Row>
    );
  }
  return <div>Cargando</div>;
};

export default DetalleAcupuntura;

import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Row, Col, Button, Form, ListGroup } from 'react-bootstrap';

import ModalConfirmar from '../../general/ModalConfirmar';
import SearchBar from '../../general/SearchBar';

const TratamientoAcupuntura = ({ id, acupuntura }) => {
  const [puntos, setPuntos] = useState(null);
  const [agregar, setAgregar] = useState(false);
  const [filtered, setFiltered] = useState([]);

  useEffect(() => {
    const fetchAcupuntura = async () => {
      const { data } = await axios.get('/api/acupuntura');
      setPuntos(data);
      setFiltered(data);
    };
    fetchAcupuntura();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const agregarPunto = async (punto) => {
    const send = {
      codAcupuntura: punto.codAcupuntura,
      punto: punto.abreviacion,
      codSindrome: id,
    };
    try {
      const res = await axios.post('/api/SindromeAcupuntura', send);
      alert(res.statusText);
    } catch (err) {
      alert('El punto seleccionado ya está relacionado con el Síndrome');
    }
  };

  const borrar = async (codSintoma) => {
    await axios.delete(`/api/sindrome/${id}/acupuntura/${codSintoma}`);
  };

  const renderList = () => {
    return (
      <ListGroup style={{ maxHeight: '300px', overflowY: 'scroll' }}>
        {filtered.map((item) => {
          return (
            <ListGroup.Item
              key={item.codAcupuntura}
              action
              onClick={() => agregarPunto(item)}
            >
              {item.punto}
            </ListGroup.Item>
          );
        })}
      </ListGroup>
    );
  };

  const filterFunction = (value) => {
    if (value.length > 0) {
      setFiltered(
        puntos.filter((acu) =>
          acu.punto.toLowerCase().includes(`${value}`.toLowerCase())
        )
      );
    } else {
      setFiltered(puntos);
    }
  };

  return (
    <React.Fragment>
      <h4>Acupuntural</h4>
      {acupuntura
        ? acupuntura.map(({ observaciones, puntos }) => {
            return (
              <React.Fragment key={observaciones}>
                <h5>{observaciones}</h5>
                {puntos ? (
                  <ListGroup>
                    {puntos.map((item) => {
                      return (
                        <ListGroup.Item key={item.codAcupuntura}>
                          {item.punto}
                          <ModalConfirmar
                            borrar={() => borrar(item.codAcupuntura)}
                            cod={id}
                            nombre={item.punto}
                          />
                        </ListGroup.Item>
                      );
                    })}
                  </ListGroup>
                ) : null}
              </React.Fragment>
            );
          })
        : null}

      <Button
        variant="warning"
        onClick={() => {
          setAgregar(!agregar);
        }}
      >
        Relacionar con Puntos de Acupuntura
      </Button>
      {agregar ? (
        <div>
          <SearchBar
            title="Buscar acupuntura"
            filterFunction={filterFunction}
          />
          {renderList()}
        </div>
      ) : null}
    </React.Fragment>
  );
};

const TratamientoHomeosiniatria = ({ id, homeosiniatria }) => {
  const [agregar, setAgregar] = useState(false);
  const [value, setValue] = useState('');

  const borrar = async (codSintoma) => {
    await axios.delete(`/api/sindrome/${id}/homeosiniatria/${codSintoma}`);
  };

  const handleSubmit = async (evt) => {
    evt.preventDefault();
    try {
      const res = await axios.post('/api/SindromeHomeosiniatria', {
        codSindrome: id,
        value,
      });
      alert(res.statusText);
    } catch (err) {
      alert('Error en el servidor, vuelva a intentar en unos minutos');
    }
  };

  const handleChange = (evt) => {
    setValue(evt.target.value);
  };

  return (
    <React.Fragment>
      <h4>Homeosiniatria</h4>
      {homeosiniatria ? (
        <ListGroup>
          {homeosiniatria.map((dato) => {
            return (
              <ListGroup.Item key={dato}>
                {dato}
                <ModalConfirmar
                  borrar={() => borrar(dato)}
                  cod={dato}
                  nombre={dato}
                />
              </ListGroup.Item>
            );
          })}
        </ListGroup>
      ) : null}

      <Button
        variant="warning"
        onClick={() => {
          setAgregar(!agregar);
        }}
      >
        Agregar relacion homeosiniatria
      </Button>
      {agregar ? (
        <Form onSubmit={handleSubmit}>
          <Form.Group controlId="SearchBar">
            <Form.Control type="text" onChange={handleChange} />
          </Form.Group>
        </Form>
      ) : null}
    </React.Fragment>
  );
};

const TratamientoAntihomotoxicos = ({ id, antihomotoxicos }) => {
  const [agregar, setAgregar] = useState(false);
  const [value, setValue] = useState('');

  const borrar = async (codSintoma) => {
    await axios.delete(`/api/sindrome/${id}/antihomotoxicos/${codSintoma}`);
  };

  const handleSubmit = async (evt) => {
    evt.preventDefault();
    try {
      const res = await axios.post('/api/SindromeAntihomotoxicos', {
        codSindrome: id,
        value,
      });
      alert(res.statusText);
    } catch (err) {
      alert('Error en el servidor, vuelva a intentar en unos minutos');
    }
  };

  const handleChange = (evt) => {
    setValue(evt.target.value);
  };

  return (
    <React.Fragment>
      <h4>Antihomotóxicos</h4>
      {antihomotoxicos ? (
        <ListGroup>
          {antihomotoxicos.map((dato) => {
            return (
              <ListGroup.Item key={dato}>
                {dato}
                <ModalConfirmar
                  borrar={() => borrar(dato)}
                  cod={dato}
                  nombre={dato}
                />
              </ListGroup.Item>
            );
          })}
        </ListGroup>
      ) : null}

      <Button
        variant="warning"
        onClick={() => {
          setAgregar(!agregar);
        }}
      >
        Agregar relacion antihomotóxicos
      </Button>
      {agregar ? (
        <Form onSubmit={handleSubmit}>
          <Form.Group controlId="SearchBar">
            <Form.Control type="text" onChange={handleChange} />
          </Form.Group>
        </Form>
      ) : null}
    </React.Fragment>
  );
};

const RelacionTratamiento = ({ id, tratamiento }) => {
  return (
    <Row>
      <Col sm={12}>
        <p style={{ fontWeight: 'bold' }}>Tratamiento</p>
      </Col>
      <Col xs={12}>
        <TratamientoAcupuntura
          id={id}
          acupuntura={tratamiento ? tratamiento.acupuntura : null}
        />
      </Col>
      <Col xs={12} sm={6}>
        <TratamientoHomeosiniatria
          id={id}
          homeosiniatria={tratamiento ? tratamiento.homeosiniatria : null}
        />
      </Col>
      <Col xs={12} sm={6}>
        <TratamientoAntihomotoxicos
          id={id}
          antihomotoxicos={tratamiento ? tratamiento.antihomotoxicos : null}
        />
      </Col>
    </Row>
  );
};

export default RelacionTratamiento;

import React, { useState, useEffect } from "react";
import axios from "axios";
import { Table, Row, Col, Button } from "react-bootstrap";

import SearchBar from "../../general/SearchBar";
import ModalConfirmar from "../../general/ModalConfirmar";
import StyledLink from "../../general/StyledLink";

const ListaSintomaAcupuntura = () => {
  const [data, setData] = useState(null);
  const [dataFilter, setDataFilter] = useState(null);

  const fetchData = async () => {
    const response = await axios.get("/api/Sintoma");
    setData(response.data);
    setDataFilter(response.data);
  };

  useEffect(() => {
    fetchData();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const filterFunction = (value) => {
    if (value.length > 0) {
      setDataFilter(
        data.filter((item) =>
          item.nombre.toLowerCase().includes(`${value}`.toLowerCase())
        )
      );
    } else {
      setDataFilter(data);
    }
  };

  const borrar = async (cod) => {
    await axios.delete(`/api/Sintoma/${cod}`);
    await axios.delete(`/api/Sintoma/${cod}/SintomaRep`);
    fetchData();
  };

  return (
    <Row>
      <Col xs={6} md={10}>
        <StyledLink to="/admin">Tablas</StyledLink>
      </Col>
      <Col xs={6} md={2}>
        <StyledLink to={`/admin/nuevo/registro/Sintoma`}>
          <Button variant="success">Agregar Nuevo Sintoma</Button>
        </StyledLink>
      </Col>
      <Col xs={12}>
        <h1>Listado Sintomas Acupuntura</h1>
        <SearchBar title="Buscar Sintoma" filterFunction={filterFunction} />
        <br />
        <Table striped bordered hover>
          <thead>
            <tr>
              <th>Código Sintoma</th>
              <th>Nombre Sintoma</th>
              <th>Borrar Sintoma</th>
            </tr>
          </thead>
          <tbody>
            {dataFilter
              ? dataFilter.map((item) => {
                  return (
                    <tr key={item.codSintoma}>
                      <td>
                        <StyledLink to={`/admin/Sintoma/${item.codSintoma}`}>
                          {item.codSintoma}
                        </StyledLink>
                      </td>
                      <td>{item.nombre}</td>
                      <td>
                        <ModalConfirmar
                          borrar={() => borrar(item.codSintoma)}
                          cod={item.codSintoma}
                          nombre={item.nombre}
                        />
                      </td>
                    </tr>
                  );
                })
              : null}
          </tbody>
        </Table>
      </Col>
    </Row>
  );
};

export default ListaSintomaAcupuntura;

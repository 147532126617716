import React, { useState, useEffect } from "react";
import axios from "axios";
import { useDispatch } from "react-redux";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlusCircle } from "@fortawesome/free-solid-svg-icons";
import { Table, Button, Modal } from "react-bootstrap";

import { GetSintomaHomeopatiaById } from "../../../../../../services/sintomasHomeopatia";
import { agregarSintomaHomeopatia } from "../../../../../../state/sintoma/actions";

const ModalRelacion = (props) => {
  const [relacion, setRelacion] = useState(null);
  const [capitulos, setCapitulos] = useState(null);
  const dispatch = useDispatch();

  useEffect(() => {
    const fetchData = async () => {
      const response = await axios.get(`/api/sintoma/${props.cod}/sintomaRep`);
      setRelacion(response.data);

      const chaps = await axios.get("/api/libro/3/capitulo");
      setCapitulos(chaps.data);
    };
    fetchData();
  }, [props.cod]);

  if (!capitulos || !relacion) {
    return (
      <Modal
        {...props}
        aria-labelledby="contained-modal-title-vcenter"
        size="lg"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            Relacionar con Sintomas de Homeopatia
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>Cargando</Modal.Body>
      </Modal>
    );
  }

  const getNombreCapitulo = (id) => {
    const capitulo = capitulos.find((element) => element.codCapitulo === id);
    return capitulo.titulo;
  };

  return (
    <Modal
      {...props}
      aria-labelledby="contained-modal-title-vcenter"
      size="lg"
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
          Relacionar con Sintomas de Homeopatia
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Table striped bordered hover>
          <thead>
            <tr>
              <th>Capitulo</th>
              <th>Sintoma</th>
              <th>Agregar</th>
            </tr>
          </thead>
          <tbody>
            {relacion.map((item, index) => {
              return (
                <tr key={index}>
                  <td>{getNombreCapitulo(item.capitulo)}</td>
                  <td>{item.nombre}</td>
                  <td>
                    <Button
                      variant="default"
                      data-toggle="tooltip"
                      data-placement="top"
                      title="Agregar sintoma"
                      onClick={async () => {
                        alert(
                          `Se agrego el sintoma de homeopatia ${item.nombre}`
                        );
                        const sintoma = await GetSintomaHomeopatiaById(
                          item.repCodSintoma
                        );
                        dispatch(agregarSintomaHomeopatia(sintoma));
                      }}
                    >
                      <FontAwesomeIcon icon={faPlusCircle} />
                    </Button>
                  </td>
                </tr>
              );
            })}
          </tbody>
        </Table>
      </Modal.Body>
    </Modal>
  );
};

export default ModalRelacion;

import React, { useEffect, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye } from "@fortawesome/free-solid-svg-icons";
import { ToastContainer } from "react-toastify";
import { Toast, Button, Table } from "react-bootstrap";

import StyledLink from "../../general/StyledLink";
import { selectPuntosAcupuntura } from "../../../state/punto/selector";
import { useDispatch, useSelector } from "react-redux";
import {
  selectCodigosSindromes,
  selectSindromesJerarquizados,
} from "../../../state/sindrome/selectors";
import { puntosSugeridos } from "../../../services/puntos";
import { agregarPuntos } from "../../../state/punto/actions";

const ToastData = ({ title, sindromes }) => {
  const [show, setShow] = useState(false);

  return (
    <>
      <Button
        variant="default"
        data-toggle="tooltip"
        data-placement="top"
        title={`Ver ${title}`}
        onClick={() => {
          setShow(!show);
        }}
      >
        <FontAwesomeIcon icon={faEye} />
      </Button>
      {show ? (
        <Toast show={show} onClose={() => setShow(!show)}>
          <Toast.Header>
            <img
              src="holder.js/20x20?text=%20"
              className="rounded mr-2"
              alt=""
            />
            <strong className="mr-auto">{title}</strong>
          </Toast.Header>
          <Toast.Body>
            {sindromes.map(({ cod, nombre }) => {
              return (
                <div key={cod} className="d-flex justify-content-between">
                  <p>{nombre}</p>
                </div>
              );
            })}
          </Toast.Body>
        </Toast>
      ) : null}

      <ToastContainer />
    </>
  );
};

const TablaPuntos = () => {
  const puntos = useSelector(selectPuntosAcupuntura);
  const sindromes = useSelector(selectSindromesJerarquizados);
  const dispatch = useDispatch();

  useEffect(() => {
    const cargarPuntos = async () => {
      const codigosSinndromes = sindromes.map((element) => {
        return element.codSindrome;
      });
      const puntos = await puntosSugeridos(codigosSinndromes);
      dispatch(agregarPuntos(puntos));
    };
    cargarPuntos();
  }, [sindromes, dispatch]);

  const renderBody = () => {
    return puntos.map((item, index) => {
      if (index < 10) {
        return (
          <tr key={index}>
            <td>
              <StyledLink to={`/libro/3#${item.codPunto}`}>
                {item.nombre}
              </StyledLink>
            </td>
            <td>
              <div className="d-flex justify-content-between">
                {item.total}
                <ToastData title="sindromes" sindromes={item.sindrome} />
              </div>
            </td>
          </tr>
        );
      }
      return null;
    });
  };

  if (puntos.length === 0) {
    return (
      <p>
        Agrega sintomas para ver informacion relacionada con los puntos de
        acupuntura
      </p>
    );
  }

  return (
    <Table striped>
      <thead>
        <tr>
          <th scope="col">Abreviacion Punto</th>
          <th scope="col">coincidencias en sindromes</th>
        </tr>
      </thead>
      <tbody>{renderBody()}</tbody>
    </Table>
  );
};

export default TablaPuntos;

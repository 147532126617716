import React from "react";
import { Card } from "react-bootstrap";

import TablaSindromes from "./TablaSindromes";

const Sindromes = () => {
  return (
    <Card>
      <Card.Header as="h3">Sindromes/Enfermedades</Card.Header>
      <Card.Body>
        <TablaSindromes />
      </Card.Body>
    </Card>
  );
};

export default Sindromes;

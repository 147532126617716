import React, { useState } from "react";
import { Form, Button, Row, Col } from "react-bootstrap";
import _ from "lodash";
import axios from "axios";
import ModalRegistro from "../ModalRegistro";

const Ginecologia = ({ values, setValues }) => {
  const camposGinecologia = [
    {
      label: "Embarazos",
      id: "embarazos",
    },
    {
      label: "Partos normales",
      id: "partos",
    },
    {
      label: "Cesareas",
      id: "cesareas",
    },
    {
      label: "Abortos",
      id: "abortos",
    },
    {
      label: "Planificacion",
      id: "planificacion",
    },
    {
      label: "Menarquia",
      id: "menarquia",
    },
    {
      label: "Ciclos",
      id: "ciclos",
    },
    {
      label: "Citologia",
      id: "citologia",
    },
    {
      label: "Mamografia",
      id: "mamografia",
    },
  ];

  const handleChange = (evt) => {
    let copy = { ...values };
    copy.ginecologia[0] = {
      ...copy.ginecologia[0],
      [evt.target.name]: evt.target.value,
    };
    setValues(copy);
  };

  const GINECOBSTETRICOS = [0, 1, 2, 3];
  return (
    <>
      <Form.Group as={Row}>
        {GINECOBSTETRICOS.map((num) => {
          return (
            <React.Fragment key={num}>
              <Form.Label
                htmlFor={camposGinecologia[num].id}
                column
                sm={4}
                md={2}
              >
                {camposGinecologia[num].label}
              </Form.Label>
              <Col sm={2} md={1}>
                <Form.Control
                  type="number"
                  id={camposGinecologia[num].id}
                  name={camposGinecologia[num].id}
                  onChange={handleChange}
                  value={values.ginecologia[0][camposGinecologia[num].id]}
                />
              </Col>
            </React.Fragment>
          );
        })}
        {GINECOBSTETRICOS.map((num) => {
          return (
            <React.Fragment key={num}>
              <Form.Label
                htmlFor={camposGinecologia[num + 4].id}
                column
                sm={3}
                md={2}
              >
                {camposGinecologia[num + 4].label}
              </Form.Label>
              <Col sm={9} md={4}>
                <Form.Control
                  type="text"
                  id={camposGinecologia[num + 4].id}
                  name={camposGinecologia[num + 4].id}
                  onChange={handleChange}
                  value={values.ginecologia[0][camposGinecologia[num + 4].id]}
                />
              </Col>
            </React.Fragment>
          );
        })}
      </Form.Group>
      <Form.Group>
        <Form.Label htmlFor={camposGinecologia[8].id}>
          {camposGinecologia[8].label}
        </Form.Label>

        <Form.Control
          type="number"
          id={camposGinecologia[8].id}
          name={camposGinecologia[8].id}
          onChange={handleChange}
          value={values.ginecologia[0][camposGinecologia[8].id]}
        />
      </Form.Group>
    </>
  );
};

const RevisionSistema = ({ campo, values, setValues }) => {
  const handleChange = (evt) => {
    let copy = { ...values };
    copy.revisionSistemas[campo.num] = {
      sistema: campo.id,
      detalle: evt.target.value,
    };
    setValues(copy);
  };

  return (
    <Form.Group as={Row}>
      <Form.Label htmlFor={campo.id} column sm={4} md={3}>
        {campo.label}
      </Form.Label>
      <Col sm={8} md={9}>
        <Form.Control
          as="textarea"
          rows={3}
          id={campo.id}
          name={campo.id}
          onChange={handleChange}
          value={values.antecedentesPer[campo.id]}
          placeholder={campo.placeholder}
        />
      </Col>
    </Form.Group>
  );
};

const AntecedentePersonal = ({ campo, values, setValues, errors }) => {
  const [showAntecedente, setShowAntecedente] = useState(false);

  const antecedenteSelect = () => {
    if (showAntecedente) {
      let copy = { ...values };
      copy.antecedentesPer[campo.num] = {
        antecedente: campo.id,
        detalle: "No refiere",
      };
      setValues(copy);
    } else {
      let copy = { ...values };
      copy.antecedentesPer[campo.num] = {
        antecedente: campo.id,
        detalle: "",
      };
      setValues(copy);
    }
    setShowAntecedente(!showAntecedente);
  };

  const handleChange = (evt) => {
    let copy = { ...values };
    copy.antecedentesPer[campo.num] = {
      antecedente: campo.id,
      detalle: evt.target.value,
    };
    setValues(copy);
  };

  return (
    <Form.Group as={Row}>
      <Form.Label htmlFor={campo.id} column sm={3} md={2}>
        {campo.label}{" "}
      </Form.Label>
      <Col sm={1}>
        <Form.Check type="checkbox" onChange={antecedenteSelect} />
      </Col>
      {showAntecedente ? (
        <Col sm={8} md={9}>
          <Form.Control
            id={campo.id}
            name={campo.id}
            onChange={handleChange}
            value={values.antecedentesPer[campo.id]}
            className={
              errors.antecedentesPer
                ? errors.antecedentesPer[campo.num]
                  ? "form-error"
                  : ""
                : ""
            }
          />

          <p className="error-small">
            {errors.antecedentesPer ? errors.antecedentesPer[campo.num] : null}
          </p>
        </Col>
      ) : null}
    </Form.Group>
  );
};

const ExamenGeneral = ({ id, values, setValues, errors }) => {
  const campo = {
    id: "general",
    label: "Estado General",

    detalle: [
      { id: "estado", label: "Estado General" },
      { id: "peso", label: "Peso *", placeholder: "Kg" },
      { id: "talla", label: "Talla *", placeholder: "mts" },
      { id: "tension", label: "Tensión", placeholder: "mm de hg" },
      {
        id: "frecCardiaca",
        label: "Frecuencia Cardiaca",
        placeholder: "latidos/minutos",
      },
      {
        id: "frecRespiratoria",
        label: "Frecuencia Respiratoria",
        placeholder: "respiraciones/minutos",
      },
      { id: "temperatura", label: "Temperatura", placeholder: "°C" },
      { id: "pulso", label: "Pulso" },
      { id: "lengua", label: "Lengua" },
      { id: "tez", label: "Tez" },
    ],
  };

  const handleChange = (evt) => {
    let copy = { ...values };
    copy[id][0]["detalle"][evt.target.name] = evt.target.value;
    setValues(copy);
  };

  return (
    <Form.Group as={Row}>
      <Form.Label htmlFor={campo.id} column sm={2} md={2}>
        {campo.label}
      </Form.Label>
      <Col sm={10} md={10}>
        <Row>
          <Col>
            <Form.Label htmlFor={campo.detalle[0].id}>
              {campo.detalle[0].label}
            </Form.Label>
            <Form.Control
              id={campo.id}
              name={campo.id}
              onChange={handleChange}
              value={values[id][campo.id]}
              placeholder={campo.placeholder}
            />
          </Col>
        </Row>
        <Form.Group as={Row}>
          <Form.Label column sm={2} md={2}>
            {campo.detalle[1].label}
          </Form.Label>
          <Col sm={4} md={2}>
            <Form.Control
              id={campo.detalle[1].id}
              name={campo.detalle[1].id}
              onChange={handleChange}
              value={values[id][campo.detalle[1].id]}
              placeholder={campo.detalle[1].placeholder}
            />
            <p className="error-small">{errors[campo.detalle[1].id]}</p>
          </Col>

          <Form.Label column sm={2} md={2}>
            {campo.detalle[2].label}
          </Form.Label>
          <Col sm={4} md={2}>
            <Form.Control
              id={campo.detalle[2].id}
              name={campo.detalle[2].id}
              onChange={handleChange}
              value={values[id][campo.detalle[2].id]}
              placeholder={campo.detalle[2].placeholder}
            />
            <p className="error-small">{errors[campo.detalle[2].id]}</p>
          </Col>
          <Form.Label column sm={2} md={2}>
            {campo.detalle[3].label}
          </Form.Label>
          <Col sm={4} md={2}>
            <Form.Control
              id={campo.detalle[3].id}
              name={campo.detalle[3].id}
              onChange={handleChange}
              value={values[id][campo.detalle[3].id]}
              placeholder={campo.detalle[3].placeholder}
            />
          </Col>
          <Form.Label column sm={2} md={2}>
            {campo.detalle[4].label}
          </Form.Label>
          <Col sm={4} md={2}>
            <Form.Control
              id={campo.detalle[4].id}
              name={campo.detalle[4].id}
              onChange={handleChange}
              value={values[id][campo.detalle[4].id]}
              placeholder={campo.detalle[4].placeholder}
            />
          </Col>

          <Form.Label column sm={2} md={2}>
            {campo.detalle[5].label}
          </Form.Label>
          <Col sm={4} md={2}>
            <Form.Control
              id={campo.detalle[5].id}
              name={campo.detalle[5].id}
              onChange={handleChange}
              value={values[id][campo.detalle[5].id]}
              placeholder={campo.detalle[5].placeholder}
            />
          </Col>
          <Form.Label column sm={2} md={2}>
            {campo.detalle[6].label}
          </Form.Label>
          <Col sm={4} md={2}>
            <Form.Control
              id={campo.detalle[6].id}
              name={campo.detalle[6].id}
              onChange={handleChange}
              value={values[id][campo.detalle[6].id]}
              placeholder={campo.detalle[6].placeholder}
            />
          </Col>
        </Form.Group>
        <Row>
          <Col>
            <Form.Label>{campo.detalle[7].label}</Form.Label>
            <Form.Control
              id={campo.detalle[7].id}
              name={campo.detalle[7].id}
              onChange={handleChange}
              value={values[id][campo.detalle[7].id]}
            />
          </Col>
        </Row>
        <Row>
          <Col>
            <Form.Label>{campo.detalle[8].label}</Form.Label>
            <Form.Control
              id={campo.detalle[8].id}
              name={campo.detalle[8].id}
              onChange={handleChange}
              value={values[id][campo.detalle[8].id]}
            />
          </Col>
        </Row>
      </Col>
    </Form.Group>
  );
};

const ExamenSistema = ({ campo, values, setValues, id }) => {
  const handleChange = (evt) => {
    let copy = { ...values };
    copy[id][campo.num] = {
      sistema: campo.id,
      detalle: evt.target.value,
    };
    setValues(copy);
  };

  return (
    <Form.Group as={Row}>
      <Form.Label htmlFor={campo.id} column sm={4} md={3}>
        {campo.label}
      </Form.Label>
      <Col sm={8} md={9}>
        <Form.Control
          as="textarea"
          rows={3}
          id={campo.id}
          name={campo.id}
          onChange={handleChange}
          value={values[id][campo.id]}
          placeholder={campo.placeholder}
        />
      </Col>
    </Form.Group>
  );
};

const RegistrarPrimeraCita = ({ id, update, setUpdate, ginecologia }) => {
  const [show, setShow] = useState(false);
  const [respuestaFormulario, setRespuestaFormulario] = useState("");
  const [values, setValues] = useState({
    sangre: "O-",
    motivo: "",
    enfermedad: "",
    antecedentesFam: "",
    antecedentesPer: [
      { antecedente: "patologicos", detalle: "no refiere" },
      { antecedente: "quirurgicos", detalle: "no refiere" },
      { antecedente: "traumaticos", detalle: "no refiere" },
      { antecedente: "alergicos", detalle: "no refiere" },
      { antecedente: "transfusionales", detalle: "no refiere" },
      { antecedente: "hospitaltarios", detalle: "no refiere" },
      { antecedente: "farmacologicos", detalle: "no refiere" },
    ],
    revisionSistemas: [
      { sistema: "nervioso", detalle: "" },
      { sistema: "oftalmologicos", detalle: "" },
      { sistema: "otorrinolaringologia", detalle: "" },
      { sistema: "cardiopulmonar", detalle: "" },
      { sistema: "gastrointestinal", detalle: "" },
      { sistema: "urinario", detalle: "" },
      { sistema: "osteomuscular", detalle: "" },
      { sistema: "endocrino", detalle: "" },
      { sistema: "piel", detalle: "" },
      { sistema: "psiquismo", detalle: "" },
    ],
    vacunas: "",
    ginecologia: [
      {
        embarazos: 0,
        partos: 0,
        cesareas: 0,
        abortos: 0,
        planificacion: "",
        menarquia: "",
        ciclos: "",
        citologia: "",
        mamografia: "",
      },
    ],
    examenSistemas: [
      {
        sistema: "general",
        detalle: {
          estado: "",
          peso: 0,
          talla: 0,
          tension: 0,
          frecCardiaca: 0,
          frecRespiratoria: 0,
          temperatura: 0,
          pulso: 0,
          lengua: "",
          tez: "",
        },
      },
      { sistema: "cabeza", detalle: "" },
      { sistema: "cuello", detalle: "" },
      { sistema: "tiroides", detalle: "" },
      { sistema: "oftalmologicos", detalle: "" },
      { sistema: "otirrinolaringologia", detalle: "" },
      { sistema: "corazon", detalle: "" },
      { sistema: "pulmones", detalle: "" },
      { sistema: "abdomen", detalle: "" },
      { sistema: "genitales", detalle: "" },
      { sistema: "osteoarticular", detalle: "" },
      { sistema: "neurologico", detalle: "" },
      { sistema: "piel", detalle: "" },
    ],
    examenEnergetico: "",
    diagnostico: "",
    tratamiento: "",
  });

  const [errors, setErrors] = useState({});

  const campos = [
    {
      label: "Tipo de Sangre",
      id: "sangre",
      type: "select",
      placeholder: "O-",
      options: [
        { id: "O-", label: "O-" },
        { id: "O+", label: "O+" },
        { id: "A-", label: "A-" },
        { id: "A+", label: "A+" },
        { id: "B-", label: "B-" },
        { id: "B+", label: "B+" },
        { id: "AB-", label: "AB-" },
        { id: "AB+", label: "AB+" },
      ],
    },

    {
      label: "Motivo consulta *",
      id: "motivo",
      type: "textarea",
    },

    {
      label: "Enfermedad actual",
      id: "enfermedad",
      type: "textarea",
    },
    {
      label: "Antecedentes Familiares *",
      id: "antecedentesFam",
      type: "textarea",
      placeholder:
        "Ingresa detalles sobre los antecedentes familiares del paciente",
    },
    {
      label: "Vacunas",
      id: "vacunas",
      type: "textarea",
    },
    {
      label: "Examen Físico por Sistemas",
      id: "examenSistemas",
    },
    {
      label: "Examen Energetico",
      id: "examenEnergetico",
    },
    {
      label: "Diagnostico *",
      id: "diagnostico",
    },
    {
      label: "Plan y tratamiento *",
      id: "tratamiento",
    },
  ];

  const handleChange = (evt) => {
    setValues({
      ...values,
      [evt.target.name]: evt.target.value,
    });
  };

  const handleSubmit = async (evt) => {
    evt.preventDefault();
    let err = validateForm(values);
    setErrors(err);
    if (_.isEmpty(err)) {
      try {
        await axios.post(`/api/paciente/${id}/primeraCita`, values);
        setRespuestaFormulario("correcto");
      } catch (err) {
        setRespuestaFormulario("Error del servidor");
      }
    } else {
      setRespuestaFormulario(
        "Revisa los campos, valida la información y vuelve a intentar"
      );
    }
  };

  const validateForm = (values) => {
    const err = {};
    let errorPersonales = false;

    if (values.motivo.length === 0) {
      err.motivo = "Ingresa el motivo de la consulta";
    }
    if (values.antecedentesFam.length === 0) {
      err.antecedentesFam =
        "Ingresa un comentario sobre los antecedentes del paciente";
    }
    err.antecedentesPer = [null, null, null, null, null, null, null];
    for (let i = 0; i < 7; i++) {
      if (values.antecedentesPer[i].detalle.length === 0) {
        err.antecedentesPer[i] =
          "Ingresa un comentario sobre los antecedentes del paciente";
        errorPersonales = true;
      }
    }
    if (!errorPersonales) {
      delete err.antecedentesPer;
    }

    if (values.diagnostico.length === 0) {
      err.diagnostico = "Ingresa un diagnostico";
    }
    if (isNaN(values.examenSistemas[0].detalle.peso)) {
      err.talla = "Ingresa un peso valido (solo números) ej: 72.1";
    } else if (values.examenSistemas[0].detalle.peso === 0) {
      err.peso = "Ingresa el peso del paciente";
    } else if (
      parseFloat(values.examenSistemas[0].detalle.peso) < 1 ||
      parseFloat(values.examenSistemas[0].detalle.peso) > 500
    ) {
      err.peso =
        "Revisa el peso ingresado, debe de estar en kilogramos ej: 72.1";
    }

    if (isNaN(values.examenSistemas[0].detalle.talla)) {
      err.talla = "Ingresa una altura valida (solo números) ej: 1.83";
    } else if (values.examenSistemas[0].detalle.talla === 0) {
      err.talla = "Ingresa la altura del paciente";
    } else if (
      parseFloat(values.examenSistemas[0].detalle.talla) < 0.1 ||
      parseFloat(values.examenSistemas[0].detalle.talla) > 3
    ) {
      err.talla = "Revisa la talla ingresada, debe estar en metros ej: 1.83";
    }

    if (values.tratamiento.length === 0) {
      err.tratamiento = "Ingresa el plan y tratamiento para el paciente";
    }

    return err;
  };

  const renderExamenSistemas = (id) => {
    const camposSistemas = [
      {
        label: "Cabeza",
        id: "cabeza",
        num: 1,
      },
      {
        label: "Cuello",
        id: "cuello",
        num: 2,
      },
      {
        label: "Tiroides",
        id: "tiroides",
        num: 3,
      },
      {
        label: "Oftalmológicos",
        id: "oftalmologicos",
        num: 4,
      },
      {
        label: "Otirrinolaringología",
        id: "otirrinolaringologia",
        num: 5,
      },
      {
        label: "Corazón",
        id: "corazon",
        num: 6,
      },
      {
        label: "Pulmones",
        id: "pulmones",
        num: 7,
      },
      {
        label: "Abdomen",
        id: "abdomen",
        num: 8,
      },
      {
        label: "Genitales",
        id: "genitales",
        num: 9,
      },
      {
        label: "Osteoarticular",
        id: "osteoarticular",
        num: 10,
      },
      {
        label: "Neurológico",
        id: "neurologico",
        num: 11,
      },
      {
        label: "Piel y faneras",
        id: "piel",
        num: 12,
      },
    ];
    return (
      <React.Fragment>
        <ExamenGeneral
          id={id}
          values={values}
          setValues={setValues}
          errors={errors}
        />
        {camposSistemas.map((campo) => {
          return (
            <ExamenSistema
              id={id}
              campo={campo}
              values={values}
              setValues={setValues}
              key={campo.num}
            />
          );
        })}
      </React.Fragment>
    );
  };

  const renderSistemas = () => {
    const camposSistemas = [
      {
        label: "Sistema Nervioso",
        id: "nervioso",
        num: 0,
      },
      {
        label: "Oftalmológicos",
        id: "oftalmologicos",
        num: 1,
      },
      {
        label: "Otorrinolaringología",
        id: "otorrinolaringologia",
        num: 2,
      },
      {
        label: "Cardiopulmonar",
        id: "cardiopulmonar",
        num: 3,
      },
      {
        label: "Gastrointestinal",
        id: "gastrointestinal",
        num: 4,
        placeholder:
          "Deseos alimenticios, sabores, habito intestinal, Aversion a alimentos, sed",
      },
      {
        label: "Urinario",
        id: "urinario",
        num: 5,
      },
      {
        label: "Genital",
        id: "genital",
        num: 6,
      },

      {
        label: "Osteomuscular",
        id: "osteomuscular",
        num: 7,
      },

      {
        label: "Sistema Endocrino",
        id: "endocrino",
        num: 8,
      },

      {
        label: "Piel y Faneras",
        id: "piel",
        num: 9,
      },

      {
        label: "PSIQUISMO",
        id: "psiquismo",
        num: 10,
        placeholder:
          "Manera de ser, genio, cosas que le critican, cosas que desearía cambiar, temores, celos, envidia, se conmueve con el dolor ajeno? Hasta que grado?, en que momentos y que lo hace llorar? Como demuestra su afecto?",
      },
    ];
    return camposSistemas.map((campo) => {
      return (
        <RevisionSistema
          campo={campo}
          values={values}
          setValues={setValues}
          key={campo.num}
        />
      );
    });
  };

  const renderAntecedentesPersonales = () => {
    const camposAntecedentesPersonales = [
      {
        label: "Patologicos",
        id: "patologicos",
        num: 0,
      },
      {
        label: "Quirurgicos",
        id: "quirurgicos",
        num: 1,
      },
      {
        label: "Traumaticos",
        id: "traumaticos",
        num: 2,
      },
      {
        label: "Alergicos",
        id: "alergicos",
        num: 3,
      },
      {
        label: "Transfusionales",
        id: "transfusionales",
        num: 4,
      },
      {
        label: "Hospitaltarios",
        id: "hospitaltarios",
        num: 5,
      },
      {
        label: "Farmacologicos",
        id: "farmacologicos",
        num: 6,
      },
    ];
    return camposAntecedentesPersonales.map((campo) => {
      return (
        <AntecedentePersonal
          campo={campo}
          values={values}
          setValues={setValues}
          errors={errors}
          key={campo.num}
        />
      );
    });
  };

  return (
    <>
      <Button onClick={() => setShow(!show)}>Registrar Primera Cita</Button>

      <ModalRegistro
        title="Registrar Datos Primera Cita"
        show={show}
        onHide={() => {
          setShow(false);
          setUpdate(!update);
        }}
      >
        <Form onSubmit={handleSubmit}>
          <Form.Group>
            <Form.Label htmlFor={campos[0].id}>{campos[0].label}</Form.Label>
            <Form.Control
              as="select"
              id={campos[0].id}
              name={campos[0].id}
              className={errors[campos[0].id] ? "form-error" : ""}
              onChange={handleChange}
              value={values[campos[0].id]}
            >
              {campos[0].options.map((o) => {
                return (
                  <option key={o.id} value={o.id}>
                    {o.label}
                  </option>
                );
              })}
            </Form.Control>

            {errors[campos[0].id] && (
              <p className="error-small">{errors[campos[0].id]}</p>
            )}
          </Form.Group>
          <Form.Group>
            <Form.Label htmlFor={campos[1].id}>{campos[1].label}</Form.Label>
            <Form.Control
              as="textarea"
              placeholder={campos[1].placeholder}
              id={campos[1].id}
              name={campos[1].id}
              className={errors[campos[1].id] ? "form-error" : ""}
              onChange={handleChange}
              rows={5}
              value={values[campos[1].id]}
            />
            <p className="error-small">{errors[campos[1].id]}</p>
          </Form.Group>
          <Form.Group>
            <Form.Label htmlFor={campos[2].id}>{campos[2].label}</Form.Label>
            <Form.Control
              as="textarea"
              placeholder={campos[2].placeholder}
              id={campos[2].id}
              name={campos[2].id}
              className={errors[campos[2].id] ? "form-error" : ""}
              onChange={handleChange}
              rows={3}
              value={values[campos[2].id]}
            />
            <p className="error-small">{errors[campos[2].id]}</p>
          </Form.Group>
          <Form.Label column="lg">Revisión por sistemas</Form.Label>
          {renderSistemas()}
          <hr />
          <Form.Group>
            <Form.Label htmlFor={campos[3].id}>{campos[3].label}</Form.Label>
            <Form.Control
              as="textarea"
              placeholder={campos[3].placeholder}
              id={campos[3].id}
              name={campos[3].id}
              className={errors[campos[3].id] ? "form-error" : ""}
              onChange={handleChange}
              rows={5}
              value={values[campos[3].id]}
            />
            <p className="error-small">{errors[campos[3].id]}</p>
          </Form.Group>
          <Form.Label column="lg">Antecedentes Personales</Form.Label>
          {renderAntecedentesPersonales()}
          <hr />
          <Form.Group>
            <Form.Label htmlFor={campos[4].id}>{campos[4].label}</Form.Label>
            <Form.Control
              as="textarea"
              placeholder={campos[4].placeholder}
              id={campos[4].id}
              name={campos[4].id}
              className={errors[campos[4].id] ? "form-error" : ""}
              onChange={handleChange}
              rows={3}
              value={values[campos[4].id]}
            />
            <p className="error-small">{errors[campos[4].id]}</p>
          </Form.Group>

          {ginecologia ? (
            <Ginecologia values={values} setValues={setValues} />
          ) : null}

          <Form.Group>
            <Form.Label column="lg">{campos[5].label}</Form.Label>
            {renderExamenSistemas(campos[5].id)}
          </Form.Group>

          <hr />
          <Form.Group>
            <Form.Label htmlFor={campos[6].id}>{campos[6].label}</Form.Label>
            <Form.Control
              as="textarea"
              id={campos[6].id}
              name={campos[6].id}
              className={errors[campos[6].id] ? "form-error" : ""}
              onChange={handleChange}
              rows={5}
              value={values[campos[6].id]}
            />
          </Form.Group>
          <Form.Group>
            <Form.Label htmlFor={campos[7].id}>{campos[7].label}</Form.Label>
            <Form.Control
              as="textarea"
              id={campos[7].id}
              name={campos[7].id}
              className={errors[campos[7].id] ? "form-error" : ""}
              onChange={handleChange}
              rows={5}
              value={values[campos[7].id]}
            />
            <p className="error-small">{errors[campos[7].id]}</p>
          </Form.Group>

          <Form.Group>
            <Form.Label htmlFor={campos[8].id}>{campos[8].label}</Form.Label>
            <Form.Control
              as="textarea"
              id={campos[8].id}
              name={campos[8].id}
              className={errors[campos[8].id] ? "form-error" : ""}
              onChange={handleChange}
              rows={5}
              value={values[campos[8].id]}
            />
            <p className="error-small">{errors[campos[8].id]}</p>
          </Form.Group>

          <Button variant="primary" type="submit">
            Agregar Primera Cita
          </Button>
          {respuestaFormulario === "correcto" ? (
            <p>Cita agregada correctamente, por favor cierra el formulario</p>
          ) : (
            <p className="error-small">{respuestaFormulario}</p>
          )}
        </Form>
      </ModalRegistro>
    </>
  );
};

export default RegistrarPrimeraCita;

export const planes = {
    mensual: {
        titulo: "Suscripcion Mensual",
        precio: "50.000",
        periodo: "Al mes",
        renueva: "mensualmente",
    },
    semestral: {
        titulo: "Suscripcion Semestral",
        precio: "250.000",
        periodo: "Cada 6 meses",
        renueva: "semestralmente",
    },
    anual: {
        titulo: "Suscripcion Anual",
        precio: "400.000",
        periodo: "cada 12 meses",
        renueva: "anualmente",
    }
}

const planes_especiales = {
    descuento_300: {
        titulo: "Suscripcion especial",
        precio: "300.000",
        periodo: "cada 12 meses",
        renueva: "anualmente",

    }
}

export const all_plans = {
...planes,
...planes_especiales
};
import React, { useEffect, useState } from 'react';
import axios from 'axios';
import {
  Row,
  Col,
  Container,
  ListGroup,
  Navbar,
  Nav,
  NavDropdown,
} from 'react-bootstrap';

import StyledLink from '../general/StyledLink';
import ScrollToTopBtn from '../general/ScrollToTop';
import SearchOnPage from '../general/SearchOnPage';
import { DropdownEnfermedades } from './Dropdown';

const Enfermedades = () => {
  const [dataEnfermedades, setDataEnfermedades] = useState(null);

  const fetchData = async () => {
    const response = await axios.get(`/api/libro/enfermedades`);
    setDataEnfermedades(response.data);
    if (window.location.hash) {
      const hash = window.location.hash.replace('#', '');
      document.getElementById(hash).scrollIntoView();
    }
  };

  useEffect(() => {
    fetchData();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const renderSintomas = (sintomas) => {
    if (sintomas.length > 0) {
      return (
        <Row>
          <Col xs={12}>
            <h3>Síntomas</h3>
          </Col>
          <Col>
            <ul>
              {sintomas.map(({ nombre }, index) => {
                if (index % 2 === 0) {
                  return <li key={nombre}>{nombre}</li>;
                }
                return null;
              })}
            </ul>
          </Col>
          <Col>
            <ul>
              {sintomas.map(({ nombre }, index) => {
                if (index % 2 === 1) {
                  return <li key={nombre}>{nombre}</li>;
                }
                return null;
              })}
            </ul>
          </Col>
        </Row>
      );
    }
    return null;
  };

  const renderTratamiento = (tratamiento, observaciones, medicamentos) => {
    if (tratamiento && medicamentos.length > 0) {
      return (
        <Row style={{ textAlign: 'justify' }}>
          <Col xs={12} style={{ textAlign: 'center' }}>
            <h3>Tratamiento</h3>
          </Col>
          {tratamiento.acupuntura.length > 0 ? (
            <Col xs={{ span: 6 }}>
              <h4>Acupuntural:</h4>
              <p style={{ textAlign: 'justify' }}>{observaciones}</p>

              <ListGroup>
                {tratamiento.acupuntura.map(({ puntos, observaciones }) => {
                  return (
                    <ListGroup.Item key={observaciones}>
                      {observaciones ? (
                        <span style={{ fontWeight: 'bold' }}>
                          {observaciones} :{' '}
                        </span>
                      ) : null}

                      {puntos.map(({ punto, codAcupuntura }) => {
                        return (
                          <StyledLink
                            to={`/libro/3#${codAcupuntura}`}
                            key={punto}
                          >
                            <span>-{punto} </span>
                          </StyledLink>
                        );
                      })}
                    </ListGroup.Item>
                  );
                })}
              </ListGroup>
            </Col>
          ) : null}
          {medicamentos.length > 0 ? (
            <Col xs={{ span: 6 }}>
              <h4>Homeopático:</h4>
              <Row>
                <Col>
                  {medicamentos ? (
                    <ul>
                      {medicamentos.map(
                        ({ medicamento, codMedicamento }, index) => {
                          if (index % 2 === 0) {
                            return (
                              <li key={codMedicamento}>
                                <StyledLink
                                  to={`/medicamento/${codMedicamento}`}
                                >
                                  {medicamento}
                                </StyledLink>
                              </li>
                            );
                          }
                          return null;
                        }
                      )}
                    </ul>
                  ) : null}
                </Col>
                <Col>
                  {medicamentos ? (
                    <ul>
                      {medicamentos.map(
                        ({ medicamento, codMedicamento }, index) => {
                          if (index % 2 === 1) {
                            return (
                              <li key={codMedicamento}>
                                <StyledLink
                                  to={`/medicamento/${codMedicamento}`}
                                >
                                  {medicamento}
                                </StyledLink>
                              </li>
                            );
                          }
                          return null;
                        }
                      )}
                    </ul>
                  ) : null}
                </Col>
              </Row>
            </Col>
          ) : null}
          {tratamiento.homeosiniatria.length > 0 ? (
            <Col xs={{ span: 6 }}>
              <h4>Homeosiniatría:</h4>
              <ul>
                {tratamiento.homeosiniatria.map((dato) => {
                  return (
                    <li key={dato} style={{ textTransform: 'capitalize' }}>
                      {dato}
                    </li>
                  );
                })}
              </ul>
            </Col>
          ) : null}
          {tratamiento.antihomotoxicos.length > 0 ? (
            <Col xs={{ span: 6 }}>
              <h4>Antihomotóxicos orales:</h4>

              <ul>
                {tratamiento.antihomotoxicos.map((dato) => {
                  return (
                    <li key={dato} style={{ textTransform: 'capitalize' }}>
                      {dato}
                    </li>
                  );
                })}
              </ul>
            </Col>
          ) : null}
        </Row>
      );
    } else if (medicamentos.length > 0) {
      return (
        <Row style={{ textAlign: 'justify' }}>
          <Col xs={12}>
            <h3 style={{ textAlign: 'center' }}>Tratamiento Homeopatico</h3>
          </Col>
          <Col>
            <ul>
              {medicamentos.map(({ medicamento, codMedicamento }, index) => {
                if (index % 2 === 0) {
                  return (
                    <li key={codMedicamento}>
                      <StyledLink to={`/medicamento/${codMedicamento}`}>
                        {medicamento}
                      </StyledLink>
                    </li>
                  );
                }
                return null;
              })}
            </ul>
          </Col>
          <Col>
            <ul>
              {medicamentos.map(({ medicamento, codMedicamento }, index) => {
                if (index % 2 === 0) {
                  return (
                    <li key={codMedicamento}>
                      <StyledLink to={`/medicamento/${codMedicamento}`}>
                        {medicamento}
                      </StyledLink>
                    </li>
                  );
                }
                return null;
              })}
            </ul>
          </Col>
        </Row>
      );
    } else if (tratamiento) {
      return (
        <Row style={{ textAlign: 'justify' }}>
          <Col xs={12} style={{ textAlign: 'center' }}>
            <h3>Tratamiento</h3>
          </Col>
          {tratamiento.acupuntura.length > 0 ? (
            <Col xs={{ span: 6 }}>
              <h4>Acupuntural:</h4>
              <p style={{ textAlign: 'justify' }}>{observaciones}</p>

              <ListGroup>
                {tratamiento.acupuntura.map(({ puntos, observaciones }) => {
                  return (
                    <ListGroup.Item key={observaciones}>
                      {observaciones ? (
                        <span style={{ fontWeight: 'bold' }}>
                          {observaciones} :{' '}
                        </span>
                      ) : null}

                      {puntos.map(({ punto, codAcupuntura }) => {
                        return (
                          <StyledLink
                            to={`/libro/3#${codAcupuntura}`}
                            key={punto}
                          >
                            <span>-{punto} </span>
                          </StyledLink>
                        );
                      })}
                    </ListGroup.Item>
                  );
                })}
              </ListGroup>
            </Col>
          ) : null}
          {tratamiento.homeosiniatria.length > 0 ? (
            <Col xs={{ span: 6 }}>
              <h4>Homeosiniatría:</h4>
              <ul>
                {tratamiento.homeosiniatria.map((dato) => {
                  return (
                    <li key={dato} style={{ textTransform: 'capitalize' }}>
                      {dato}
                    </li>
                  );
                })}
              </ul>
            </Col>
          ) : null}
          {tratamiento.antihomotoxicos.length > 0 ? (
            <Col xs={{ span: 6 }}>
              <h4>Antihomotóxicos orales:</h4>

              <ul>
                {tratamiento.antihomotoxicos.map((dato) => {
                  return (
                    <li key={dato} style={{ textTransform: 'capitalize' }}>
                      {dato}
                    </li>
                  );
                })}
              </ul>
            </Col>
          ) : null}
        </Row>
      );
    }
    return null;
  };

  const renderMedicamentos = (medicamentos) => {
    if (medicamentos.length > 0) {
      let observacoinesEnMedicamentos = false;

      medicamentos.map(({ observaciones }) => {
        observaciones
          ? (observacoinesEnMedicamentos = true)
          : (observacoinesEnMedicamentos = observacoinesEnMedicamentos);
      });
      if (observacoinesEnMedicamentos) {
        return (
          <Row>
            <Col xs={12}>
              <h3>Homeopatico</h3>
            </Col>
            <Col>
              {medicamentos.map(
                ({ medicamento, observaciones, codMedicamento }) => {
                  return (
                    <React.Fragment key={medicamento}>
                      {observaciones ? (
                        <StyledLink to={`/medicamento/${codMedicamento}`}>
                          <span style={{ fontWeight: 'bold' }}>
                            {medicamento}
                          </span>
                        </StyledLink>
                      ) : null}
                      {observaciones ? (
                        <p style={{ textAlign: 'justify' }}>{observaciones}</p>
                      ) : null}
                    </React.Fragment>
                  );
                }
              )}
            </Col>
          </Row>
        );
      }
    }
    return null;
  };

  const renderHijos = (hijos) => {
    return hijos.map((enfermedad) => {
      return (
        <div key={enfermedad.codSindrome}>
          <Row>
            <Col>
              <h2 id={enfermedad.codSindrome} style={{ paddingTop: '10vh' }}>
                {enfermedad.nombre}
              </h2>
            </Col>
          </Row>
          <Row>
            <Col>
              <p style={{ textAlign: 'justify' }}>{enfermedad.texto}</p>
            </Col>
          </Row>
          {renderSintomas(enfermedad.sintomas)}
          {renderTratamiento(
            enfermedad.tratamiento,
            enfermedad.observaciones,
            enfermedad.medicamentos
          )}
          {renderMedicamentos(enfermedad.medicamentos)}

          {enfermedad.hijos ? (
            <Row>
              <Col xs={{ offset: 1 }}>{renderHijos(enfermedad.hijos)}</Col>
            </Row>
          ) : null}
        </div>
      );
    });
  };

  const renderCapitulos = (capitulos) => {
    return capitulos.map(({ nombre, hijos, codSindrome }) => {
      if (hijos) {
        return (
          <NavDropdown title={nombre} id={`#${codSindrome}submenu`}>
            <NavDropdown.Item href={`#${codSindrome}`}>
              {nombre}
            </NavDropdown.Item>
            {hijos.map(({ nombre, codSindrome }) => {
              return (
                <>
                  <NavDropdown.Divider />
                  <NavDropdown.Item href={`#${codSindrome}`}>
                    {nombre}
                  </NavDropdown.Item>
                </>
              );
            })}
          </NavDropdown>
        );
      }
      return <Nav.Link href={`#${codSindrome}`}>{nombre}</Nav.Link>;
    });
  };
  const renderListaCapitulos = () => {
    return (
      <Navbar id="sidebar">
        <ListGroup>
          <h1>
            <a href="#enfermedades" className="logo">
              Enfermedades
            </a>
          </h1>
          {dataEnfermedades.map((capitulo) => {
            return (
              <DropdownEnfermedades
                capitulo={capitulo}
                padding={0}
                key={capitulo.codSindrome}
              />
            );
          })}
        </ListGroup>
      </Navbar>
    );
  };

  if (dataEnfermedades) {
    return (
      <Container fluid id="enfermedades">
        <Row>
          <Col xs={12} sm={3} lg={3} id="sidebar-wrapper">
            {renderListaCapitulos()}
          </Col>
          <Col>
            <div
              className="book-container"
              onCut={(event) => {
                event.preventDefault();
              }}
              onCopy={(event) => {
                event.preventDefault();
              }}
            >
              <Row className="sticky-top-menu">
                <Col>
                  <SearchOnPage />
                </Col>
              </Row>
              <h1 id="#enfermedades">Enfermedades</h1>
              {renderHijos(dataEnfermedades)}
            </div>
            <ScrollToTopBtn />
          </Col>
        </Row>
      </Container>
    );
  }
  return <div>Enfermedades</div>;
};

export default Enfermedades;

import React, { useState } from "react";
import { Button } from "react-bootstrap";

import Formulario from "../../Formulario";
import ModalRegistro from "../ModalRegistro";

const RegistrarAcompaniante = ({ id, update, setUpdate }) => {
  const [show, setShow] = useState(false);
  const campos = [
    {
      label: "Nombre *",
      id: "nombre",
      type: "text",
    },
    {
      label: "Apellido *",
      id: "apellido",
      type: "text",
    },
    {
      label: "Direccion",
      id: "direccion",
      type: "text",
    },
    {
      label: "Telefono Fijo",
      id: "tel",
      type: "tel",
    },
    {
      label: "Telefono Oficina",
      id: "telOficina",
      type: "tel",
    },
    {
      label: "Telefono celular",
      id: "cel",
      type: "tel",
    },
  ];

  const validateForm = (values) => {
    const err = {};

    if (values.nombre.length === 0) {
      err.nombre = "Se requiere el nombre del paciente";
    }
    if (values.apellido.length === 0) {
      err.apellido = "Se requiere el apellido del paciente";
    }
    return err;
  };

  return (
    <>
      <Button onClick={() => setShow(!show)}>Agregar Acompañante</Button>

      <ModalRegistro
        title="Registrar Acompañante Paciente"
        show={show}
        onHide={() => {
          setShow(false);
          setUpdate(!update);
        }}
      >
        <Formulario
          campos={campos}
          validateForm={validateForm}
          apiRoute={`/api/paciente/${id}/acompaniante`}
          enviar="Agregar Acompañante"
        />
      </ModalRegistro>
    </>
  );
};

export default RegistrarAcompaniante;

import React from "react";
import { Row, Col } from "react-bootstrap";

import Formulario from "../../Formulario";
import StyledLink from "../../general/StyledLink";

const Nuevo = () => {
  const campos = [
    {
      label: "Nombre Síndrome",
      id: "nombre",
      type: "text",
    },
    {
      label: "Resumén",
      id: "texto",
      type: "textarea",
    },
    {
      label: "Observaciones",
      id: "observaciones",
      type: "textarea",
    },
  ];

  const validateForm = (values) => {
    const err = {};
    if (values.nombre.length === 0) {
      err.nombre = "Ingresa un síndrome";
    }
    return err;
  };

  return (
    <Row>
      <Col xs={12}>
        <StyledLink to="/admin/Sindrome">Listado Síndromes</StyledLink>
      </Col>
      <Col>
        <h1>Registro Nuevo Síndrome</h1>
        <Formulario
          campos={campos}
          validateForm={validateForm}
          apiRoute={"/api/Sindrome"}
          enviar="Crear Nuevo Síndrome"
        />
      </Col>
    </Row>
  );
};

export default Nuevo;

import React from "react";
import { Row, Col } from "react-bootstrap";

const PrimeraCita = ({ data, esHombre }) => {
  const timestamp = data._id.toString().substring(0, 8);
  const date = new Date(parseInt(timestamp, 16) * 1000);
  return (
    <React.Fragment>
      <Row>
        <Col>
          <h3>Registro Primera Cita</h3>
          <p className="text-muted">{date.toString()}</p>
        </Col>
      </Row>
      <Row>
        <Col lg={6}>
          <dl>
            <dt>Motivo consulta</dt>
            <dd>{data.motivo}</dd>

            <dt>Enfermedad</dt>
            <dd>{data.enfermedad}</dd>

            <dt>Tipo de Sangre</dt>
            <dd>{data.sangre}</dd>

            <dt>Antecedentes Personales</dt>
            <dd>
              <dl style={{ textIndent: "10%" }}>
                {data.antecedentesPer.map((antecedente) => {
                  return (
                    <React.Fragment key={antecedente.antecedente}>
                      <dt>{antecedente.antecedente}</dt>
                      <dd>{antecedente.detalle}</dd>
                    </React.Fragment>
                  );
                })}
              </dl>
            </dd>
          </dl>
        </Col>
        <Col lg={6}>
          <dl>
            <dt>Antecedentes Familiares</dt>
            <dd>{data.antecedentesFam}</dd>

            <dt>Revisión por Sistemas</dt>
            <dd>
              <dl style={{ textIndent: "10%" }}>
                {data.revisionSistemas.map((sistema) => {
                  if (sistema.detalle === "") {
                    return null;
                  }
                  return (
                    <React.Fragment key={sistema.sistema}>
                      <dt>{sistema.sistema}</dt>
                      <dd>{sistema.detalle}</dd>
                    </React.Fragment>
                  );
                })}
              </dl>
            </dd>
            <dt>Vacunas</dt>
            <dd>{data.vacunas}</dd>
          </dl>
        </Col>
      </Row>
      {esHombre ? null : (
        <Row>
          <Col>
            <dl>
              <dt>Ginecología</dt>
              <dd>
                <dl style={{ textIndent: "10%" }}>
                  <Row>
                    <Col>
                      <dt>Embarazos</dt>
                      <dd>{data.ginecologia[0].embarazos}</dd>
                    </Col>
                    <Col>
                      <dt>Partos</dt>
                      <dd>{data.ginecologia[0].partos}</dd>
                    </Col>
                    <Col>
                      <dt>Cesareas</dt>
                      <dd>{data.ginecologia[0].cesareas}</dd>
                    </Col>
                    <Col>
                      <dt>Abortos</dt>
                      <dd>{data.ginecologia[0].abortos}</dd>
                    </Col>
                  </Row>

                  <dt>Planificación</dt>
                  <dd>{data.ginecologia[0].planificacion}</dd>

                  <dt>Menarquia</dt>
                  <dd>{data.ginecologia[0].menarquia}</dd>

                  <dt>Ciclos</dt>
                  <dd>{data.ginecologia[0].ciclos}</dd>

                  <dt>Citologia</dt>
                  <dd>{data.ginecologia[0].citologia}</dd>
                  <dt>Mamografia</dt>
                  <dd>{data.ginecologia[0].mamografia}</dd>
                </dl>
              </dd>
            </dl>
          </Col>
        </Row>
      )}
      <Row>
        <Col>
          <dl>
            <dt>Examen fisico por sistemas</dt>
          </dl>
          {data.examenSistemas.map(({ sistema, detalle }) => {
            if (sistema !== "general")
              return (
                <Row key={sistema}>
                  <Col
                    xs={{ offset: 1, span: 11 }}
                    style={{ fontWeight: "bold" }}
                  >
                    {sistema}
                  </Col>
                  <Col xs={{ offset: 2 }}>{detalle}</Col>
                </Row>
              );
            else
              return (
                <React.Fragment key={sistema}>
                  <Row>
                    <Col
                      xs={{ offset: 1, span: 11 }}
                      style={{ fontWeight: "bold" }}
                    >
                      {sistema}
                    </Col>
                    <Col
                      xs={{ offset: 2, span: 10 }}
                      style={{ fontWeight: "bold" }}
                    >
                      Estado general
                    </Col>
                    <Col xs={{ offset: 2, span: 10 }}>{detalle.estado}</Col>
                  </Row>
                  <Row>
                    <Col xs={{ offset: 2 }} style={{ fontWeight: "bold" }}>
                      Peso
                    </Col>
                    <Col>{detalle.peso} Kilogramos</Col>

                    <Col xs={{ offset: 1 }} style={{ fontWeight: "bold" }}>
                      Talla
                    </Col>
                    <Col>{detalle.talla} metros</Col>

                    <Col xs={{ offset: 1 }} style={{ fontWeight: "bold" }}>
                      Indice de masa corporal
                    </Col>
                    <Col>{Math.round(detalle.imc * 100) / 100}</Col>
                  </Row>
                  <Row key={sistema}>
                    <Col xs={{ offset: 1 }}></Col>
                    {detalle.tension ? (
                      <Col xs={{ offset: 1 }} style={{ fontWeight: "bold" }}>
                        Tensión
                      </Col>
                    ) : null}
                    {detalle.tension ? (
                      <Col>{detalle.tension} mm de Hg.</Col>
                    ) : null}

                    {detalle.frecCardiaca ? (
                      <Col xs={{ offset: 1 }} style={{ fontWeight: "bold" }}>
                        Frecuencia cardiaca
                      </Col>
                    ) : null}
                    {detalle.frecCardiaca ? (
                      <Col>{detalle.tension} Pulsaciones/minuto.</Col>
                    ) : null}

                    {detalle.frecRespiratoria ? (
                      <Col xs={{ offset: 1 }} style={{ fontWeight: "bold" }}>
                        Frecuencia Respiratoria
                      </Col>
                    ) : null}
                    {detalle.frecRespiratoria ? (
                      <Col>
                        {detalle.frecRespiratoria} respiraciones/minuto.
                      </Col>
                    ) : null}

                    {detalle.temperatura ? (
                      <Col xs={{ offset: 1 }} style={{ fontWeight: "bold" }}>
                        Temperatura
                      </Col>
                    ) : null}
                    {detalle.temperatura ? (
                      <Col>{detalle.temperatura} °C</Col>
                    ) : null}
                  </Row>
                  {detalle.pulso ? (
                    <Row>
                      <Col
                        xs={{ offset: 2, span: 10 }}
                        style={{ fontWeight: "bold" }}
                      >
                        Pulso
                      </Col>
                      <Col xs={{ offset: 2, span: 10 }}>{detalle.pulso}</Col>
                    </Row>
                  ) : null}
                  {detalle.lengua ? (
                    <Row>
                      <Col
                        xs={{ offset: 2, span: 10 }}
                        style={{ fontWeight: "bold" }}
                      >
                        Lengua
                      </Col>
                      <Col xs={{ offset: 2, span: 10 }}>{detalle.lengua}</Col>
                    </Row>
                  ) : null}
                  {detalle.tez ? (
                    <Row>
                      <Col
                        xs={{ offset: 2, span: 10 }}
                        style={{ fontWeight: "bold" }}
                      >
                        Tez
                      </Col>
                      <Col xs={{ offset: 2, span: 10 }}>{detalle.tez}</Col>
                    </Row>
                  ) : null}
                </React.Fragment>
              );
          })}
        </Col>
      </Row>

      <Row>
        <Col>
          <dl>
            <dt>Examen Energético</dt>
            <dd>{data.examenEnergetico}</dd>
          </dl>
        </Col>
      </Row>

      <Row>
        <Col>
          <dl>
            <dt>Diagnostico</dt>
            <dd>{data.diagnostico}</dd>
          </dl>
        </Col>
      </Row>

      <Row>
        <Col>
          <dl>
            <dt>Plan y tratamiento</dt>
            <dd>{data.tratamiento}</dd>
          </dl>
        </Col>
      </Row>
    </React.Fragment>
  );
};

export default PrimeraCita;

import React from "react";
import { Container, Row, Col } from "react-bootstrap";

import "../../css/servicios.css";
import Icono_libro from "../../assets/img/IconoLibro.png";

const Servicios = () => {
  return (
    <section id="servicios">
      <Container>
        <Row>
          <Col>
            <div className="section-title">
              <h2>Nuestro producto</h2>
              <p>
                Consientes de la necesidad de facilitar la búsqueda del
                medicamento homeopático y el diagnóstico en medicina tradicional
                china para seleccionar los puntos de acupuntura más adecuados
                para el tratamiento de nuestros pacientes, y basados en el
                repertorio homeopático de síntomas de Kent, construimos esta
                aplicación que facilita la búsqueda y repertorización de los
                síntomas descritos por el paciente en la consulta, los cuales
                pueden integrarse en los dos sistemas médicos para hallar el
                síndrome de diagnóstico en acupuntura, los puntos más adecuados
                para su manejo y el medicamento homeopático más semejante.
              </p>
            </div>
          </Col>
        </Row>

        <Row>
          <Col md={6} lg={4}>
            <div className="icon-box">
              <div className="icon">
                <img src={Icono_libro} alt="book icon" width="40" height="40" />
              </div>
              <h4>Repertorización</h4>
              <p>
                Acceso al Repertorio digital homeopático de Kent con mas de
                98.000 síntomas y al repertorio digital de síntomas de
                acupuntura con los mismos capítulos del repertorio de Kent, con
                mas de 15.000 relaciones entre sintomas de acupuntura y
                homeopatia. A partir de sintomas seleccionados podrás observar
                un listado de medicamentos de acuerdo a un sistema de
                puntuación.
              </p>
            </div>
          </Col>
          <Col md={6} lg={4}>
            <div className="icon-box">
              <div className="icon">
                <img src={Icono_libro} alt="book icon" width="40" height="40" />
              </div>
              <h4>Manejo de pacientes</h4>
              <p>
                Guarda estados de la repertorizacion de un paciente en su
                perfil, registra controles medicos con informacion detallada del
                paciente que podras consultar en cualquier momento
              </p>
            </div>
          </Col>

          <Col md={6} lg={4}>
            <div className="icon-box">
              <div className="icon">
                <img src={Icono_libro} alt="book icon" width="40" height="40" />
              </div>
              <h4>Libros Guía y Materias medicas</h4>
              <p>
                Libros como “Homeosiniatría de la conciencia del Tao a la
                memoria del agua” Dr. Hector Roa Morales, “Homeosiniatría en el
                manejo de enfermedades más comunes” Dr. Hector Roa y “Atlas de
                meridianos y puntos de acupuntura” Dr. Hector Roa. Tambien
                accede al contenido de las materias medicas de autores como
                Vinovsky, Clarke, Kent, Lathoud o Nash.
              </p>
            </div>
          </Col>
        </Row>
      </Container>
    </section>
  );

  return (
    <Row>
      <Col>
        <div className="responsive-video">
          <iframe
            src="https://www.youtube.com/embed/zpOULjyy-n8?rel=0"
            allowFullScreen
          ></iframe>
        </div>
      </Col>
    </Row>
  );
};

export default Servicios;

import React, { useState, useEffect } from 'react';
import JsxParser from 'react-jsx-parser';
import axios from 'axios';
import { Row, Col, Container, Navbar, ListGroup } from 'react-bootstrap';
import '../../css/homeosiniatria.css';
import ScrollToTopBtn from '../general/ScrollToTop';
import SearchOnPage from '../general/SearchOnPage';

import { DropdownHomeosiniatria } from './Dropdown';

const Homeosiniatria = () => {
  const [libro, setLibro] = useState(null);
  const [capitulo, setCapitulo] = useState(null);

  const handleChangeCapitulo = (cap) => {
    setCapitulo(cap);
    window.scrollTo(0, 0);
  };

  useEffect(() => {
    const fetchData = async () => {
      const response = await axios.get('/api/libro/Homeosiniatria');
      setLibro(response.data);

      setCapitulo(response.data[0]);
    };
    fetchData();
  }, []);

  return (
    <Container fluid id="homeosiniatria">
      <Row>
        <Col xs={12} md={3} lg={3} id="sidebar-wrapper">
          <Navbar id="sidebar">
            <ListGroup>
              {libro
                ? libro.map((capitulo) => {
                    return (
                      <DropdownHomeosiniatria
                        data={capitulo}
                        handleChangeCapitulo={handleChangeCapitulo}
                        key={capitulo.orden}
                      />
                    );
                  })
                : null}
            </ListGroup>
          </Navbar>
        </Col>

        <Col xs={12} md={9} lg={9}>
          <Row className="sticky-top-menu">
            <Col>
              <SearchOnPage />
            </Col>
          </Row>
          <Row>
            <ScrollToTopBtn />
            <div
              className="book-container"
              onCut={(event) => {
                event.preventDefault();
              }}
              onCopy={(event) => {
                event.preventDefault();
              }}
            >
              {capitulo ? (
                <div>
                  <h1>{capitulo.capitulo}</h1>
                </div>
              ) : null}
              {capitulo
                ? capitulo.subcapitulos.map((subCap, key) => {
                    return (
                      <div key={subCap.nombre + key} id={subCap.nombre}>
                        <br />
                        <h3>{subCap.nombre}</h3>

                        <JsxParser jsx={subCap.texto} />
                      </div>
                    );
                  })
                : null}
            </div>
          </Row>
        </Col>
      </Row>
    </Container>
  );
};

export default Homeosiniatria;

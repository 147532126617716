import React, { useState, useEffect } from "react";
import axios from "axios";
import { Row, Col, Button, ListGroup } from "react-bootstrap";

import ModalConfirmar from "../../general/ModalConfirmar";
import StyledLink from "../../general/StyledLink";
import SearchBar from "../../general/SearchBar";

const RelacionSintoma = ({ id, relacion }) => {
  const [agregar, setAgregar] = useState(false);
  const [filtered, setFiltered] = useState([]);
  const [sintoma, setSintoma] = useState([]);

  const fetchData = async () => {
    const response = await axios.get("/api/nombre/sintoma");
    setSintoma(response.data);
    setFiltered(response.data);
  };

  useEffect(() => {
    fetchData();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const filterFunction = (value) => {
    if (value.length > 0) {
      setFiltered(
        sintoma.filter((sinto) =>
          sinto.nombre.toLowerCase().includes(`${value}`.toLowerCase())
        )
      );
    } else {
      setFiltered(sintoma);
    }
  };

  const agregarSintoma = async (sintoma) => {
    const send = {
      nombre: sintoma.nombre,
      codSintoma: sintoma.codSintoma,
      codSindrome: id,
    };
    try {
      const res = await axios.post("/api/SindromeSintoma", send);
      alert(res.statusText);
    } catch (err) {
      alert("El sintoma seleccionado ya está relacionado con el Medicamento");
    }
  };

  const renderList = () => {
    return (
      <ListGroup style={{ maxHeight: "300px", overflowY: "scroll" }}>
        {filtered.map((item) => {
          return (
            <ListGroup.Item
              key={item.codSintoma}
              action
              onClick={() => agregarSintoma(item)}
            >
              {item.nombre}
            </ListGroup.Item>
          );
        })}
      </ListGroup>
    );
  };

  const borrar = async (codSin) => {
    await axios.delete(`/api/sindrome/${id}/sintoma/${codSin}`);
  };

  return (
    <Row>
      <Col sm={12}>
        <p style={{ fontWeight: "bold" }}>Relacion con Sintomas</p>

        {relacion ? (
          <ListGroup>
            {relacion.map((item) => {
              return (
                <ListGroup.Item key={item.codSintoma}>
                  {item.nombre}
                  <ModalConfirmar
                    borrar={() => borrar(item.codSintoma)}
                    cod={id}
                    nombre={item.nombre}
                  />
                </ListGroup.Item>
              );
            })}
          </ListGroup>
        ) : null}
        <br />
        <Button
          variant="warning"
          onClick={() => {
            setAgregar(!agregar);
          }}
        >
          Relacionar con Sintomas de acupuntura
        </Button>
        {agregar ? (
          <div>
            <SearchBar title="Buscar sintoma" filterFunction={filterFunction} />
            {renderList()}
            <StyledLink to={`/admin/nuevo/registro/Sintoma`}>
              <Button variant="success">Agregar Nuevo Sintoma</Button>
            </StyledLink>
          </div>
        ) : null}
      </Col>
    </Row>
  );
};

export default RelacionSintoma;

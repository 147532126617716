import React from "react";
import { Row, Col } from "react-bootstrap";

import Campo from "../detalle/Campo";
import SubCapitulo from "./SubCapitulo";

const Capitulo = ({ data, validateForm, id }) => {
  return (
    <Row>
      <Col sm={12}>
        <Campo titulo={"Nombre Capitulo"} detail={data.nombre} />
      </Col>
      <Col sm={{ span: 11, offset: 1 }}>
        {data.subcapitulo.map((subchap) => {
          return (
            <SubCapitulo
              data={subchap}
              validateForm={validateForm}
              id={id}
              key={subchap.nombre}
            />
          );
        })}
      </Col>
    </Row>
  );
};

export default Capitulo;

import React, { useState } from "react";
import { Row, Col, Image, Card, Carousel } from "react-bootstrap";

const PuntoAcupuntura = ({ data }) => {
  const [index, setIndex] = useState(0);

  const handleSelect = (selectedIndex, e) => {
    setIndex(selectedIndex);
  };

  const {
    codAcupuntura,
    abreviacion,
    punto,
    nombreChino,
    nombreAlterno,
    localizacion,
    funcion,
    metodo,
    indicaciones,
    imagen,
    propiedad,
  } = data;
  return (
    <Card id={codAcupuntura}>
      <Row>
        <Col lg={5} md={6}>
          <Carousel
            activeIndex={index}
            onSelect={handleSelect}
            autoPlay={false}
            interval={null}
          >
            {imagen.map((item) => {
              return (
                <Carousel.Item key={item}>
                  <Image src={item} alt="punto acupuntura" fluid />
                </Carousel.Item>
              );
            })}
          </Carousel>
        </Col>
        <Col>
          <Card.Body>
            <Card.Title className={`${propiedad}`}>{punto}</Card.Title>
            <Card.Subtitle className="mb-2 text-muted">
              {abreviacion} {nombreChino} {nombreAlterno}
            </Card.Subtitle>

            <dl>
              <dt>Función</dt>
              <dd>{funcion}</dd>
              <dt>indicaciones</dt>
              <dd>{indicaciones}</dd>
              <dt>Localización</dt>
              <dd>{localizacion}</dd>
              <dt>Metodo</dt>
              <dd>{metodo}</dd>
            </dl>
          </Card.Body>
        </Col>
      </Row>
    </Card>
  );
};

export default PuntoAcupuntura;

import React, { useState, useEffect } from "react";
import axios from "axios";
import { Navbar, Nav, NavDropdown, Row, Col } from "react-bootstrap";

import "../css/medicamento.css";

const Medicamento = ({ match }) => {
  const [items, setItems] = useState([]);
  const [medicamento, setMedicamento] = useState(null);
  const [medFiltered, setMedFiltered] = useState([]);
  const [search, setSearch] = useState("");

  const fetchMed = async (id) => {
    const response = await axios.get(`/api/medicamento/${id}`);
    setMedicamento(response.data);
  };

  const fetchData = async () => {
    const response = await axios.get("/api/medicamentos/nombres");
    setItems(response.data);
    fetchMed(match.params.id);
    setMedFiltered(response.data);
  };

  useEffect(() => {
    fetchData();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const renderMedicamento = () => {
    if (medicamento) {
      return (
        <div className="scroll-medicamento noselect">
          <h1>{medicamento.medicamento}</h1>
          <p className="text-muted">
            {medicamento.abreviacion}, {medicamento.nombreAlterno}
          </p>

          {medicamento.capitulo.map((cap) => {
            return (
              <React.Fragment key={cap.nombre}>
                {cap.subcapitulo.map((sub, index) => {
                  return (
                    <React.Fragment key={sub.nombre}>
                      {index === 0 ? (
                        <h2 id={`${cap.nombre}-${sub.nombre}`}>
                          {sub.nombre.toUpperCase()}
                        </h2>
                      ) : (
                        <h3 id={`${cap.nombre}-${sub.nombre}`}>{sub.nombre}</h3>
                      )}
                      <p
                        className="text-justify"
                        style={{ whiteSpace: "pre-line", marginRight: "20px" }}
                      >
                        {sub.texto}
                      </p>
                    </React.Fragment>
                  );
                })}
              </React.Fragment>
            );
          })}
        </div>
      );
    }
    return <div>cargando</div>;
  };

  const handleSubmit = (evt) => {
    evt.preventDefault();
  };

  const handleChange = (evt) => {
    const value = evt.target.value;

    setSearch(value);
    if (value.length > 0) {
      setMedFiltered(
        items.filter((med) =>
          med.medicamento.toLowerCase().includes(`${value}`.toLowerCase())
        )
      );
    } else {
      setMedFiltered(items);
    }
  };

  const searchMed = () => {
    return (
      <form onSubmit={handleSubmit}>
        <div className="form-group">
          <label>Buscar Medicamento</label>
          <input
            type="text"
            className="form-control"
            style={{ maxWidth: "70%" }}
            onChange={handleChange}
            value={search}
          />
        </div>
      </form>
    );
  };

  const renderListaMedicamentos = () => {
    if (medicamento) {
      return (
        <React.Fragment>
          {searchMed()}
          <div className="scroll-menu-medicamento">
            {medFiltered.map((i) => {
              return (
                <p
                  style={{ cursor: "pointer" }}
                  key={i.codMedicamento}
                  onClick={() => {
                    fetchMed(i.codMedicamento);
                  }}
                  className={
                    medicamento.codMedicamento === i.codMedicamento
                      ? "active"
                      : null
                  }
                >
                  {i.medicamento}
                </p>
              );
            })}
          </div>
        </React.Fragment>
      );
    }
  };

  const renderCapitulos = () => {
    if (medicamento) {
      return (
        <Navbar expand="lg" className="nav-menu">
          <Navbar.Toggle aria-controls="navbar-autores" />
          <Navbar.Collapse id="navbar-autores">
            <Nav className="ml-auto">
              {medicamento.capitulo.map((cap) => {
                return (
                  <NavDropdown
                    title={cap.nombre}
                    id="collasible-nav-dropdown"
                    key={cap.nombre}
                  >
                    {cap.subcapitulo.map((sub) => {
                      return (
                        <NavDropdown.Item
                          href={`#${cap.nombre}-${sub.nombre}`}
                          key={sub.nombre}
                        >
                          {sub.nombre}
                        </NavDropdown.Item>
                      );
                    })}
                  </NavDropdown>
                );
              })}
            </Nav>
          </Navbar.Collapse>
        </Navbar>
      );
    }
    return <div>cargando</div>;
  };

  return (
    <div className="my-container center">
      <Row>
        <Col xs={12} sm={3} lg={2}>
          {renderListaMedicamentos()}
        </Col>
        <Col>
          <Row>
            <Col xs={12}>{renderCapitulos()}</Col>
            <Col xs={12}>{renderMedicamento()}</Col>
          </Row>
        </Col>
      </Row>
    </div>
  );
};

export default Medicamento;

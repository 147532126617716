import React from "react";
import { Route, Redirect } from "react-router-dom";

import useAuth from "../../../customHooks/useAuth";

const SubscriptionProtectedRoute = ({ component: Component, ...rest }) => {
  const { isAuth, suscripcion, rol } = useAuth();
  if (isAuth === true || isAuth === false) {
    if (isAuth) {
      if (suscripcion && rol) {
        return (
          <Route
            {...rest}
            render={(props) =>
              suscripcion == "ACTIVE" ||
              suscripcion == "PAID" ||
              suscripcion == "PENDING" ||
              rol === "admin" ||
              rol === "colaborador" ? (
                <Component {...props} />
              ) : (
                <Redirect to="/planes" />
              )
            }
          />
        );
      }
    } else {
      return <Route {...rest} render={(props) => <Redirect to="/login" />} />;
    }
  }

  return null;
};

export default SubscriptionProtectedRoute;

import axios from "axios";
import React, { useState } from "react";
import { Container, Row, Col, Card, Button, Form } from "react-bootstrap";
import { connect } from "react-redux";

import { setPlan } from "../../state/payU/actions";
import useAuth from "../../customHooks/useAuth";
import StyledLink from "../general/StyledLink";

import { planes } from "../general/pagos/config";

const Planes = (props) => {
  const [codigo, setCodigo] = useState("");
  const [promo, setPromo] = useState(null);
  const [codigoValido, setCodigoValido] = useState(false);

  const { logout, isAuth } = useAuth();

  const handleChange = (evt) => {
    setCodigo(evt.target.value);
  };

  const handleSubmit = async (evt) => {
    evt.preventDefault();
    const { data } = await axios.post("/api/validarCodigo", { codigo });

    if (data) {
      setPromo(data.promo);
      props.setPlan(data.promo);
      setCodigoValido(true);
    } else {
      setCodigoValido(false);
    }
  };

  return (
    <div id="watermark">
      <Container className="text-center" id="planes">
        <Row>
          {Object.entries(planes).map((item) => {
            const slug = item[0];
            const { titulo, precio, periodo, renueva } = item[1];

            return (
              <Col
                onClick={() => {
                  props.setPlan(slug);
                }}
                key={slug}
              >
                <StyledLink to="/pago">
                  <Card>
                    <Card.Body className="no-link-style">
                      <Card.Title>{titulo}</Card.Title>
                      <Card.Subtitle className="precio">
                        $ {precio} COP
                      </Card.Subtitle>
                      <Card.Text>{periodo}</Card.Text>
                      <Card.Text className="descripcion-plan">
                        Al empezar tu suscripción obtendras acceso a todas las
                        caracteristicas de la plataforma,teniendo disponible un
                        amplio repertorio medico, un sencillo proceso para
                        obtener tu repertorización y la opción de manejar las
                        consultas de tus pacientes
                      </Card.Text>
                      <Button variant="default" className="btn-get-started">
                        Suscribirme
                      </Button>
                      <Card.Text className="text-muted footer">
                        El plan se renueva {renueva}
                      </Card.Text>
                    </Card.Body>
                  </Card>
                </StyledLink>
              </Col>
            );
          })}
        </Row>
        <Row>
          <Col>
            <p>̉̉¿Tienes un codigo de descuento? Ingresalo a continuación:</p>
            {!isAuth ? (
              <p>
                <StyledLink to="/login">
                  (para aplicar el descuento es necesario estar registrado en la
                  aplicación)
                </StyledLink>
              </p>
            ) : null}

            <Form onSubmit={handleSubmit}>
              <Form.Group controlId="codigoDescuento">
                <Form.Control
                  disabled={!isAuth}
                  onChange={handleChange}
                  placeholder="Ingresa el codigo de descuento"
                  value={codigo}
                />
              </Form.Group>
            </Form>
          </Col>
          {codigoValido ? (
            <Col>
              <p>
                ̉El descuento ha sido debidamente aplicado da click en el
                siguiente botón para continuar
              </p>
              {promo === "colaborador" ? (
                <StyledLink to="/">
                  <Button variant="success" onClick={logout}>
                    Continuar
                  </Button>
                </StyledLink>
              ) : (
                <StyledLink to={`pago/`}>
                  <Button variant="success">Continuar</Button>
                </StyledLink>
              )}
            </Col>
          ) : null}
        </Row>
        <Row>
          <Col>
            <p>
              Al Adquirir tu suscripción en Aurea accederas a un gran catalogo
              medico en el cual encontraras la informacion necesaria para
              gestionar y organizar tu consultorio Homeopatico. Aplican{" "}
              <StyledLink to="tyc"> Terminos y condiciones</StyledLink>
            </p>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

const mapStateToProps = (state) => {
  return { payU: state.payU };
};

export default connect(mapStateToProps, {
  setPlan,
})(Planes);

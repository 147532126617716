import React, { useState, useEffect } from "react";
import axios from "axios";
import { Row, Col } from "react-bootstrap";

import Campo from "../detalle/Campo";
import RelacionMedicamentos from "./RelacionMedicamentos";
import StyledLink from "../../general/StyledLink";

const DetalleSintomaHomeopatia = ({ id }) => {
  const [detalle, setDetalle] = useState(null);
  const [capitulos, setCapitulos] = useState(null);

  const fetchData = async () => {
    const response = await axios.get(`/api/SintomaRep/${id}`);
    setDetalle(response.data);

    const chaps = await axios.get("/api/libro/3/capitulo");
    setCapitulos(chaps.data);
  };

  useEffect(() => {
    fetchData();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const validateForm = () => {
    return {};
  };

  const getNombreCapitulo = (id) => {
    if (capitulos) {
      let nombre = "";
      capitulos.forEach((item) => {
        if (item.codCapitulo === id) {
          nombre = item.titulo;
        }
      });
      return nombre;
    }
  };

  if (detalle) {
    const camposNombre = [
      {
        id: "nombre",
        type: "text",
        placeholder: detalle.nombre,
      },
    ];

    const camposCapitulo = [
      {
        id: "capitulo",
        type: "text",
        placeholder: getNombreCapitulo(detalle.codCapitulo),
      },
    ];

    const camposMedicamentos = [
      {
        id: "numeroMedicamentos",
        type: "text",
        placeholder: detalle.numeroMedicamentos,
      },
    ];

    return (
      <Row>
        <Col sm={12}>
          <StyledLink to="/admin/SintomaRep">
            Listado Sintomas Homeopatia
          </StyledLink>
          <h1>Sintoma Homeopatia: {id}</h1>
          <br />
        </Col>
        <Col sm={12} md={6} lg={4}>
          <Campo
            titulo={"Capitulo"}
            detail={getNombreCapitulo(detalle.codCapitulo)}
            campos={camposCapitulo}
            validateForm={validateForm}
            apiRoute={`/api/Sintoma/${id}`}
          />
        </Col>
        <Col sm={12} md={6} lg={4}>
          <Campo
            titulo={"Nombre Sintoma"}
            detail={detalle.nombre}
            campos={camposNombre}
            validateForm={validateForm}
            apiRoute={`/api/Sintoma/${id}`}
          />
        </Col>
        <Col sm={12}>
          <Campo
            titulo={"Numero Medicamentos"}
            detail={detalle.numeroMedicamentos}
            campos={camposMedicamentos}
            validateForm={validateForm}
            apiRoute={`/api/Sintoma/${id}`}
          />
        </Col>
        <Col sm={{ span: 11, offset: 1 }}>
          <RelacionMedicamentos id={id} />
        </Col>
      </Row>
    );
  }
  return <div>Cargando</div>;
};

export default DetalleSintomaHomeopatia;

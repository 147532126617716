import React, { useEffect, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye } from "@fortawesome/free-solid-svg-icons";
import { ToastContainer } from "react-toastify";
import { Toast, Button, Table } from "react-bootstrap";

import StyledLink from "../../general/StyledLink";
import { useDispatch, useSelector } from "react-redux";
import { selectSintomasAcupuntura } from "../../../state/sintomaAcupuntura/selectors";
import { getSindromesSugeridos } from "../../../services/sindrome";
import {
  selectSindromes,
  selectSindromesJerarquizados,
} from "../../../state/sindrome/selectors";
import { agregarSindromes } from "../../../state/sindrome/actions";

const ToastData = ({ title, sintomas }) => {
  const [show, setShow] = useState(false);

  return (
    <>
      <Button
        variant="default"
        data-toggle="tooltip"
        data-placement="top"
        title={`Ver ${title}`}
        onClick={() => {
          setShow(!show);
        }}
      >
        <FontAwesomeIcon icon={faEye} />
      </Button>
      {show ? (
        <Toast show={show} onClose={() => setShow(!show)}>
          <Toast.Header>
            <img
              src="holder.js/20x20?text=%20"
              className="rounded mr-2"
              alt=""
            />
            <strong className="mr-auto">{title}</strong>
          </Toast.Header>
          <Toast.Body>
            {sintomas.map(({ codSintoma, nombre }) => {
              return (
                <div
                  key={codSintoma}
                  className="d-flex justify-content-between"
                >
                  <p>{nombre}</p>
                </div>
              );
            })}
          </Toast.Body>
        </Toast>
      ) : null}

      <ToastContainer />
    </>
  );
};

const TablaSindromes = () => {
  const sintomasAcupuntura = useSelector(selectSintomasAcupuntura);
  const sindromes = useSelector(selectSindromesJerarquizados);
  const dispatch = useDispatch();

  useEffect(() => {
    const cargarSindromes = async () => {
      const codigos = sintomasAcupuntura.map((item) => item.codSintoma);
      const sin = await getSindromesSugeridos(codigos);
      if (sin !== undefined) {
        dispatch(agregarSindromes(sin));
      }
    };
    cargarSindromes();
  }, [dispatch, sintomasAcupuntura]);

  if (sindromes.length === 0) {
    return (
      <p>Agrega sintomas para ver informacion relacionada con los sindromes</p>
    );
  }

  const renderBody = () => {
    return sindromes.map((item, index) => {
      if (index < 10) {
        return (
          <tr key={index}>
            <td>
              <StyledLink
                to={{ pathname: "/libro/2", hash: `#${item.codSindrome}` }}
              >
                {item.sindrome}
              </StyledLink>
            </td>
            <td>
              <div className="d-flex justify-content-between">
                {item.total}
                <ToastData title="sintomas" sintomas={item.sintomas} />
              </div>
            </td>
          </tr>
        );
      }
      return null;
    });
  };

  return (
    <Table striped>
      <thead>
        <tr>
          <th scope="col">Sindrome</th>
          <th scope="col">Número de sintomas</th>
        </tr>
      </thead>
      <tbody>{renderBody()}</tbody>
    </Table>
  );
};

export default TablaSindromes;

import React, { useEffect, useState } from "react";
import { Container, Image, Nav, Navbar } from "react-bootstrap";
import { LinkContainer } from "react-router-bootstrap";

import Logo from "../../assets/img/LogoNav.png";
import useAuth from "../../customHooks/useAuth";
import StyledLink from "./StyledLink";
import "../../css/navigation.css";

const Navigation = (props) => {
  let bread = [];
  const [navegacion, setNavegacion] = useState([]);

  useEffect(() => {
    const nav = props.location.pathname.split("/");
    setNavegacion(nav.slice(1, nav.length));
  }, [props]);

  const { logout, rol, isAuth, suscripcion } = useAuth();

  const linksAuthNav = () => {
    const links = [
      { to: "/pacientes", text: "Pacientes" },
      { to: "/integracion", text: "Repertorización" },
      { to: "/medicamento/1", text: "Materias Medicas" },
      { to: "/libro", text: "Libros Guía" },
    ];

    return links.map(({ to, text }) => {
      return (
        <LinkContainer key={to} to={to}>
          <Nav.Link>{text}</Nav.Link>
        </LinkContainer>
      );
    });
  };

  const userNav = () => {
    const links = [
      { to: "/perfil", text: "Perfil" },
      { to: "/", text: "Salir", click: logout },
    ];

    return links.map(({ to, text, click }) => {
      return (
        <LinkContainer key={to} to={to} onClick={click}>
          <Nav.Link>{text}</Nav.Link>
        </LinkContainer>
      );
    });
  };

  const renderOptions = () => {
    if (isAuth) {
      switch (rol) {
        case "admin":
          return (
            <>
              {linksAuthNav()}
              <LinkContainer to="/admin">
                <Nav.Link>Administrar</Nav.Link>
              </LinkContainer>
              {userNav()}
            </>
          );
        case "colaborador":
          return (
            <>
              {linksAuthNav()}
              {userNav()}
            </>
          );
        default:
          if (
            suscripcion === "ACTIVE" ||
            suscripcion == "PAID" ||
            suscripcion === "PENDING"
          ) {
            return (
              <>
                {linksAuthNav()}
                {userNav()}
              </>
            );
          } else {
            return (
              <>
                <Nav.Link href="/planes">Suscribirme</Nav.Link>
                {userNav()}
              </>
            );
          }
      }
    } else {
      return (
        <>
          <Nav.Link href="/planes">Suscribirme</Nav.Link>
          <Nav.Link href="/contacto">Contacto</Nav.Link>
          <Nav.Link href="/login">Ingreso</Nav.Link>
        </>
      );
    }
  };

  return (
    <header id="header">
      <Container>
        <Navbar expand="lg" className="nav-menu">
          <Navbar.Brand href="/">
            <Image src={Logo} alt="Logo" fluid />
          </Navbar.Brand>

          <Navbar.Toggle aria-controls="aurea-navbar-nav" />
          <Navbar.Collapse id="aurea-navbar-nav">
            <Nav className="ml-auto">{renderOptions()}</Nav>
          </Navbar.Collapse>
        </Navbar>
      </Container>
      <ol className="breadcrumb">
        <li className="breadcrumb-item">
          <StyledLink to="/">Home</StyledLink>
        </li>
        {navegacion.map((item) => {
          bread += "/" + item;
          return (
            <li
              className="breadcrumb-item active"
              aria-current="page"
              key={item}
            >
              <StyledLink to={bread}>{item}</StyledLink>
            </li>
          );
        })}
      </ol>
    </header>
  );
};

export default Navigation;

import React from "react";
import { Row, Col } from "react-bootstrap";

const Acompaniante = ({ data }) => {
  return (
    <React.Fragment>
      <Row>
        <Col>
          <h3>
            {data.nombre} {data.apellido}
          </h3>
        </Col>
      </Row>
      <hr />
      <Row>
        <Col lg={3} sm={6}>
          <dl>
            <dt>Direccion</dt>
            <dd>{data.direccion}</dd>
          </dl>
        </Col>
        <Col lg={3} sm={6}>
          <dl>
            <dt>Telefono Casa</dt>
            <dd>{data.tel}</dd>
          </dl>
        </Col>
        <Col lg={3} sm={6}>
          <dl>
            <dt>Telefono Oficina</dt>
            <dd>{data.telOficina}</dd>
          </dl>
        </Col>
        <Col lg={3} sm={6}>
          <dl>
            <dt>Celular</dt>
            <dd>{data.cel}</dd>
          </dl>
        </Col>
      </Row>
    </React.Fragment>
  );
};

export default Acompaniante;

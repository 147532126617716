import React, { useState, useEffect } from "react";
import axios from "axios";
import { Row, Col, Nav, Navbar } from "react-bootstrap";

import StyledLink from "../general/StyledLink";

const Repertorio = () => {
  const [data, setData] = useState(null);
  const [capitulo, setCapitulo] = useState(30);
  const [letra, setLetra] = useState("A");
  const [capitulos, setCapitulos] = useState(null);

  const fetchChaps = async () => {
    const chaps = await axios.get("/api/libro/3/capitulo");
    setCapitulos(chaps.data);
  };
  const fetchData = async () => {
    const response = await axios.get(`/api/repertorio/${capitulo}/${letra}`);
    setData(response.data);
  };

  useEffect(() => {
    fetchChaps();
    fetchData();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    setData(null);
    fetchData();
  }, [letra, capitulo]); // eslint-disable-line react-hooks/exhaustive-deps

  const renderCapitulos = () => {
    if (capitulos) {
      return (
        <div className="scroll-menu-medicamento">
          {capitulos.map((i) => {
            return (
              <p
                style={{ cursor: "pointer" }}
                onClick={() => {
                  setCapitulo(i.codCapitulo);
                }}
                className={i.codCapitulo === capitulo ? "active" : null}
                key={i.codCapitulo}
              >
                {i.titulo}
              </p>
            );
          })}
        </div>
      );
    }
    return <div>Cargando</div>;
  };

  const renderLetras = () => {
    const letras = [
      "A",
      "B",
      "C",
      "D",
      "E",
      "F",
      "G",
      "H",
      "I",
      "J",
      "K",
      "L",
      "M",
      "N",
      "O",
      "P",
      "Q",
      "R",
      "S",
      "T",
      "U",
      "V",
      "W",
      "X",
      "Y",
      "Z",
    ];
    if (data) {
      return (
        <Navbar expand="lg">
          <Navbar.Toggle aria-controls="basic-navbar-nav" />
          <Navbar.Collapse id="basic-navbar-nav">
            <Nav className="mr-auto">
              {letras.map((l) => {
                return (
                  <Nav.Link
                    onClick={() => {
                      setLetra(l);
                    }}
                    className={l === letra ? "active" : null}
                  >
                    {l}
                  </Nav.Link>
                );
              })}
            </Nav>
          </Navbar.Collapse>
        </Navbar>
      );
    }
    return <div>Cargando</div>;
  };

  const renderContenido = () => {
    return (
      <div className="scroll-medicamento noselect">
        {data.map((item) => {
          const texto = item.nombre.split(":/");
          const separadores = texto.length - 2;
          const nombre = texto[separadores];

          return (
            <Row style={{ textAlign: "justify" }}>
              <Col md={{ span: 11 - separadores, offset: separadores }}>
                <span className={"repertorio-titulo"}>- {nombre}: </span>
                {item.valor.map((med) => {
                  return (
                    <StyledLink
                      to={`/medicamento/${med.codMedicamento}`}
                      key={med.codMedicamento}
                    >
                      <span className={`repertorio-${med.valor}`}>
                        {med.medicamento},{" "}
                      </span>
                    </StyledLink>
                  );
                })}
              </Col>
              <Col md={{ offset: 1 }} />
            </Row>
          );
        })}
      </div>
    );
  };

  const getNombreCapitulo = () => {
    let nombre = "";
    capitulos.forEach((item) => {
      if (item.codCapitulo === capitulo) {
        nombre = item.titulo;
      }
    });
    return nombre;
  };

  return (
    <div className="my-container">
      <Row>
        <Col xs={3} lg={2}>
          {renderCapitulos()}
        </Col>
        <Col>
          <Row>
            <Col xs={12}>{renderLetras()}</Col>
            <Col xs={12}>
              {capitulos ? <h1>{getNombreCapitulo()}</h1> : null}

              <h2>{letra}</h2>
              {data ? renderContenido() : null}
            </Col>
          </Row>
        </Col>
      </Row>
    </div>
  );
};

export default Repertorio;

import React, {useState} from "react";
import {Form} from "react-bootstrap"

const SearchOnPage = () => {

    const [search, setSearch]= useState("")

  const handleSearchSubmit = (evt) => {
    evt.preventDefault();
    window.find(search)
  };

  const handleSearchChange = (evt) => {
    const value = evt.target.value;
    setSearch(value);
  };



  return (
    <Form onSubmit={handleSearchSubmit}>
      <Form.Group controlId="FindOnBook">
        <Form.Control
          type="text"
          placeholder="Busca dentro del libro"
          onChange={handleSearchChange}
        />
      </Form.Group>
    </Form>
  );
};

export default SearchOnPage;

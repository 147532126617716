import React, { useState, useEffect } from 'react';
import axios from 'axios';
import JsxParser from 'react-jsx-parser';
import { Container, Row, Col, ListGroup, Image, Navbar } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBars } from '@fortawesome/free-solid-svg-icons';

import Dropdown from './Dropdown';
import PuntoAcupuntura from './PuntoAcupuntura';
import ScrollToTopBtn from '../general/ScrollToTop';
import SearchOnPage from '../general/SearchOnPage';
import '../../css/libro.css';

const CapituloAtlas = ({ titulo, propiedad, imagen, capitulos }) => {
  const tituloP = titulo;
  return (
    <div className="chap-container">
      <h2 id={titulo} className={`${propiedad}`}>
        {titulo}
      </h2>
      <Image src={imagen} fluid />
      {capitulos
        ? capitulos.map(({ titulo, contenido, img, puntos }, index) => {
            return (
              <div className="sub-chap-container" key={index}>
                <h3 id={`${tituloP}-${titulo}`}>{titulo}</h3>
                <Row>
                  <Col>
                    <JsxParser jsx={contenido} />
                  </Col>
                  {img ? (
                    <Col className={index % 2 === 0 ? 'order-first' : ''}>
                      <Image src={img} fluid />
                    </Col>
                  ) : null}
                </Row>
                <Row>
                  <Col>
                    {puntos
                      ? puntos.map((punto) => {
                          return (
                            <PuntoAcupuntura
                              data={punto}
                              key={punto.codAcupuntura}
                            />
                          );
                        })
                      : null}
                  </Col>
                </Row>
              </div>
            );
          })
        : null}
    </div>
  );
};

const Atlas = () => {
  const [libro, setLibro] = useState([]);
  const [showCapitulos, setShowCapitulos] = useState(false);

  const fetchAtlas = async () => {
    const response = await axios.get(`/api/libro/Atlas`);
    setLibro(response.data);
    if (window.location.hash) {
      const hash = window.location.hash.replace('#', '');
      if (!isNaN(hash)) {
        document.getElementById(hash).scrollIntoView();
      }
    }
  };

  useEffect(() => {
    fetchAtlas();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const renderListaCapitulos = () => {
    return (
      <Navbar id="sidebar">
        <ListGroup>
          <ListGroup.Item className="capitulo">
            <a href="#Atlas">Atlas de meridianos</a>
          </ListGroup.Item>
          {libro.map(({ titulo, capitulos }) => {
            if (capitulos.length > 0) {
              return (
                <Dropdown nombre={titulo} capitulos={capitulos} key={titulo} />
              );
            }
            return (
              <ListGroup.Item key={titulo}>
                <a href={`#${titulo}`}>{titulo}</a>
              </ListGroup.Item>
            );
          })}
          <ListGroup.Item className="capitulo"></ListGroup.Item>
        </ListGroup>
      </Navbar>
    );
  };

  const renderAtlas = () => {
    return (
      <div
        className="book-container"
        onCut={(event) => {
          event.preventDefault();
        }}
        onCopy={(event) => {
          event.preventDefault();
        }}
      >
        <h1 id="Atlas">
          ATLAS DE MERIDIANOS ( Canales ) Y PUNTOS DE ACUPUNTURA
        </h1>
        <ListGroup>
          {libro.map(({ titulo, propiedad }) => {
            return (
              <ListGroup.Item className={`${propiedad}`} key={titulo}>
                <h3>
                  <a href={`#${titulo}`}>{titulo}</a>
                </h3>
              </ListGroup.Item>
            );
          })}
        </ListGroup>
        {libro.map(({ titulo, propiedad, imagen, capitulos }) => {
          return (
            <CapituloAtlas
              key={titulo}
              titulo={titulo}
              propiedad={propiedad}
              imagen={imagen}
              capitulos={capitulos}
            />
          );
        })}
      </div>
    );
  };

  return (
    <Container fluid id="atlas-container">
      <Row className="sticky-top-menu">
        <Col xs={2}>
          <FontAwesomeIcon
            onClick={() => setShowCapitulos(!showCapitulos)}
            icon={faBars}
            size="2x"
          />
        </Col>
        <Col>
          <SearchOnPage />
        </Col>
      </Row>
      <Row>
        {showCapitulos ? (
          <Col xs={12} sm={3} lg={3} id="sidebar-wrapper">
            {renderListaCapitulos()}
          </Col>
        ) : null}

        <Col>
          {renderAtlas()}
          <ScrollToTopBtn />
        </Col>
      </Row>
    </Container>
  );
};

export default Atlas;

import axios from "axios";
import _ from "lodash";
import React, { useEffect, useState } from "react";
import { Col, Row, Form, Button } from "react-bootstrap";
import { PaymentInputsWrapper, usePaymentInputs } from "react-payment-inputs";
import images from "react-payment-inputs/images";
import { connect } from "react-redux";

import "../../../css/pagos.css";
import useAuth from "../../../customHooks/useAuth";
import useHistory from "../../../customHooks/useHistory";
import StyledLink from "../StyledLink";

import { all_plans } from "./config";

const PayU = (props) => {
  const {
    wrapperProps,
    getCardImageProps,
    getCardNumberProps,
    getExpiryDateProps,
    getCVCProps,
  } = usePaymentInputs();
  const { logout } = useAuth();
  const { history } = useHistory();

  const [estado, setEstado] = useState(null);
  const [procesando, setProcesando] = useState(false);
  const [activada, setActivada] = useState(false);
  const [validationErrors, setValidationErrors] = useState({
    email: "",
    docNumber: "",
    city: "",
    address: "",
    phone: "",
    cardholderName: "",
    cardNumber: "",
    submit: "",
  });
  const [values, setValues] = useState({
    email: "",
    docNumber: "",
    city: "",
    address: "",
    phone: "",
    cardholderName: "",
    cvc: "",
    expiry: "",
    cardType: "Visa",
    cardNumber: "",
  });

  useEffect(() => {
    if (props.plan in all_plans) {
      return;
    }
    history.push("/planes");
  }, []);

  const handleChange = (evt) => {
    setValues({
      ...values,
      [evt.target.name]: evt.target.value,
    });
  };

  const validateForm = () => {
    const err = {};
    if (values.email.length === 0) {
      err.email = "Se requiere un correo para facturación";
    }
    if (values.docNumber.length === 0) {
      err.docNumber = "Se requiere el número de documento";
    }
    if (values.city.length === 0) {
      err.city = "Se requiere la ciudad de residencia";
    }
    if (values.address.length === 0) {
      err.address = "Se requiere una dirección";
    }
    if (values.phone.length === 0) {
      err.phone = "Se requiere el número de celular";
    }
    if (values.cardholderName.length === 0) {
      err.cardholderName = "Se requiere el nombre del titular";
    }
    if (values.cardNumber.length === 0) {
      err.cardNumber = "Se requiere el número de tarjeta";
    }
    if (values.cardNumber.length < 6) {
      err.cardNumber = "Ingrese un número valido";
    }
    return err;
  };

  const submitForm = async (event) => {
    event.preventDefault();
    setValidationErrors(validateForm());
    values.cardType = getCardImageProps()["aria-label"];
    if (_.isEmpty(validationErrors)) {
      setProcesando(true);
      axios
        .patch(`/api/subscribe/${props.plan}`, values)
        .then((response) => {
          if (response.status === 200) {
            setActivada(true);
            setEstado(response.data.description);
          }
          setProcesando(false);
        })
        .catch((error) => {
          setValidationErrors({ submit: error.response.data.description });
          setProcesando(false);
        });
    }
  };

  if (!props.plan) {
    return <></>;
  }

  /*return (
    <section className="payment-form dark">
      <div className="container_payment">
        <div className="block-heading">
          <h1>Disculpa las molestias</h1>
          <p>
            En el momento estamos presentando dificultades tecnicas, revisa en
            unos días para poder acceder a todo el contenido de Aurea
          </p>
        </div>
      </div>
    </section>
  );*/
  return (
    <section className="payment-form dark">
      <div className="container_payment">
        <div className="block-heading">
          <h1>Realizar Pago</h1>
          <p>
            En seguida puedes realizar el pago por medio de PayU, para adquirir
            la subscripción {props.plan ? props.plan : null} por un costo de{" "}
            {all_plans[props.plan].precio} {all_plans[props.plan].periodo}
          </p>
        </div>
        <div className="form-payment">
          {procesando ? (
            <div className="bouncer-container">
              <h2>Procesando Pago</h2>
              <p>
                Secured by <span>PayU</span>
              </p>
              <div className="bouncer">
                <div></div>
                <div></div>
                <div></div>
                <div></div>
              </div>
            </div>
          ) : null}
          <div className="payment-details">
            <Form onSubmit={submitForm} id="paymentForm">
              <h2 className="title">Detalles del comprador</h2>
              <Row>
                <Col>
                  <Form.Label htmlFor="email">E-Mail</Form.Label>
                  <Form.Control
                    onChange={handleChange}
                    value={values.email}
                    type="text"
                    placeholder="correo@gmail.com"
                    name="email"
                    className={validationErrors["email"] ? "form-error" : ""}
                  />
                  {validationErrors["email"] && (
                    <p className="error-small">{validationErrors["email"]}</p>
                  )}
                </Col>
              </Row>
              <Row>
                <Col>
                  <Form.Label htmlFor="docNumber">
                    Número de documento
                  </Form.Label>
                  <Form.Control
                    onChange={handleChange}
                    value={values.docNumber}
                    type="text"
                    name="docNumber"
                    className={
                      validationErrors["docNumber"] ? "form-error" : ""
                    }
                  />
                  {validationErrors["docNumber"] && (
                    <p className="error-small">
                      {validationErrors["docNumber"]}
                    </p>
                  )}
                </Col>
              </Row>
              <Row>
                <Col xs={6}>
                  <Form.Label htmlFor="city">Ciudad</Form.Label>
                  <Form.Control
                    onChange={handleChange}
                    placeholder="Bogotá"
                    value={values.city}
                    type="text"
                    name="city"
                    className={validationErrors["city"] ? "form-error" : ""}
                  />
                  {validationErrors["city"] && (
                    <p className="error-small">{validationErrors["city"]}</p>
                  )}
                </Col>
                <Col>
                  <Form.Label htmlFor="phone">Celular</Form.Label>
                  <Form.Control
                    onChange={handleChange}
                    value={values.phone}
                    type="text"
                    name="phone"
                    className={validationErrors["phone"] ? "form-error" : ""}
                  />
                  {validationErrors["phone"] && (
                    <p className="error-small">{validationErrors["phone"]}</p>
                  )}
                </Col>
              </Row>
              <Row>
                <Col>
                  <Form.Label htmlFor="address">Dirección</Form.Label>
                  <Form.Control
                    onChange={handleChange}
                    value={values.address}
                    type="text"
                    name="address"
                    className={validationErrors["address"] ? "form-error" : ""}
                  />
                  {validationErrors["address"] && (
                    <p className="error-small">{validationErrors["address"]}</p>
                  )}
                </Col>
              </Row>
              <br />
              <h2 className="title">Detalles de la tarjeta</h2>
              <Row>
                <Col>
                  <Form.Label htmlFor="cardholderName">Titular</Form.Label>
                  <Form.Control
                    onChange={handleChange}
                    value={values.cardholderName}
                    type="text"
                    name="cardholderName"
                    className={
                      validationErrors["cardholderName"] ? "form-error" : ""
                    }
                  />
                  {validationErrors["cardholderName"] && (
                    <p className="error-small">
                      {validationErrors["cardholderName"]}
                    </p>
                  )}
                </Col>

                <Col sm={12}>
                  <div className="payment-wrapper">
                    <PaymentInputsWrapper
                      {...wrapperProps}
                      style={{ marginTop: "15px" }}
                    >
                      <svg {...getCardImageProps({ images })} />
                      <input
                        {...getCardNumberProps({
                          onChange: (evt) => {
                            setValues({
                              ...values,
                              cardNumber: evt.target.value,
                            });
                          },
                        })}
                      />
                      <input
                        {...getExpiryDateProps({
                          onChange: (evt) => {
                            setValues({
                              ...values,
                              expiry: evt.target.value,
                            });
                          },
                        })}
                      />
                      <input
                        {...getCVCProps({
                          onChange: (evt) => {
                            setValues({
                              ...values,
                              cvc: evt.target.value,
                            });
                          },
                        })}
                      />
                    </PaymentInputsWrapper>
                  </div>
                </Col>
              </Row>
              <Button
                variant="primary"
                type="submit"
                className={
                  validationErrors["submit"]
                    ? "form-error btn btn-primary btn-block"
                    : "btn btn-primary btn-block"
                }
              >
                Pagar Suscripcion
              </Button>
              {validationErrors["submit"] && (
                <p className="error-small">{validationErrors["submit"]}</p>
              )}
            </Form>
            {activada ? (
              <Row>
                <Col>
                  <p>El estado de su transacción es: {estado}</p>
                  <p>
                    Felicidades su suscripción ha sido activada correctamente,
                    para actualizar los cambios vuelva a entrar a la aplicación
                    dando{" "}
                    <StyledLink to="/login" onClick={logout}>
                      click aqui
                    </StyledLink>
                  </p>
                </Col>
              </Row>
            ) : null}
          </div>
        </div>
      </div>
    </section>
  );
};

const mapStateToProps = (state) => {
  return { plan: state.payU };
};

export default connect(mapStateToProps, {})(PayU);

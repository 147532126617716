import React from "react";
import { Container } from "react-bootstrap";

const Tutorial = () => {
  return (
    <Container responsive>
      <h1> Tutorial Aurea</h1>
      <br />
      <div class="embed-responsive embed-responsive-16by9">
        <video class="video-fluid z-depth-1" controls>
          <source
            src="https://s3-aurea-pb.s3.us-east-2.amazonaws.com/tutoriales/Aurea+video+tutorial+Pacientes.mp4"
            type="video/mp4"
          />
        </video>
      </div>

      <br />
      <div class="embed-responsive embed-responsive-16by9">
        <video class="video-fluid z-depth-1" controls>
          <source
            src="https://s3-aurea-pb.s3.us-east-2.amazonaws.com/tutoriales/Aurea+video+tutorial+Repertotizaci%C3%B3n.mp4"
            type="video/mp4"
          />
        </video>
      </div>
      <br />
    </Container>
  );
};

export default Tutorial;

import { ADD_SINDROME } from "./types";

export default (state = [], action) => {
  switch (action.type) {
    case ADD_SINDROME:
      return action.payload;
    default:
      return state;
  }
};

import axios from "axios";

import { ADD_SINDROME } from "./types";

export const addSindromes = (codigosSintoma) => async (dispatch) => {
  const response = await axios.get("/api/sindrome_sugerido/", {
    params: {
      codigos: codigosSintoma,
    },
  });
  // dispatch({ type: ADD_SINDROME, payload: response.data });
};

export const agregarSindromes = (sindromes) => {
  return { type: ADD_SINDROME, payload: sindromes };
};

import React from "react";
import { Row, Col } from "react-bootstrap";

import Campo from "../detalle/Campo";

const SubCapitulo = ({ data, validateForm, id }) => {
  const camposTexto = [
    {
      id: "capitulo.texto",
      type: "textarea",
      placeholder: data.texto,
    },
  ];

  return (
    <Row>
      <Col sm={12}>
        <Campo titulo={"Nombre SubCapitulo"} detail={data.nombre} />
      </Col>
      <Col sm={12}>
        <Campo
          titulo={"Contenido SubCapitulo"}
          detail={data.texto}
          editable={false}
        />
      </Col>
    </Row>
  );
};

export default SubCapitulo;

import { createSelector } from "reselect";

export const selectSintomasAcupuntura = (state) =>
  state.holonmed.sintomasAcupuntura;

export const selectCodigosSintomasAcupuntura = createSelector(
  [(state) => state.holonmed.sintomasAcupuntura],
  (sintomasAcupuntura) => {
    return sintomasAcupuntura.map((item) => item.codSintoma);
  }
);

import React, { useEffect, useState } from "react";
import axios from "axios";
import { Container } from "react-bootstrap";

import TablaPacientes from "./TablaPacientes";
import RegistrarPaciente from "./RegistrarPaciente";
import SearchBar from "../general/SearchBar";

const Pacientes = () => {
  const [pacientes, setPacientes] = useState(null);
  const [filtro, setFiltro] = useState(null);
  const [update, setUpdate] = useState(true);

  const fetchData = async () => {
    const response = await axios.get("/api/paciente");
    setPacientes(response.data);
    setFiltro(response.data);
  };

  useEffect(() => {
    fetchData();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    fetchData();
  }, [update]); // eslint-disable-line react-hooks/exhaustive-deps

  const filterFunction = (value) => {
    if (value.length > 0) {
      setFiltro(
        pacientes.filter(
          (item) =>
            item.nombre.toLowerCase().includes(`${value}`.toLowerCase()) ||
            item.apellido.toLowerCase().includes(`${value}`.toLowerCase()) ||
            item.documento.toLowerCase().includes(`${value}`.toLowerCase())
        )
      );
    } else {
      setFiltro(pacientes);
    }
  };

  if (filtro && pacientes) {
    return (
      <div id="watermark">
        <Container>
          <SearchBar title="Buscar Paciente" filterFunction={filterFunction} />
          <TablaPacientes data={filtro} />
          <RegistrarPaciente setUpdate={setUpdate} update={update} />
        </Container>
      </div>
    );
  }
  return <Container>Cargando</Container>;
};

export default Pacientes;

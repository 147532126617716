import React, { useState, useEffect } from "react";
import axios from "axios";
import { Row, Col, Button, ListGroup } from "react-bootstrap";
import ModalConfirmar from "../../general/ModalConfirmar";
import SearchBar from "../../general/SearchBar";

const RelacionMedicamento = ({ id, relacion }) => {
  const [agregar, setAgregar] = useState(false);
  const [filtered, setFiltered] = useState([]);
  const [medicamentos, setMedicamentos] = useState([]);

  const fetchData = async () => {
    const response = await axios.get("/api/medicamentos/nombres");
    setMedicamentos(response.data);
    setFiltered(response.data);
  };

  useEffect(() => {
    fetchData();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const filterFunction = (value) => {
    if (value.length > 0) {
      setFiltered(
        medicamentos.filter((med) =>
          med.medicamento.toLowerCase().includes(`${value}`.toLowerCase())
        )
      );
    } else {
      setFiltered(medicamentos);
    }
  };

  const agregarMedicamento = async (medicamento) => {
    const send = {
      codMedicamento: medicamento.codMedicamento,
      medicamento: medicamento.medicamento,
      codSindrome: id,
    };
    try {
      const res = await axios.post("/api/SindromeMedicamento", send);
      alert(res.statusText);
    } catch (err) {
      alert("El medicamento seleccionado ya está relacionado con el sindrome");
    }
  };

  const renderList = () => {
    return (
      <ListGroup style={{ maxHeight: "300px", overflowY: "scroll" }}>
        {filtered.map((item) => {
          return (
            <ListGroup.Item
              key={item.codMedicamento}
              action
              onClick={() => agregarMedicamento(item)}
            >
              {item.medicamento}
            </ListGroup.Item>
          );
        })}
      </ListGroup>
    );
  };

  const borrar = async (codMed) => {
    await axios.delete(`/api/sindrome/${id}/medicamento/${codMed}`);
    fetchData();
  };

  return (
    <Row>
      <Col sm={12}>
        <p style={{ fontWeight: "bold" }}>Relacion con Medicamentos</p>
        {relacion ? (
          <ListGroup>
            {relacion.map((item) => {
              return (
                <ListGroup.Item key={item.codMedicamento}>
                  {item.medicamento}
                  <ModalConfirmar
                    borrar={() => borrar(item.codMedicamento)}
                    cod={id}
                    nombre={item.medicamento}
                  />
                </ListGroup.Item>
              );
            })}
          </ListGroup>
        ) : null}
        <br />
        <Button
          variant="warning"
          onClick={() => {
            setAgregar(!agregar);
          }}
        >
          Relacionar con Medicamentos de Homeopatia
        </Button>
        {agregar ? (
          <div>
            <br />
            <SearchBar
              title="Buscar Medicamento"
              filterFunction={filterFunction}
            />
            {renderList()}
          </div>
        ) : null}
      </Col>
    </Row>
  );
};

export default RelacionMedicamento;

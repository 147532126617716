import React, { useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCrown } from "@fortawesome/free-solid-svg-icons";
import { Button } from "react-bootstrap";
import { jerarquizarSintomaHomeopatia } from "../../../../../state/sintoma/actions";
import { useDispatch } from "react-redux";

const Jerarquico = ({ item }) => {
  const [pin, setPin] = useState(item.jerarquizado);
  const dispatch = useDispatch();
  return (
    <Button
      variant="default"
      data-toggle="tooltip"
      data-placement="top"
      title={pin ? "Desanclar" : "Jerarquización"}
      onClick={() => {
        dispatch(jerarquizarSintomaHomeopatia(item, pin));
        setPin(!pin);
      }}
    >
      <FontAwesomeIcon icon={faCrown} color={pin ? "green" : "black"} />
    </Button>
  );
};

export default Jerarquico;

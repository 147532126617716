import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSyringe } from "@fortawesome/free-solid-svg-icons";
import { Card } from "react-bootstrap";

import TablaPuntos from "./TablaPuntos";

const Acupuntura = () => {
  return (
    <Card>
      <Card.Header as="h3">
        Puntos de acupuntura sugeridos <FontAwesomeIcon icon={faSyringe} />
      </Card.Header>
      <Card.Body>
        <TablaPuntos />
      </Card.Body>
    </Card>
  );
};

export default Acupuntura;

import React, { useEffect, useState } from "react";
import { Row, Col } from "react-bootstrap";
import axios from "axios";

import Formulario from "../../Formulario";
import StyledLink from "../../general/StyledLink";

const Nuevo = () => {
  const [capitulos, setCapitulos] = useState(null);

  const fetchData = async () => {
    const todos = await axios.get("/api/libro/3/capitulo");
    setCapitulos(todos.data);
  };

  useEffect(() => {
    fetchData();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const validateForm = (values) => {
    const err = {};
    if (values.nombre.length === 0) {
      err.nombre = "Ingresa un Sintoma";
    }
    return err;
  };

  const renderForm = () => {
    if (capitulos) {
      let options = [];
      capitulos.forEach((chap) =>
        options.push({ label: chap.titulo, id: chap.codCapitulo })
      );
      const campos = [
        {
          label: "Nombre Sintoma",
          id: "nombre",
          type: "text",
        },
        {
          label: "Capitulo",
          id: "codCapitulo",
          type: "select",
          placeholder: options[0].id,
          options: options,
        },
      ];
      return (
        <Formulario
          campos={campos}
          validateForm={validateForm}
          apiRoute={"/api/Sintoma"}
          enviar="Crear Nuevo Sintoma"
        />
      );
    }
    return null;
  };

  return (
    <Row>
      <Col xs={12}>
        <StyledLink to="/admin/Sintoma">Listado Sintomas</StyledLink>
      </Col>
      <Col>
        <h1>Registro Nuevo Sintoma</h1>
        {renderForm()}
      </Col>
    </Row>
  );
};

export default Nuevo;

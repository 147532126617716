import React, { useState, useEffect } from "react";
import axios from "axios";
import { useDispatch } from "react-redux";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlusCircle } from "@fortawesome/free-solid-svg-icons";
import { Table, Button, Modal } from "react-bootstrap";

import { agregarSintomaAcupuntura } from "../../../../../../state/sintomaAcupuntura/actions";

const ModalRelacion = (props) => {
  const [relacion, setRelacion] = useState([]);
  const dispatch = useDispatch();

  useEffect(() => {
    const fetchData = async () => {
      const response = await axios.get(`/api/sintomaRep/${props.cod}/sintoma`);
      setRelacion(response.data);
    };
    fetchData();
  }, [props]);

  const renderBody = () => {
    return relacion.map((item, index) => {
      return (
        <tr key={index}>
          <td>{item.nombre}</td>
          <td>
            <Button
              variant="default"
              data-toggle="tooltip"
              data-placement="top"
              title="Agregar sintoma"
              onClick={() => {
                alert(`Se agrego el sintoma de acupuntura ${item.nombre}`);
                dispatch(agregarSintomaAcupuntura(item));
              }}
            >
              <FontAwesomeIcon icon={faPlusCircle} />
            </Button>
          </td>
        </tr>
      );
    });
  };

  return (
    <Modal
      {...props}
      aria-labelledby="contained-modal-title-vcenter"
      size="lg"
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
          Relacionar con Sintomas de Acupuntura
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Table striped bordered hover>
          <thead>
            <tr>
              <th>Sintoma</th>
              <th>Agregar</th>
            </tr>
          </thead>
          <tbody>
            {relacion.length > 0 ? (
              renderBody()
            ) : (
              <tr>
                <td>El sintoma no tiene relaciones</td>
                <td></td>
              </tr>
            )}
          </tbody>
        </Table>
      </Modal.Body>
    </Modal>
  );
};

export default ModalRelacion;

import React from "react";
import { Row, Col, Card, Button, Image, Container } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faGoogle } from "@fortawesome/free-brands-svg-icons";

import Logo from "../assets/img/Logo.png";

const Login = () => {
  return (
    <Container>
      <Row className="justify-content-center">
        <Col xs={6} md={3}>
          <Card>
            <Card.Body>
              <Card.Title>Login</Card.Title>
              <div className="text-center">
                <Card.Text>Ingresa a la aplicación con Google</Card.Text>
                <a href="/auth/google">
                  <Button variant="outline-primary">
                    <FontAwesomeIcon icon={faGoogle} /> Google
                  </Button>
                </a>
              </div>
            </Card.Body>
          </Card>
        </Col>
        <Col>
          <Image src={Logo} alt="Logo" fluid />
        </Col>
      </Row>
    </Container>
  );
};

export default Login;

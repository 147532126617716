import { SET_PAYU_PLAN } from "./types";

export default (state = null, action) => {
  switch (action.type) {
    case SET_PAYU_PLAN:
      return action.payload;
    default:
      return state;
  }
};

import React from 'react';
import { Link } from 'react-router-dom';
import { Container, Row, Col } from 'react-bootstrap';

import useAuth from '../../customHooks/useAuth';
import '../../css/hero.css';

const Hero = () => {
  const { isAuth } = useAuth();
  return (
    <section id="hero">
      <div className="my-container">
        <div id="Aurea">
          <h1>Aurea</h1>
          <h2>
            La mejor plataforma para manejar tu consulta de medicina integrativa
          </h2>
        </div>
      </div>
      <Container>
        <Row>
          <Col xs={12} lg={5}>
            <div className="content">
              <h3>¿Que es Aurea?</h3>
              <p>
                Es una aplicación que te permite integrar conceptos y
                conocimientos de la acupuntura y la homeopatía, para facilitar
                el diagnostico, aplicación terapéutica y selección de
                medicamentos para el manejo de tus pacientes.
              </p>
              <div className="text-center">
                <a href="#servicios" className="more-btn">
                  mas información
                </a>
              </div>
            </div>
          </Col>
          <Col xs={12} lg={4}>
            <div className="content-small">
              <h4>Información Confiable</h4>
              <p>
                Búsqueda y repertorización de síntomas en el repertorio
                homeopático y acupuntural
              </p>
            </div>
          </Col>
          <Col xs={12} lg={3}>
            {isAuth ? (
              <Link to="/integracion" className="btn-get-started scrollto">
                Ir a repertorizar
              </Link>
            ) : (
              <Link to="/planes" className="btn-get-started scrollto">
                Compre ahora
              </Link>
            )}
            <Link to="/tutorial" className="btn-get-started-2 scrollto">
              Tutorial
            </Link>
          </Col>
        </Row>
      </Container>
    </section>
  );
};

export default Hero;

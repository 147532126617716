import React from "react";

import NuevoSindrome from "./Sindrome/Nuevo";
import NuevoSintoma from "./sintomaAcupuntura/Nuevo";

const Nuevo = (props) => {
  const renderForm = () => {
    switch (props.match.params.handle) {
      case "Sintoma":
        return <NuevoSintoma />;
      case "Sindrome":
        return <NuevoSindrome />;
      default:
        return <NuevoSindrome />;
    }
  };
  return <div className="container">{renderForm()}</div>;
};

export default Nuevo;

import React from "react";
import { Row, Col, Image } from "react-bootstrap";

const Paciente = ({ data }) => {
  return (
    <React.Fragment>
      <Row>
        <Col className="d-none d-sm-block" sm={4} md={1}>
          <Image src={data.img} rounded alt="imagen paciente" fluid />
        </Col>
        <Col>
          <h1>
            {data.nombre} {data.apellido}
          </h1>
        </Col>
      </Row>
      <hr />
      <Row>
        <Col xs={12} sm={6} lg={3}>
          <dl>
            <dt>Documento de identificación</dt>
            <dd>
              {data.tipoDoc}:{data.documento}
            </dd>
            <dt>Fecha de nacimiento</dt>
            <dd>{data.nacimiento.toString().split("T")[0]}</dd>
            <dt>Edad</dt>
            <dd>{data.edad}</dd>
            <dt>Sexo</dt>
            <dd>{data.sexo}</dd>
          </dl>
        </Col>
        <Col xs={12} sm={6} lg={3}>
          <dl>
            <dt>Estado Civil</dt>
            <dd>{data.estadoCivil}</dd>
            <dt>Nacionalidad</dt>
            <dd>{data.nacionalidad}</dd>
            <dt>Ciudad Residencia</dt>
            <dd>{data.ciudad}</dd>
            <dt>Direccion</dt>
            <dd>{data.direccion}</dd>
          </dl>
        </Col>
        <Col xs={12} sm={6} lg={3}>
          <dl>
            <dt>Correo</dt>
            <dd>{data.correo}</dd>
            <dt>Telefono Casa</dt>
            <dd>{data.tel}</dd>
            <dt>Telefono Oficina</dt>
            <dd>{data.telOficina}</dd>
            <dt>Celular</dt>
            <dd>{data.cel}</dd>
          </dl>
        </Col>
        <Col xs={12} sm={6} lg={3}>
          <dl>
            <dt>Ocupación</dt>
            <dd>{data.ocupacion}</dd>
            <dt>EPS </dt>
            <dd>{data.eps}</dd>
            <dt>Creencias religiosas</dt>
            <dd>{data.religion}</dd>
          </dl>
        </Col>
      </Row>
    </React.Fragment>
  );
};

export default Paciente;

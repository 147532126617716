import axios from "axios";

import {
  ADD_SINTOMA_ACUPUNTURA,
  DELETE_SINTOMA_ACUPUNTURA,
  RESTART_SINTOMA_ACUPUNTURA,
  UPDATE_SINTOMA_ACUPUNTURA,
} from "./types";

export const agregarSintomaAcupuntura = (sintoma) => {
  return { type: ADD_SINTOMA_ACUPUNTURA, payload: sintoma };
};

export const borrarSintomaAcupuntura = (codSintoma) => async (dispatch) => {
  dispatch({ type: DELETE_SINTOMA_ACUPUNTURA, payload: codSintoma });
};

export const jerarquizarSintomaAcupuntura = (sintoma, pin) => {
  return {
    type: UPDATE_SINTOMA_ACUPUNTURA,
    payload: { ...sintoma, jerarquizado: !pin },
  };
};

export const reiniciarSintomaAcupuntura = () => {
  return { type: RESTART_SINTOMA_ACUPUNTURA };
};

import {
  ADD_SINTOMA,
  DELETE_SINTOMA,
  UPDATE_SINTOMA,
  RESTART_SINTOMA,
} from "./types";

const INITIAL_STATE = [];

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case ADD_SINTOMA:
      return [...state, action.payload];
    case DELETE_SINTOMA:
      return state.filter(
        (element) => element.codSintomaRep !== action.payload
      );
    case UPDATE_SINTOMA:
      return state.map((element) => {
        return element.codSintomaRep === action.payload.codSintomaRep
          ? action.payload
          : element;
      });
    case RESTART_SINTOMA:
      return INITIAL_STATE;
    default:
      return state;
  }
};

import { ADD_PUNTO } from "./types";

export default (state = [], action) => {
  switch (action.type) {
    case ADD_PUNTO:
      return action.payload;
    default:
      return state;
  }
};

import React, { useState } from "react";
import { Button } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBookOpen } from "@fortawesome/free-solid-svg-icons";

import ModalRelacion from "./ModalRelacion";

const Mapa = ({ cod }) => {
  const [show, setShow] = useState(false);

  return (
    <>
      <Button
        variant="default"
        data-toggle="tooltip"
        data-placement="top"
        title="Ver sintomas de homeopatia"
        onClick={() => {
          setShow(!show);
        }}
      >
        <FontAwesomeIcon icon={faBookOpen} />
      </Button>
      {show ? (
        <ModalRelacion cod={cod} show={show} onHide={() => setShow(false)} />
      ) : null}
    </>
  );
};

export default Mapa;

import React from "react";
import { Row, Col, Card } from "react-bootstrap";

import Formulario from "./Formulario";

const Signup = () => {
  const campos = [
    {
      label: "correo",
      type: "email",
      muted: "No compartiremos tu informacion con nadie",
    },
    {
      label: "clave",
      type: "password",
    },
    {
      label: "confirmar clave",
      type: "password",
    },
  ];

  const validateForm = (values) => {
    const err = {};
    const regexClave = new RegExp(
      /^^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[a-zA-Z]).{8,}$/
    );
    if (values.correo.length === 0) {
      err.correo = "El correo no puede estar vacio";
    }
    if (!regexClave.test(values.clave)) {
      err.clave =
        "La clave debe tener 8 caracteres una mayuscula una minuscula y un número";
    }
    if (values.clave.length === 0) {
      err.clave = "La contraseña no puede ser vacia";
    }
    if (values.clave !== values["confirmar clave"]) {
      err["confirmar clave"] = "La clave debe coincidir";
    }
    return err;
  };

  return (
    <Row className="justify-content-md-center">
      <Col xs="6">
        <Card>
          <Card.Body>
            <Card.Title>Registrate</Card.Title>
            <Formulario
              campos={campos}
              validateForm={validateForm}
              apiRoute="/api/signup"
              strength={true}
            />
          </Card.Body>
        </Card>
      </Col>
    </Row>
  );
};

export default Signup;

import _ from "lodash";
import { createSelector } from "reselect";

export const selectMedicamentos = (state) => state.medicamentos;

export const selectMedicamentosJerarquizados = createSelector(
  [(state) => state.holonmed.sintomasHomeopatia, (state) => state.medicamentos],
  (sintomasHomeopatia, medicamentos) => {
    const sintomasHomeopatiaJerarquizados = sintomasHomeopatia
      .filter((sintomaHomeopatia) => {
        return sintomaHomeopatia.jerarquizado;
      })
      .map((element) => element.codSintomaRep);
    return medicamentos.filter((medicamento) => {
      return (
        sintomasHomeopatiaJerarquizados.length ===
        _.intersection(medicamento.sintoma, sintomasHomeopatiaJerarquizados)
          .length
      );
    });
  }
);

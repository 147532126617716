import React, { useEffect, useState } from "react";

import MateriaMedica from "./MateriaMedica";
import Repertorio from "./Repertorio";
import Homeosiniatria from "./Homeosiniatria";
import Atlas from "./Atlas";
import Enfermedades from "./Enfermedades";

const DetalleLibro = (props) => {
  const [autor, setAutor] = useState(null);

  useEffect(() => {
    const nav = props.location.pathname.split("/");
    const libNumber = nav[nav.length - 1];

    switch (libNumber) {
      case "1":
        setAutor("Homeosiniatria");
        break;
      case "3":
        setAutor("Atlas");
        break;
      case "2":
        setAutor("Enfermedades");
        break;
      case "4":
        setAutor("Kent-repertorio");
        break;
      case "5":
        setAutor("Vinovsky");
        break;
      case "6":
        setAutor("Clarke");
        break;
      case "7":
        setAutor("Nash");
        break;
      case "8":
        setAutor("Kent");
        break;
      case "9":
        setAutor("Lathoud");
        break;
      case "10":
        setAutor("Farrington");
        break;
      case "11":
        setAutor("Paschero");
        break;
      default:
        setAutor(null);
        break;
    }
  }, [props]);

  switch (autor) {
    case "Kent-repertorio":
      return <Repertorio />;
    case "Homeosiniatria":
      return <Homeosiniatria />;
    case "Atlas":
      return <Atlas />;
    case "Enfermedades":
      return <Enfermedades />;
    case null:
      return null;
    default:
      return <MateriaMedica autor={autor} />;
  }
};

export default DetalleLibro;

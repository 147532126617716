import React from "react";
import { Row, Col, Container, Image } from "react-bootstrap";
import StyledLink from "./StyledLink";

import Logo from "../../assets/img/Logo.png";
import "../../css/navigation.css";

const Footer = () => {
  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  return (
    <footer id="footer">
      <Container className="footer-content">
        <Row>
          <Col xs={12}>
            Aurea medicina Holistica Software (v0.2.61){" "}
            <StyledLink to="/tutorial">Ayuda</StyledLink>
          </Col>
          <Col xs={12}>
            <div onClick={scrollToTop}>
              <StyledLink to="/">
                <Image src={Logo} alt="Logo" fluid />
              </StyledLink>
            </div>
          </Col>
        </Row>
      </Container>
    </footer>
  );
};

export default Footer;

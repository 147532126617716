import { useState, useEffect } from "react";
import axios from "axios";
import _ from "lodash";

import history from "../history";

const useAuth = () => {
  const [rol, setRol] = useState(null);
  const [suscripcion, setSuscripcion] = useState(null);
  const [isAuth, setIsAuth] = useState(null);

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async () => {
    const result = await axios.get("/api/current_user");
    if (!_.isEmpty(result.data)) {
      setIsAuth(true);
      setRol(result.data.rol);
      setSuscripcion(result.data.estado_suscripcion);
    } else {
      setIsAuth(false);
    }
  };

  const login = () => {
    axios
      .get(`/api/current_user`)
      .then((res) => {
        if (!_.isEmpty(res.data)) {
          setIsAuth(true);
          setRol(res.data.rol);
          setSuscripcion(res.data.estado_suscripcion);
          if (res.data.suscripcion != "no") {
            history.push("/");
          } else {
            history.push("/planes");
          }
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const logout = () => {
    axios
      .get(`/api/logout`)
      .then((res) => {
        setIsAuth(false);
        setRol(null);
        setSuscripcion(null);
        history.push("/");
      })
      .catch((error) => {
        console.log(error);
      });
  };

  return { login, logout, rol, isAuth, suscripcion };
};

export default useAuth;

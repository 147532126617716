import React from "react";

import ListaAcupuntura from "./acupuntura/ListaAcupuntura";
import ListaMedicamento from "./medicamento/ListaMedicamento";
import ListaSintomaAcupuntura from "./sintomaAcupuntura/ListaSintomaAcupuntura";
import ListaSintomaHomeopatia from "./sintomaHomeopatia/ListaSintomaHomeopatia";
import ListaSindrome from "./Sindrome/Lista";

const Lista = (props) => {
  const renderLista = () => {
    switch (props.match.params.handle) {
      case "Acupuntura":
        return <ListaAcupuntura />;
      case "Medicamento":
        return <ListaMedicamento />;
      case "Sintoma":
        return <ListaSintomaAcupuntura />;
      case "SintomaRep":
        return <ListaSintomaHomeopatia />;
      case "Sindrome":
        return <ListaSindrome />;
      default:
        return <ListaAcupuntura />;
    }
  };
  return <div className="container">{renderLista()}</div>;
};

export default Lista;

import axios from "axios";

export const puntosSugeridos = async (codigos) => {
  const { data } = await axios.get("/api/punto_sugerido/", {
    params: {
      codigos,
    },
  });
  return data;
};

import React, { useState } from "react";
import { Button } from "react-bootstrap";

import Formulario from "../Formulario";
import ModalRegistro from "./ModalRegistro";

const RegistrarPaciente = ({ setUpdate, update }) => {
  const [show, setShow] = useState(false);
  const campos = [
    {
      label: "Nombre *",
      id: "nombre",
      type: "text",
    },
    {
      label: "Apellido *",
      id: "apellido",
      type: "text",
    },
    {
      label: "Fecha de Nacimiento *",
      id: "nacimiento",
      type: "date",
    },
    {
      label: "Sexo *",
      id: "sexo",
      type: "select",
      placeholder: "Masculino",
      options: [
        { id: "Masculino", label: "Masculino" },
        { id: "Femenino", label: "Femenino" },
        { id: "Otro", label: "Otro" },
      ],
    },
    {
      label: "Tipo de Documento *",
      id: "tipoDoc",
      type: "select",
      placeholder: "CC",
      options: [
        { id: "CC", label: "CC" },
        { id: "TI", label: "TI" },
        { id: "Pasaporte", label: "Pasaporte" },
        { id: "Otro", label: "Otro" },
      ],
    },
    {
      label: "Número de Documento *",
      id: "documento",
      type: "text",
    },
    {
      label: "Nacionalidad",
      id: "nacionalidad",
      type: "select",
      placeholder: "Colombiano",
      options: [
        { id: "Colombiana", label: "Colombiana" },
        { id: "Argentina", label: "Argentina" },
        { id: "Boliviana", label: "Boliviana" },
        { id: "Chilena", label: "Chilena" },
        { id: "Costarricense", label: "Costarricense" },
        { id: "Cubana", label: "Cubana" },
        { id: "Dominicana", label: "Dominicana" },
        { id: "Ecuatoriana", label: "Ecuatoriana" },
        { id: "Estadounidense", label: "Estadounidense" },
        { id: "Guatemalteca", label: "Guatemalteca" },
        { id: "Hondureña", label: "Hondureña" },
        { id: "Mexicana", label: "Mexicana" },
        { id: "Nicaragüense", label: "Nicaragüense" },
        { id: "Panameña", label: "Panameña" },
        { id: "Paraguaya", label: "Paraguaya" },
        { id: "Puertorriqueña", label: "Puertorriqueña" },
        { id: "Peruana", label: "Peruana" },
        { id: "Salvadoreña", label: "Salvadoreña" },
        { id: "Uruguaya", label: "Uruguaya" },
        { id: "Venezolana", label: "Venezolana" },
        { id: "Otra", label: "Otra" },
      ],
    },
    {
      label: "Estado Civil",
      id: "estadoCivil",
      type: "select",
      placeholder: "Soltero/a",
      options: [
        { id: "Soltero/a", label: "Soltero/a" },
        { id: "Casado/a", label: "Casado/a" },
        { id: "Unión libre", label: "Unión libre" },
        { id: "Separado/a", label: "Separado/a" },
        { id: "Divorciado/a", label: "Divorciado/a" },
        { id: "Viudo/a", label: "Viudo/a" },
      ],
    },
    {
      label: "Ocupación",
      id: "ocupacion",
      type: "text",
    },
    {
      label: "Ciudad Residencia",
      id: "ciudad",
      type: "text",
    },
    {
      label: "Direccion",
      id: "direccion",
      type: "text",
    },
    {
      label: "Telefono Fijo",
      id: "tel",
      type: "tel",
    },
    {
      label: "Telefono Oficina",
      id: "telOficina",
      type: "tel",
    },
    {
      label: "Telefono celular",
      id: "cel",
      type: "tel",
    },
    {
      label: "Correo *",
      id: "correo",
      type: "email",
    },
    {
      label: "EPS",
      id: "eps",
      type: "text",
    },
    {
      label: "Creencias Religiosas",
      id: "religion",
      type: "text",
    },
  ];

  const validateForm = (values) => {
    const err = {};

    if (values.nombre.length === 0) {
      err.nombre = "Se requiere el nombre del paciente";
    }
    if (values.apellido.length === 0) {
      err.apellido = "Se requiere el apellido del paciente";
    }
    if (values.nacimiento.length === 0) {
      err.nacimiento = "Se requiere ingresar una fecha de nacimiento";
    }
    if (values.documento.length === 0) {
      err.documento =
        "Se requiere ingresar el número de identificación del paciente";
    }
    if (values.correo.length === 0) {
      err.correo = "Se requiere ingresar un correo";
    }
    return err;
  };

  return (
    <React.Fragment>
      <Button onClick={() => setShow(!show)}>Agregar Paciente</Button>

      <ModalRegistro
        title="Registrar Nuevo Paciente"
        show={show}
        onHide={() => {
          setShow(false);
          setUpdate(!update);
        }}
      >
        <Formulario
          campos={campos}
          validateForm={validateForm}
          apiRoute="/api/paciente"
          enviar="Agregar Paciente"
        />
      </ModalRegistro>
    </React.Fragment>
  );
};

export default RegistrarPaciente;

import React, { useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEdit, faTimesCircle } from "@fortawesome/free-solid-svg-icons";
import { Button } from "react-bootstrap";

import Formulario from "../../Formulario";

const Campo = ({
  titulo,
  detail,
  editable,
  campos,
  validateForm,
  apiRoute,
}) => {
  const [editar, setEditar] = useState(false);

  const renderFA = (icon) => {
    return (
      <Button
        onClick={() => {
          setEditar(!editar);
        }}
        variant="primary-outline"
      >
        <FontAwesomeIcon icon={icon} />
      </Button>
    );
  };

  return (
    <dl>
      {editable ? (
        <dt>
          {titulo}
          {renderFA(editar ? faTimesCircle : faEdit)}
        </dt>
      ) : (
        <dt>{titulo}</dt>
      )}
      {editar ? (
        <Formulario
          campos={campos}
          validateForm={validateForm}
          apiRoute={apiRoute}
          enviar="confirmar cambio"
          patch={true}
        />
      ) : (
        <dd>{detail}</dd>
      )}
    </dl>
  );
};

export default Campo;

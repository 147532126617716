import React from "react";
import { Container } from "react-bootstrap";

import StyledLink from "./StyledLink";

const notFoundPage = () => {
  return (
    <Container>
      <h1> 404 Page Not Found</h1>
      <p> Lo sentimos la pagina a la que quiere acceder no existe.</p>
      <p>
        <StyledLink to="/">Volver a inicio</StyledLink>
      </p>
    </Container>
  );
};

export default notFoundPage;

import React from "react";

import StyledLink from "../general/StyledLink";

const TablaSindromes = ({ data }) => {
  const renderBody = () => {
    return data.map((item) => {
      return (
        <tr key={item.documento}>
          <td>
            <StyledLink to={`/pacientes/${item._id}`}>{item.nombre}</StyledLink>
          </td>
          <td>{item.apellido}</td>
          <td>{item.tipoDoc}</td>
          <td>{item.documento}</td>
          <td>{item.edad}</td>
        </tr>
      );
    });
  };

  if (data) {
    if (data.length > 0) {
      return (
        <table className="table table-striped">
          <thead>
            <tr>
              <th scope="col">Nombre</th>
              <th scope="col">Apellido</th>
              <th scope="col">Tipo Documento</th>
              <th scope="col">Número Documento</th>
              <th scope="col">Edad</th>
            </tr>
          </thead>
          <tbody>{renderBody()}</tbody>
        </table>
      );
    }
    return (
      <table className="table table-striped">
        <thead>
          <tr>
            <th scope="col">Nombre</th>
            <th scope="col">Apellido</th>
            <th scope="col">Tipo Documento</th>
            <th scope="col">Número Documento</th>
            <th scope="col">Edad</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>No hay pacientes registrados</td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
          </tr>
        </tbody>
      </table>
    );
  }
  return <p>Cargando</p>;
};

export default TablaSindromes;

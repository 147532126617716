import {
  ADD_SINTOMA_ACUPUNTURA,
  DELETE_SINTOMA_ACUPUNTURA,
  RESTART_SINTOMA_ACUPUNTURA,
  UPDATE_SINTOMA_ACUPUNTURA,
} from "./types";

const INITIAL_STATE = [];
export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case ADD_SINTOMA_ACUPUNTURA:
      return [...state, action.payload];
    case DELETE_SINTOMA_ACUPUNTURA:
      return state.filter((element) => element.codSintoma !== action.payload);
    case UPDATE_SINTOMA_ACUPUNTURA:
      return state.map((element) => {
        return element.codSintoma === action.payload.codSintoma
          ? action.payload
          : element;
      });
    case RESTART_SINTOMA_ACUPUNTURA:
      return INITIAL_STATE;
    default:
      return state;
  }
};

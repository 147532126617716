import React, { useState } from "react";
import { Modal, Button } from "react-bootstrap";

const ModalConfirmar = ({ borrar, cod, nombre }) => {
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const handleBorrar = () => {
    borrar(cod);
    handleClose();
  };
  return (
    <>
      <Button variant="danger" onClick={handleShow} className="float-right">
        Borrar
      </Button>
      {show ? (
        <Modal show={show} onHide={handleClose}>
          <Modal.Header closeButton>
            <Modal.Title>Confirmacion Borrado</Modal.Title>
          </Modal.Header>
          <Modal.Body> Seguro que desea borrar {nombre} ?</Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={handleClose}>
              Cerrar
            </Button>
            <Button variant="danger" onClick={handleBorrar}>
              Confirmar
            </Button>
          </Modal.Footer>
        </Modal>
      ) : null}
    </>
  );
};

export default ModalConfirmar;

import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { Row, Col, Button } from "react-bootstrap";

import "../../css/Integracion.css";
import { reiniciarSintomaHomeopatia } from "../../state/sintoma/actions";
import { reiniciarSintomaAcupuntura } from "../../state/sintomaAcupuntura/actions";

import Medicamentos from "./Homeopatia/Medicamentos";
import Acupuntura from "./Acupuntura/Acupuntura";
import Sindromes from "./Sindromes/Sindromes";
import ModalPacientes from "./Repertorizacion/ModalPacientes";
import SintomasAcupuntura from "./SintomasAcupuntura";
import SintomasHomeopatia from "./SintomasHomeopatia";

const Integracion = () => {
  const [modalShow, setModalShow] = useState(false);
  const dispatch = useDispatch();

  const restart = () => {
    dispatch(reiniciarSintomaAcupuntura());
    dispatch(reiniciarSintomaHomeopatia());
  };

  return (
    <div id="watermark">
      <div className="my-container">
        <Row>
          <ModalPacientes show={modalShow} onHide={() => setModalShow(false)} />
          <Col xs={12}>
            <Row>
              <Col>
                <Button variant="primary" onClick={() => setModalShow(true)}>
                  Guardar Repertorización
                </Button>
              </Col>
              <Col>
                <Button variant="danger" onClick={restart}>
                  Borrar Repertorización
                </Button>
              </Col>
              <Col>
                <Button variant="warning" onClick={window.print}>
                  Imprimir Repertorización
                </Button>
              </Col>
            </Row>
          </Col>
          <Col xs={12} lg={6}>
            <SintomasHomeopatia />
          </Col>
          <Col xs={12} lg={6}>
            <SintomasAcupuntura />
          </Col>
          <Col xs={12}>
            <Medicamentos />
          </Col>
          <Col xs={12} lg={6}>
            <Acupuntura />
          </Col>
          <Col xs={12} lg={6}>
            <Sindromes />
          </Col>
        </Row>
      </div>
    </div>
  );
};

export default Integracion;

import React, { useState, useEffect } from "react";
import axios from "axios";
import { Table, Row, Col } from "react-bootstrap";
import ReactPaginate from "react-paginate";

import StyledLink from "../../general/StyledLink";
import SearchBar from "../../general/SearchBar";

const ListaSintomaHomeopatia = () => {
  const [data, setData] = useState(null);
  const [size, setSize] = useState(null);
  const [capitulos, setCapitulos] = useState([]);
  const [pageNumber, setPageNumber] = useState(0);
  const dataPerPage = 500;

  const fetchData = async () => {
    const resSize = await axios.get("/api/size/sintomaRep");
    setSize(resSize.data);

    const response = await axios.get(`/api/sintomaRep/page/${pageNumber}`);
    setData(response.data);

    const chaps = await axios.get("/api/libro/3/capitulo");
    setCapitulos(chaps.data);
  };

  useEffect(() => {
    fetchData();
  }, [pageNumber]); // eslint-disable-line react-hooks/exhaustive-deps

  const changePage = ({ selected }) => {
    setPageNumber(selected);
  };

  const getNombreCapitulo = (id) => {
    const capitulo = capitulos.find((element) => element.codCapitulo === id);
    return capitulo.titulo;
  };

  const renderData = () => {
    if (data) {
      return (
        <>
          {data.map((item) => {
            return (
              <tr key={item.codSintomaRep}>
                <td>
                  <StyledLink to={`/admin/SintomaRep/${item.codSintomaRep}`}>
                    {item.codSintomaRep}
                  </StyledLink>
                </td>
                <td>
                  {item.capitulo
                    ? item.capitulo
                    : getNombreCapitulo(item.codCapitulo)}
                </td>
                <td>{item.nombre}</td>
              </tr>
            );
          })}
        </>
      );
    }
    return null;
  };

  const renderPag = () => {
    if (data && size) {
      const pageCount = Math.ceil(size.size / dataPerPage);
      return (
        <ReactPaginate
          previousLabel="Anterior"
          nextLabel="Siguiente"
          pageCount={pageCount}
          onPageChange={changePage}
          containerClassName="paginationBttns"
        />
      );
    }
    return null;
  };

  const filterFunction = async (value) => {
    if (value.length > 5) {
      const response = await axios.get(`/api/sintomaRep/nombre/${value}`);
      setData(response.data);
      setPageNumber(0);
      setSize({ size: 400 });
    } else {
      const response = await axios.get(`/api/sintomaRep/page/${pageNumber}`);
      setData(response.data);
      setPageNumber(0);

      const resSize = await axios.get("/api/size/sintomaRep");
      setSize(resSize.data);
    }
  };

  return (
    <Row>
      <Col xs={12}>
        <StyledLink to="/admin">Tablas</StyledLink>
      </Col>
      <Col xs={12}>
        <h1>Listado Sintomas Homeopatia</h1>
        <SearchBar title="Buscar Sintoma" filterFunction={filterFunction} />
        <br />
        <Table striped bordered hover>
          <thead>
            <tr>
              <th>Código Sintoma</th>
              <th>Capitulo</th>
              <th>Nombre Sintoma</th>
            </tr>
          </thead>
          <tbody>{renderData()}</tbody>
        </Table>
      </Col>
      <Col xs={12}>{renderPag()}</Col>
    </Row>
  );
};

export default ListaSintomaHomeopatia;

import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Row, Col } from 'react-bootstrap';

import Campo from '../detalle/Campo';
import RelacionTratamiento from './RelacionTratamiento';
import RelacionMedicamento from './RelacionMedicamento';
import RelacionSintoma from './RelacionSintoma';
import SindromePadre from './SindromePadre';
import StyledLink from '../../general/StyledLink';

const Detalle = ({ id }) => {
  const [detalle, setDetalle] = useState(null);

  const fetchData = async () => {
    const { data } = await axios.get(`/api/sindrome/${id}`);
    setDetalle(data);
  };

  useEffect(() => {
    fetchData();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const validateForm = () => {
    return {};
  };

  if (detalle) {
    const camposTexto = [
      {
        id: 'texto',
        type: 'textarea',
        placeholder: detalle.texto,
      },
    ];

    const camposNombre = [
      {
        id: 'nombre',
        type: 'text',
        placeholder: detalle.nombre,
      },
    ];

    const camposObservaciones = [
      {
        id: 'observaciones',
        type: 'textarea',
        placeholder: detalle.observaciones,
      },
    ];

    return (
      <Row>
        <Col sm={12}>
          <StyledLink to="/admin/Sindrome">Listado Sindromes</StyledLink>
          <h1>Sindrome: {id}</h1>
          <br />
        </Col>
        <Col sm={12} md={6}>
          <SindromePadre id={id} />
        </Col>
        <Col sm={12} md={6}>
          <Campo
            titulo={'nombre Síndrome'}
            detail={detalle.nombre}
            editable={true}
            campos={camposNombre}
            validateForm={validateForm}
            apiRoute={`/api/Sindrome/${id}`}
          />
        </Col>
        <Col sm={12} md={6}>
          <Campo
            titulo={'Resumen del Síndrome'}
            detail={detalle.texto}
            campos={camposTexto}
            validateForm={validateForm}
            editable={true}
            apiRoute={`/api/Sindrome/${id}`}
          />
        </Col>
        <Col sm={12} md={6}>
          <Campo
            titulo={'Observaciones del Síndrome'}
            detail={detalle.observaciones}
            campos={camposObservaciones}
            validateForm={validateForm}
            editable={true}
            apiRoute={`/api/Sindrome/${id}`}
          />
        </Col>
        <Col sm={{ span: 11, offset: 1 }}>
          <RelacionTratamiento id={id} tratamiento={detalle.tratamiento} />
          <hr />
        </Col>

        <Col sm={{ span: 11, offset: 1 }}>
          <RelacionMedicamento id={id} relacion={detalle.medicamentos} />
          <hr />
        </Col>
        <Col sm={{ span: 11, offset: 1 }}>
          <RelacionSintoma id={id} relacion={detalle.sintomas} />
        </Col>
      </Row>
    );
  }
  return <div>Cargando</div>;
};

export default Detalle;

import React from "react";

import StyledLink from "./general/StyledLink";
import "../css/card.css";

const HorizontalCard = ({ titulo, parrafo, imagen, idLibro, autor }) => {
  return (
    <div className="card">
      <div className="row ">
        <div className="col-sm-5 col-md-3">
          <StyledLink to={`/libro/${idLibro}`}>
            <img className="d-block w-100" src={imagen} alt="" />
          </StyledLink>
        </div>
        <div className="col-sm-7 col-md-9 px-3">
          <div className="card-block px-6">
            <h4 className="card-title">
              <StyledLink to={`/libro/${idLibro}`}>{titulo}</StyledLink>
            </h4>
            <p className="text-muted">{autor}</p>
            <p className="card-text">{parrafo}</p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default HorizontalCard;

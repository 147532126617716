import React, { useState } from "react";
import { Button, Card } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPills } from "@fortawesome/free-solid-svg-icons";

import ModalSintomas from "./ModalSintomas";
import Tabla from "./Tabla";

const SintomasHomeopatia = () => {
  const [modalShow, setModalShow] = useState(false);

  return (
    <Card>
      <Card.Header as="h3">
        Sintomas Homeopatia <FontAwesomeIcon icon={faPills} />
      </Card.Header>
      <Card.Body>
        <Button
          className="mb-3"
          variant="primary"
          onClick={() => setModalShow(true)}
        >
          Ver listado de sintomas de Homeopatia
        </Button>
        <Tabla />
      </Card.Body>
      {modalShow && (
        <ModalSintomas show={modalShow} onHide={() => setModalShow(false)} />
      )}
    </Card>
  );
};

export default SintomasHomeopatia;

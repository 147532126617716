// src/MyAuthComponent.js
import React, { useEffect, useState } from "react";
import { Container, Button, Col, Row, Image } from "react-bootstrap";
import axios from "axios";

import StyledLink from "./StyledLink";

const GoogleProfile = () => {
  const [profile, setProfile] = useState(null);
  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async () => {
    const result = await axios.get("/api/current_user");
    setProfile(result.data);
  };

  const cancelSub = async () => {
    await axios.get("/api/cancelsub");
  };

  if (profile) {
    const timestamp = profile._id.toString().substring(0, 8);
    const date = new Date(parseInt(timestamp, 16) * 1000);
    return (
      <Container>
        <Row>
          <Col>
            <h1>Tu perfil</h1>
          </Col>
        </Row>
        <Row style={{ margin: "50px 0px" }}>
          <Col xs={6} md={4}>
            <Image
              src={profile.img}
              alt="profile picture"
              fluid
              style={{ height: "100%" }}
            />
          </Col>
          <Col>
            <Row>
              <Col xs={12}>
                <dl>
                  <dt>Nombre:</dt>
                  <dd>{profile.nombre}</dd>
                </dl>
              </Col>
              <Col xs={12}>
                <dl>
                  <dt>Correo:</dt>
                  <dd>{profile.correo}</dd>
                </dl>
              </Col>
              <Col xs={12}>
                <dl>
                  <dt>Activo desde:</dt>
                  <dd>{date.toString()}</dd>
                </dl>
              </Col>
            </Row>
          </Col>
        </Row>
        <Row style={{ margin: "10px 0px" }}>
          <Col xs={12}>
            <h2>Tu Suscripción</h2>
            <p>
              El estado de tu suscripción en el momento es:{" "}
              {profile.estado_suscripcion}
            </p>
          </Col>
          {profile.estado_suscripcion === "ACTIVE" ||
          profile.estado_suscripcion == "PAID" ||
          profile.estado_suscripcion === "PENDING" ? (
            <StyledLink to="/login">
              <Button variant={"danger"} onClick={cancelSub}>
                Cancelar suscripción
              </Button>
            </StyledLink>
          ) : (
            <StyledLink to="/planes">
              <Button variant={"success"}>Suscribirme</Button>
            </StyledLink>
          )}
        </Row>
      </Container>
    );
  }
  return null;
};

export default GoogleProfile;

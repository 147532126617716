import React, { useState, useEffect } from "react";
import axios from "axios";
import { useDispatch, useSelector } from "react-redux";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faMinusCircle } from "@fortawesome/free-solid-svg-icons";
import { Table } from "react-bootstrap";

import { borrarSintomaHomeopatia } from "../../../../state/sintoma/actions";
import { selectSintomasHomeopatia } from "../../../../state/sintoma/selectors";

import Mapa from "./Mapa";
import Jerarquico from "./Jerarquico";

const Tabla = () => {
  const [capitulos, setCapitulos] = useState(null);
  const sintomasHomeopatia = useSelector(selectSintomasHomeopatia);
  const dispatch = useDispatch();

  useEffect(() => {
    const fetchData = async () => {
      const response = await axios.get("/api/libro/3/capitulo");
      setCapitulos(response.data);
    };
    fetchData();
  }, []);

  if (!capitulos) {
    return <></>;
  }

  const getNombreCapitulo = (id) => {
    const capitulo = capitulos.find((element) => element.codCapitulo === id);
    return capitulo.titulo;
  };

  const renderBody = () => {
    if (sintomasHomeopatia.length > 0) {
      return sintomasHomeopatia.map((item) => {
        return (
          <tr key={(item.codCapitulo, item.codSintomaRep)}>
            <td>{getNombreCapitulo(item.codCapitulo)}</td>
            <td>{item.nombre}</td>
            <td>
              <div className="d-flex justify-content-between">
                <Jerarquico item={item} />
                <Mapa cod={item.codSintomaRep} />
                <button
                  className="btn btn-default"
                  data-toggle="tooltip"
                  data-placement="top"
                  title="Borrar"
                  onClick={() => {
                    dispatch(borrarSintomaHomeopatia(item.codSintomaRep));
                  }}
                >
                  <FontAwesomeIcon icon={faMinusCircle} color="red" />
                </button>
              </div>
            </td>
          </tr>
        );
      });
    }
    return (
      <tr>
        <td>Agrega Sintomas para comenzar con el proceso de repertorización</td>
        <td></td>
        <td></td>
      </tr>
    );
  };

  return (
    <Table striped>
      <thead>
        <tr>
          <th scope="col">Capitulo</th>
          <th scope="col">Nombre</th>
          <th scope="col">opciones</th>
        </tr>
      </thead>
      <tbody>{renderBody()}</tbody>
    </Table>
  );
};

export default Tabla;

import React, { useEffect, useState } from "react";
import { Modal, Row, Col, ListGroup } from "react-bootstrap";
import axios from "axios";
import { useSelector } from "react-redux";
import { selectCodigosSintomasHomeopatia } from "../../../state/sintoma/selectors";
import { selectCodigosSintomasAcupuntura } from "../../../state/sintomaAcupuntura/selectors";

const ModalPacientes = (props) => {
  const [pacientes, setPacientes] = useState(null);
  const codigosSintomasHomeopatia = useSelector(
    selectCodigosSintomasHomeopatia
  );
  const codigosSintomasAcupuntura = useSelector(
    selectCodigosSintomasAcupuntura
  );

  const fetchData = async () => {
    const response = await axios.get("/api/paciente");
    setPacientes(response.data);
  };

  useEffect(() => {
    fetchData();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const guardar = async (id) => {
    const rep = {
      sintoAcu: codigosSintomasAcupuntura,
      sintoHomeo: codigosSintomasHomeopatia,
    };

    await axios.post(`/api/paciente/${id}/repertorizacion`, rep);
    alert("Repertorización Agregada al paciente");
  };

  const renderPacientes = () => {
    if (pacientes) {
      return pacientes.map((paciente) => {
        return (
          <ListGroup.Item
            key={paciente._id}
            onClick={() => guardar(paciente._id)}
            style={{ cursor: "pointer" }}
          >
            {paciente.nombre} {paciente.apellido}
          </ListGroup.Item>
        );
      });
    }
    return null;
  };
  return (
    <Modal {...props} aria-labelledby="contained-modal-title-vcenter" centered>
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
          Guardar repertorizacion
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Row>
          <Col>
            <h3>Selecciona el paciente:</h3>

            <div className="overflow-auto" style={{ maxHeight: "300px" }}>
              <ListGroup>{renderPacientes()}</ListGroup>
            </div>
          </Col>
        </Row>
      </Modal.Body>
    </Modal>
  );
};

export default ModalPacientes;

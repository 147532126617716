import React, { useState } from 'react';
import { Row, Col, ListGroup } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlusSquare, faTimes } from '@fortawesome/free-solid-svg-icons';

export const DropdownHomeosiniatria = ({ data, handleChangeCapitulo }) => {
  const [show, setShow] = useState(false);

  const { subcapitulos, capitulo } = data;

  const renderCapitulos = () => {
    if (show) {
      return subcapitulos.map(({ nombre }) => {
        if (nombre) {
          return (
            <ListGroup.Item
              key={nombre}
              className="capitulo"
              style={{ marginLeft: '10px' }}
              onClick={() => {
                handleChangeCapitulo(data);
              }}
            >
              <a style={{ color: 'black' }} href={`#${nombre}`}>
                {nombre}
              </a>
            </ListGroup.Item>
          );
        }
        return null;
      });
    }
    return null;
  };

  return (
    <React.Fragment>
      <ListGroup.Item className="capitulo">
        <Row>
          <Col
            xs="10"
            onClick={() => {
              handleChangeCapitulo(data);
            }}
          >
            <a href={`#${capitulo}`}>{capitulo}</a>
          </Col>
          <Col>
            <FontAwesomeIcon
              onClick={() => setShow(!show)}
              icon={show ? faTimes : faPlusSquare}
            />
          </Col>
        </Row>
      </ListGroup.Item>
      {renderCapitulos()}
    </React.Fragment>
  );
};

export const DropdownEnfermedades = ({ capitulo, padding }) => {
  const [show, setShow] = useState(false);

  const { nombre, codSindrome, hijos } = capitulo;

  return (
    <React.Fragment>
      <ListGroup.Item
        className="capitulo"
        style={{ paddingLeft: `${padding}px` }}
      >
        <Row>
          <Col xs="10">
            <a href={`#${codSindrome}`}>{nombre}</a>
          </Col>

          <Col>
            {hijos.length > 0 ? (
              <FontAwesomeIcon
                onClick={() => setShow(!show)}
                icon={show ? faTimes : faPlusSquare}
              />
            ) : null}
          </Col>
        </Row>
      </ListGroup.Item>
      {show
        ? hijos.map((capitulo) => {
            return (
              <DropdownEnfermedades
                capitulo={capitulo}
                padding={padding + 20}
                key={capitulo.codSindrome}
              />
            );
          })
        : null}
    </React.Fragment>
  );
};

const DropdownAcupuntura = ({ nombre, capitulos, padre }) => {
  const [show, setShow] = useState(false);

  const renderPuntos = () => {
    if (show) {
      return capitulos.map(({ punto, codAcupuntura }) => {
        return (
          <React.Fragment key={codAcupuntura}>
            <ListGroup.Item className="capitulo" style={{ marginLeft: '20px' }}>
              <a style={{ color: 'purple' }} href={`#${codAcupuntura}`}>
                {punto}
              </a>
            </ListGroup.Item>
          </React.Fragment>
        );
      });
    }
    return null;
  };
  return (
    <React.Fragment>
      <ListGroup.Item className="capitulo" style={{ marginLeft: '10px' }}>
        <Row>
          <Col xs="10">
            <a style={{ color: 'black' }} href={`#${padre}-${nombre}`}>
              {nombre}
            </a>
          </Col>
          <Col>
            <FontAwesomeIcon
              onClick={() => setShow(!show)}
              icon={show ? faTimes : faPlusSquare}
            />
          </Col>
        </Row>
      </ListGroup.Item>
      {renderPuntos()}
    </React.Fragment>
  );
};

const Dropdown = ({ nombre, capitulos }) => {
  const [show, setShow] = useState(false);

  const renderCapitulos = () => {
    if (show) {
      return capitulos.map(({ titulo, puntos }) => {
        return (
          <React.Fragment key={titulo}>
            {puntos.length > 0 ? (
              <DropdownAcupuntura
                nombre={titulo}
                padre={nombre}
                capitulos={puntos}
              />
            ) : titulo ? (
              <ListGroup.Item
                className="capitulo"
                style={{ marginLeft: '10px' }}
              >
                <a style={{ color: 'black' }} href={`#${nombre}-${titulo}`}>
                  {titulo}
                </a>
              </ListGroup.Item>
            ) : null}
          </React.Fragment>
        );
      });
    }
    return null;
  };

  return (
    <React.Fragment>
      <ListGroup.Item className="capitulo">
        <Row>
          <Col xs="10">
            <a href={`#${nombre}`}>{nombre}</a>
          </Col>
          <Col>
            <FontAwesomeIcon
              onClick={() => setShow(!show)}
              icon={show ? faTimes : faPlusSquare}
            />
          </Col>
        </Row>
      </ListGroup.Item>
      {renderCapitulos()}
    </React.Fragment>
  );
};

export default Dropdown;

import React, { useEffect, useState } from "react";
import { Table, Button } from "react-bootstrap";

import StyledLink from "../../general/StyledLink";
import { useDispatch, useSelector } from "react-redux";
import { selectSintomasHomeopatia } from "../../../state/sintoma/selectors";
import { selectMedicamentosJerarquizados } from "../../../state/medicamento/selectors";
import { medicamentosSugeridos } from "../../../services/medicamentos";
import { agregarMedicamentos } from "../../../state/medicamento/actions";

const TablaMed = () => {
  const [visualizacion, setVisualizacion] = useState(false);
  const sintomasHomeopatia = useSelector(selectSintomasHomeopatia);
  const medicamentos = useSelector(selectMedicamentosJerarquizados);
  const dispatch = useDispatch();

  useEffect(() => {
    const cargarMedicamentos = async () => {
      const codigosSintomasHomeopatia = sintomasHomeopatia.map(
        (element) => element.codSintomaRep
      );
      const med = await medicamentosSugeridos(codigosSintomasHomeopatia);
      if (med !== undefined) {
        dispatch(agregarMedicamentos(med));
      }
    };
    cargarMedicamentos();
  }, [dispatch, sintomasHomeopatia]);

  const mapToColor = (valor, index) => {
    switch (valor) {
      case 1:
        return (
          <span key={index} className="dot dot-black">
            1
          </span>
        );
      case 2:
        return (
          <span key={index} className="dot dot-blue">
            2
          </span>
        );
      case 3:
        return (
          <span key={index} className="dot dot-red">
            3
          </span>
        );
      default:
        return <span key={index} className="dot dot-neutro" />;
    }
  };

  const renderMedicamentos = () => {
    return (
      <tr>
        <td />
        <td />
        {medicamentos.map((item) => {
          return (
            <th style={{ minWidth: "100px" }} key={item.codMedicamento[0]}>
              <StyledLink to={`/medicamento/${item.codMedicamento[0]}`}>
                {item.medicamento}
              </StyledLink>
            </th>
          );
        })}
      </tr>
    );
  };

  const renderPuntaje = () => {
    return (
      <tr>
        <td />
        <th>Puntaje</th>
        {medicamentos.map((item) => {
          return <td key={item.codMedicamento[0]}>{item.total}</td>;
        })}
      </tr>
    );
  };

  const renderCoincidencias = () => {
    return (
      <tr>
        <td />
        <th>Coincidencias</th>
        {medicamentos.map((item) => {
          return <td key={item.codMedicamento[0]}>{item.valor.length}</td>;
        })}
      </tr>
    );
  };

  const renderPuntajeMed = (codS, item) => {
    if (item.sintoma.includes(codS)) {
      const index = item.sintoma.findIndex((sinto) => codS === sinto);

      return visualizacion
        ? item.valor[index]
        : mapToColor(item.valor[index], index);
    }
    return null;
  };

  const renderSintomas = () => {
    return sintomasHomeopatia.map((element) => {
      return (
        <tr key={element.codSintomaRep}>
          <th>{element.nombre}</th>
          <td />
          {medicamentos.map((item) => {
            return (
              <td key={item.codMedicamento[0]}>
                {renderPuntajeMed(element.codSintomaRep, item)}
              </td>
            );
          })}
        </tr>
      );
    });
  };

  if (medicamentos.length === 0) {
    return <p>No hay datos</p>;
  }

  return (
    <React.Fragment>
      <Button
        variant="primary"
        onClick={() => setVisualizacion(!visualizacion)}
        className="mb-3"
      >
        cambiar visualizacion
      </Button>
      <div className="scrollableTable">
        <Table striped bordered>
          <thead>{renderMedicamentos()}</thead>
          <tbody>
            {renderPuntaje()}
            {renderCoincidencias()}
            {renderSintomas()}
          </tbody>
        </Table>
      </div>
    </React.Fragment>
  );
};

export default TablaMed;

import React from "react";

import StyledLink from "../general/StyledLink";

const Admin = () => {
  return (
    <div className="container">
      <h1>Tablas Aureo</h1>
      <ul>
        <li>
          <StyledLink to="/admin/Acupuntura">Puntos de Acupuntura</StyledLink>
        </li>
        <li>
          <StyledLink to="/admin/Medicamento">Medicamentos</StyledLink>
        </li>
        <li>
          <StyledLink to="/admin/Sintoma">Sintomas Acupuntura</StyledLink>
        </li>
        <li>
          <StyledLink to="/admin/SintomaRep">Sintomas Repertorio</StyledLink>
        </li>
        <li>
          <StyledLink to="/admin/Sindrome">Sindromes</StyledLink>
        </li>
      </ul>
    </div>
  );
};

export default Admin;

import React, { useState, useEffect } from "react";
import { Form, Button, Row, Col, Toast } from "react-bootstrap";
import PasswordStrengthBar from "react-password-strength-bar";
import _ from "lodash";
import axios from "axios";

import useAuth from "../customHooks/useAuth";

const Formulario = ({
  campos,
  validateForm,
  apiRoute,
  strength,
  enviar,
  patch,
}) => {
  const [values, setValues] = useState({});
  const [errors, setErrors] = useState({});
  const [showA, setShowA] = useState(false);
  const [serverErr, setServerErr] = useState(null);
  const [errMsg, setErrMsg] = useState("");

  const { login } = useAuth();

  useEffect(() => {
    const obj = {};

    for (const key of campos) {
      obj[key.id] = "";
      if (key.placeholder) {
        obj[key.id] = key.placeholder;
      }
    }
    setValues(obj);
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const handleChange = (evt) => {
    setValues({
      ...values,
      [evt.target.name]: evt.target.value,
    });
  };

  const handleSubmit = async (evt) => {
    evt.preventDefault();
    let err = validateForm(values);
    setErrors(err);
    if (_.isEmpty(err)) {
      try {
        let res;
        if (patch) {
          res = await axios.patch(apiRoute, values);
        } else {
          res = await axios.post(apiRoute, values);
        }
        if (apiRoute.includes("login")) {
          login();
        } else {
          setServerErr(null);
          setErrMsg(res.data.msg);
          setShowA(true);
        }
      } catch (err) {
        setServerErr(err.message);
        setErrMsg(err.response.data.msg);
        setShowA(true);
      }
    }
  };

  const toggleShowA = () => setShowA(!showA);

  const toast = () => {
    return (
      <Row>
        <Col xs={6}>
          <Toast show={showA} onClose={toggleShowA}>
            <Toast.Header>
              <img
                src="holder.js/20x20?text=%20"
                className="rounded mr-2"
                alt=""
              />
              <strong className="mr-auto">
                {serverErr ? "Error" : "Éxito"}
              </strong>
              <small>{serverErr}</small>
            </Toast.Header>
            <Toast.Body>{errMsg}</Toast.Body>
          </Toast>
        </Col>
      </Row>
    );
  };

  const renderMuted = (campo) => {
    if (campo.muted) {
      return <Form.Text className="text-muted">{campo.muted}</Form.Text>;
    }
  };

  const control = (c) => {
    switch (c.type) {
      case "select":
        return (
          <Form.Control
            as="select"
            id={c.id}
            name={c.id}
            className={errors[c.id] ? "form-error" : ""}
            onChange={handleChange}
            value={values[c.id]}
          >
            {c.options.map((o) => {
              return (
                <option key={o.id} value={o.id}>
                  {o.label}
                </option>
              );
            })}
          </Form.Control>
        );
      case "textarea":
        return (
          <Form.Control
            as="textarea"
            id={c.id}
            name={c.id}
            className={errors[c.id] ? "form-error" : ""}
            onChange={handleChange}
            rows={5}
            value={values[c.id]}
          />
        );
      case "readOnly":
        return <Form.Control readOnly defaultValue={c.placeholder} />;
      default:
        return (
          <Form.Control
            type={c.type}
            id={c.id}
            name={c.id}
            className={errors[c.id] ? "form-error" : ""}
            onChange={handleChange}
            value={values[c.id]}
          />
        );
    }
  };

  const renderFields = () => {
    return campos.map((campo) => {
      return (
        <Form.Group key={campo.id}>
          <Form.Label htmlFor={campo.id}>{campo.label}</Form.Label>
          {control(campo)}
          {renderMuted(campo)}
          {errors[campo.id] && (
            <p className="error-small">{errors[campo.id]}</p>
          )}
          {campo.id === "clave" && strength && (
            <PasswordStrengthBar password={values[campo.id]} />
          )}
        </Form.Group>
      );
    });
  };
  return (
    <Form onSubmit={handleSubmit}>
      {renderFields()}
      <Button variant="primary" type="submit">
        {enviar || "Enviar"}
      </Button>
      {toast()}
    </Form>
  );
};

export default Formulario;

import React, { useState, useEffect } from "react";
import axios from "axios";
import { Row, Col } from "react-bootstrap";

import SearchBar from "../general/SearchBar";

const MateriaMedica = ({ autor }) => {
  const [items, setItems] = useState([]);
  const [itemsFiltered, setItemsFiltered] = useState([]);
  const [medicamento, setMedicamento] = useState(null);

  const fetchMedicamentos = async () => {
    const response = await axios.get(
      `/api/autor/${autor}/medicamentos/nombres`
    );
    setItems(response.data);
    setItemsFiltered(response.data);
  };

  const fetchMed = async (id) => {
    const response = await axios.get(`/api/medicamento/${id}`);
    setMedicamento(response.data);
  };

  useEffect(() => {
    fetchMedicamentos();
    fetchMed(1);
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const renderListaMedicamentos = () => {
    if (itemsFiltered && medicamento) {
      const filterFunction = (value) => {
        if (value.length > 0) {
          setItemsFiltered(
            items.filter((item) =>
              item.medicamento.toLowerCase().includes(`${value}`.toLowerCase())
            )
          );
        } else {
          setItemsFiltered(items);
        }
      };

      return (
        <React.Fragment>
          <SearchBar
            title="Buscar Medicamento"
            filterFunction={filterFunction}
          />
          <div className="scroll-menu-medicamento">
            {itemsFiltered.map((i) => {
              return (
                <p
                  style={{ cursor: "pointer" }}
                  key={i.codMedicamento}
                  onClick={() => {
                    fetchMed(i.codMedicamento);
                  }}
                  className={
                    medicamento.codMedicamento === i.codMedicamento
                      ? "active"
                      : null
                  }
                >
                  {i.medicamento}
                </p>
              );
            })}
          </div>
        </React.Fragment>
      );
    }
    return null;
  };

  const renderCapitulos = () => {
    if (medicamento) {
      return (
        <nav className="navbar navbar-expand-lg nav-menu navbar-light">
          <button
            className="navbar-toggler"
            type="button"
            data-toggle="collapse"
            data-target="#navbarMed"
            aria-controls="#navbarMed"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            <span className="navbar-toggler-icon" />
          </button>
          <div className="collapse navbar-collapse" id="navbarMed">
            <ul className="navbar-nav mr-auto">
              {medicamento.capitulo.map((cap) => {
                if (cap.nombre === autor) {
                  return cap.subcapitulo.map((sub) => {
                    return (
                      <li
                        className="nav-item"
                        key={sub.nombre}
                        style={{ margin: "0px 10px" }}
                      >
                        <a href={`#${cap.nombre}-${sub.nombre}`}>
                          {sub.nombre}
                        </a>
                      </li>
                    );
                  });
                }
                return null;
              })}
            </ul>
          </div>
        </nav>
      );
    }
    return <div>cargando</div>;
  };

  const renderMedicamento = () => {
    if (medicamento) {
      return (
        <div className="scroll-medicamento noselect">
          <h1>{medicamento.medicamento}</h1>
          <p className="text-muted">
            {medicamento.abreviacion}, {medicamento.nombreAlterno}
          </p>

          {medicamento.capitulo.map((cap) => {
            if (cap.nombre === autor) {
              return (
                <React.Fragment key={cap.nombre}>
                  {cap.subcapitulo.map((sub, index) => {
                    return (
                      <React.Fragment key={sub.nombre}>
                        {index === 0 ? (
                          <h2 id={`${cap.nombre}-${sub.nombre}`}>
                            {sub.nombre.toUpperCase()}
                          </h2>
                        ) : (
                          <h3 id={`${cap.nombre}-${sub.nombre}`}>
                            {sub.nombre}
                          </h3>
                        )}
                        <p
                          className="text-justify"
                          style={{ whiteSpace: "pre-line" }}
                        >
                          {sub.texto}
                        </p>
                      </React.Fragment>
                    );
                  })}
                </React.Fragment>
              );
            }
            return null;
          })}
        </div>
      );
    }
    return <div>cargando</div>;
  };

  return (
    <div className="my-container">
      <Row>
        <Col xs={12} sm={3} lg={2}>
          {renderListaMedicamentos()}
        </Col>
        <Col>
          <Row>
            <Col xs={12}>{renderCapitulos()}</Col>
            <Col xs={12}>{renderMedicamento()}</Col>
          </Row>
        </Col>
      </Row>
    </div>
  );
};

export default MateriaMedica;

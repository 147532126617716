import React, { useState, useEffect } from "react";
import axios from "axios";
import { Row, Col, Button } from "react-bootstrap";
import ModalSintomas from "./ModalSintomas";
import ModalConfirmar from "../../general/ModalConfirmar";

const RelacionSintoma = ({ id }) => {
  const [relacion, setRelacion] = useState(null);
  const [capitulos, setCapitulos] = useState(null);
  const [agregar, setAgregar] = useState(false);

  const fetchData = async () => {
    const response = await axios.get(`/api/sintoma/${id}/sintomaRep`);
    setRelacion(response.data);

    const chaps = await axios.get("/api/libro/3/capitulo");
    setCapitulos(chaps.data);
  };

  useEffect(() => {
    fetchData();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const getNombreCapitulo = (id) => {
    if (capitulos) {
      let nombre = "";
      capitulos.forEach((item) => {
        if (item.codCapitulo === id) {
          nombre = item.titulo;
        }
      });
      return nombre;
    }
  };

  const borrar = async (codRep) => {
    await axios.delete(`/api/Sintoma/${id}/SintomaRep/${codRep}`);
    fetchData();
  };

  if (relacion) {
    return (
      <Row>
        <Col sm={12}>
          <dl>
            <dt>Relacion con Sintomas Homeopaticos</dt>
            {relacion.map((item) => {
              return (
                <dd key={item.repCodSintoma}>
                  {getNombreCapitulo(item.capitulo)}:{item.nombre}
                  <ModalConfirmar
                    borrar={() => borrar(item.repCodSintoma)}
                    cod={id}
                    nombre={item.nombre}
                  />
                </dd>
              );
            })}
            <Button
              variant="warning"
              onClick={() => {
                setAgregar(!agregar);
              }}
            >
              Relacionar con Sintoma de Homeopatia
            </Button>
            <ModalSintomas
              show={agregar}
              onHide={() => setAgregar(false)}
              id={id}
            />
          </dl>
        </Col>
      </Row>
    );
  }
  return null;
};

export default RelacionSintoma;

import { createSelector } from "reselect";

export const selectSintomasHomeopatia = (state) =>
  state.holonmed.sintomasHomeopatia;

export const selectCodigosSintomasHomeopatia = createSelector(
  [(state) => state.holonmed.sintomasHomeopatia],
  (sintomasHomeopatia) => {
    return sintomasHomeopatia.map((element) => element.codSintomaRep);
  }
);

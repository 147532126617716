import React, { useState, useEffect } from "react";
import axios from "axios";
import { Link } from "react-router-dom";
import { Table, Row, Col } from "react-bootstrap";

import SearchBar from "../../general/SearchBar";
import StyledLink from "../../general/StyledLink";

const ListaMedicamento = () => {
  const [data, setData] = useState(null);
  const [dataFilter, setDataFilter] = useState(null);

  const fetchData = async () => {
    const response = await axios.get("/api/medicamentos/nombres");
    setData(response.data);
    setDataFilter(response.data);
  };

  useEffect(() => {
    fetchData();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const filterFunction = (value) => {
    if (value.length > 0) {
      setDataFilter(
        data.filter((item) =>
          item.medicamento.toLowerCase().includes(`${value}`.toLowerCase())
        )
      );
    } else {
      setDataFilter(data);
    }
  };

  return (
    <Row>
      <Col xs={12}>
        <StyledLink to="/admin">Tablas</StyledLink>
      </Col>
      <Col xs={12}>
        <h1>Listado Medicamentos Homeosiniatria</h1>
        <SearchBar title="Buscar Medicamento" filterFunction={filterFunction} />
        <br />
        <Table striped bordered hover>
          <thead>
            <tr>
              <th>Código Medicamento</th>
              <th>Nombre Medicamento</th>
            </tr>
          </thead>
          <tbody>
            {dataFilter
              ? dataFilter.map((item) => {
                  return (
                    <tr key={item.codMedicamento}>
                      <td>
                        <StyledLink
                          to={`/admin/Medicamento/${item.codMedicamento}`}
                        >
                          {item.codMedicamento}
                        </StyledLink>
                      </td>
                      <td>{item.medicamento}</td>
                    </tr>
                  );
                })
              : null}
          </tbody>
        </Table>
      </Col>
    </Row>
  );
};

export default ListaMedicamento;

import axios from "axios";

export const getSindromesSugeridos = async (codigos) => {
  const codigosEncoded = codigos.toString();

  const { data } = await axios.get("/api/sindrome_sugerido/", {
    params: { codigosEncoded },
  });
  return data;
};

import React from "react";
import { Row, Col, Button } from "react-bootstrap";
import { useDispatch } from "react-redux";

import {
  agregarSintomaHomeopatia,
  reiniciarSintomaHomeopatia,
} from "../../../state/sintoma/actions";
import {
  agregarSintomaAcupuntura,
  reiniciarSintomaAcupuntura,
} from "../../../state/sintomaAcupuntura/actions";
import { GetSintomaAcupunturaById } from "../../../services/sintomasAcupuntura";
import { GetSintomaHomeopatiaById } from "../../../services/sintomasHomeopatia";

const Repertorizacion = (props) => {
  const timestamp = props.data._id.toString().substring(0, 8);
  const date = new Date(parseInt(timestamp, 16) * 1000);
  const dispatch = useDispatch();

  const cargar = () => {
    dispatch(reiniciarSintomaAcupuntura());
    dispatch(reiniciarSintomaHomeopatia());

    props.data.sintoAcu.forEach(async (element) => {
      const sintoma = await GetSintomaAcupunturaById(element);
      dispatch(agregarSintomaAcupuntura(sintoma));
    });

    props.data.sintoHomeo.forEach(async (element) => {
      const sintoma = await GetSintomaHomeopatiaById(element);
      dispatch(agregarSintomaHomeopatia(sintoma));
    });

    alert("Repertorizacion cargada");
  };

  return (
    <Row>
      <Col xs={12}>
        <h3>Repertorización paciente</h3>
        <p className="text-muted">{date.toString()}</p>
      </Col>
      <Col xs={12}>
        <Button variant="success" onClick={() => cargar()}>
          Cargar repertorización
        </Button>
      </Col>
    </Row>
  );
};

export default Repertorizacion;

import { combineReducers } from "redux";
import medicamentos from "./medicamento/reducer";
import payUReducer from "./payU/reducer";
import puntos from "./punto/reducer";
import sindromes from "./sindrome/reducer";
import sintomas from "./sintoma/reducer";
import sintomasAcupuntura from "./sintomaAcupuntura/reducer";

export default combineReducers({
  holonmed: combineReducers({
    sintomasHomeopatia: sintomas,
    sintomasAcupuntura,
    puntos,
  }),
  medicamentos,
  sindromes,
  // puntos,
  payU: payUReducer,
});

import React, { useEffect, useState } from "react";
import axios from "axios";
import { Row, Col, Button } from "react-bootstrap";

import RegistrarCita from "../registroControl/RegistrarCita";
import RegistrarPrimeraCita from "../registroControl/RegistrarPrimeraCita";
import RegistrarAcompaniante from "./RegistrarAcompaniante";
import Acompaniante from "./Acompaniante";
import Paciente from "./Paciente";
import PrimeraCita from "./PrimeraCita";
import Cita from "./Cita";
import Repertorizacion from "./Repertorizacion";

const PacienteDetail = (props) => {
  const [paciente, setPaciente] = useState(null);
  const [update, setUpdate] = useState(true);
  const { id } = props.match.params;

  const fetchData = async () => {
    const result = await axios.get(`/api/paciente/${id}`);
    setPaciente(result.data);
  };

  useEffect(() => {
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [update]);

  if (paciente) {
    return (
      <div className="container py-3">
        <Row>
          <Col className="d-flex justify-content-center">
            <Button variant="warning" onClick={window.print}>
              Imprimir información paciente
            </Button>
          </Col>
        </Row>

        <Paciente data={paciente} />
        <Row>
          <Col xs={12}>
            <h2>Acompañante</h2>
          </Col>

          <hr />
          <Col xs={12}>
            {paciente.acompaniante.length > 0 ? (
              <Acompaniante data={paciente.acompaniante[0]} />
            ) : (
              <RegistrarAcompaniante
                id={id}
                update={update}
                setUpdate={setUpdate}
              />
            )}
          </Col>
        </Row>
        <Row>
          <Col xs={12}>
            <h2>Registros</h2>
          </Col>
          <hr />
          <Col xs={12}>
            {paciente.primeraCita.length > 0 ? (
              <>
                <RegistrarCita id={id} update={update} setUpdate={setUpdate} />
                <hr />
                <PrimeraCita
                  data={paciente.primeraCita[0]}
                  esHombre={paciente.sexo === "Masculino"}
                />
                <hr />
              </>
            ) : (
              <RegistrarPrimeraCita
                id={id}
                ginecologia={paciente.sexo !== "Masculino"}
                update={update}
                setUpdate={setUpdate}
              />
            )}
          </Col>
        </Row>
        <Row>
          <Col xs={12} md={8} lg={9}>
            {paciente.citas.map((cita) => {
              return (
                <div key={cita._id}>
                  <Cita data={cita} />
                  <hr />
                </div>
              );
            })}
          </Col>
          <Col xs={12} md={4} lg={3}>
            {paciente.repertorizaciones.map((rep) => {
              return (
                <div key={rep._id}>
                  <Repertorizacion data={rep} />
                  <hr />
                </div>
              );
            })}
          </Col>
        </Row>
      </div>
    );
  }
  return <div>Cargando</div>;
};

export default PacienteDetail;

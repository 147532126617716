import React from "react";
import { Route, Redirect } from "react-router-dom";

import useAuth from "../../../customHooks/useAuth";

const AdminProtectedRoute = ({ component: Component, ...rest }) => {
  const { isAuth, rol } = useAuth();
  if (isAuth === true || isAuth === false) {
    if (isAuth) {
      if (rol) {
        return (
          <Route
            {...rest}
            render={(props) =>
              rol === "admin" ? <Component {...props} /> : <Redirect to="/" />
            }
          />
        );
      }
    } else {
      return <Route {...rest} render={(props) => <Redirect to="/login" />} />;
    }
  }
  return null;
};

export default AdminProtectedRoute;

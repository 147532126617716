import React, { useEffect, useState } from "react";
import { Container, Row, Col } from "react-bootstrap";
import axios from "axios";
import "../../css/datos.css";

const Datos = () => {
  const [datos, setDatos] = useState(null);

  const fetchData = async () => {
    const { data } = await axios.get("/api/datosPagina");
    setDatos(data);
  };

  useEffect(() => {
    fetchData();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  if (datos) {
    return (
      <section id="datos">
        <div id="watermark">
          <Container>
            <div className="section-title">
              <h2>datos</h2>
            </div>
            <Row>
              <Col sm={6} lg={4}>
                <div className="count-box">
                  <span data-toggle="counter-up">{datos.medicamentos}</span>
                  <p>Medicamentos</p>
                </div>
              </Col>
              <Col sm={6} lg={4}>
                <div className="count-box">
                  <span data-toggle="counter-up">{datos.puntos}</span>
                  <p>Puntos de acupuntura</p>
                </div>
              </Col>
              <Col sm={6} lg={4}>
                <div className="count-box">
                  <span data-toggle="counter-up">{datos.sintomas}</span>
                  <p>Sintomas</p>
                </div>
              </Col>
              <Col sm={6} lg={4}>
                <div className="count-box">
                  <span data-toggle="counter-up">{datos.sindromes}</span>
                  <p>Sindromes y enfermedades</p>
                </div>
              </Col>
              <Col sm={6} lg={4}>
                <div className="count-box">
                  <span data-toggle="counter-up">{datos.relaciones}</span>
                  <p>
                    Sintomas relacionados entre repertorio acupuntural y
                    repertorio homeopático
                  </p>
                </div>
              </Col>
              <Col sm={6} lg={4}>
                <div className="count-box">
                  <span data-toggle="counter-up">{datos.libros}</span>
                  <p>Libros</p>
                </div>
              </Col>
            </Row>
          </Container>
        </div>
      </section>
    );
  } else {
    return null;
  }
};

export default Datos;

import axios from "axios";

import { ADD_MEDICAMENTOS } from "./types";

export const addMedicamentos = (codigosSintomaRep) => async (dispatch) => {
  const response = await axios.get("/api/medicamentos_Sugeridos/", {
    params: {
      codigos: codigosSintomaRep,
    },
  });
  // dispatch({ type: ADD_MEDICAMENTOS, payload: response.data });
};

export const agregarMedicamentos = (medicamentos) => {
  return { type: ADD_MEDICAMENTOS, payload: medicamentos };
};

import React from "react";
import Hero from "./Hero";
import Datos from "./Datos";
import Servicios from "./Servicios";

const Home = () => {
  return (
    <React.Fragment>
      <Hero />

      <Servicios />
      <Datos />
    </React.Fragment>
  );
};

export default Home;

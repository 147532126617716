import axios from "axios";
import {
  ADD_SINTOMA,
  DELETE_SINTOMA,
  RESTART_SINTOMA,
  UPDATE_SINTOMA,
} from "./types";

export const addSintoma = (codSintoma) => async (dispatch) => {
  const response = await axios.get(`/api/sintomaRep/${codSintoma}`);

  //dispatch({ type: ADD_SINTOMA, payload: response.data });
};

export const restartSintoma = () => async (dispatch) => {
  dispatch({ type: RESTART_SINTOMA });
};

export const agregarSintomaHomeopatia = (sintoma) => {
  return { type: ADD_SINTOMA, payload: sintoma };
};

export const borrarSintomaHomeopatia = (codSintoma) => {
  return { type: DELETE_SINTOMA, payload: codSintoma };
};

export const jerarquizarSintomaHomeopatia = (sintoma, pin) => {
  return { type: UPDATE_SINTOMA, payload: { ...sintoma, jerarquizado: !pin } };
};

export const reiniciarSintomaHomeopatia = () => {
  return { type: RESTART_SINTOMA };
};

import React from "react";
import { BrowserRouter, Route, Switch } from "react-router-dom";
import { Provider } from "react-redux";
import { createStore, applyMiddleware, compose } from "redux";
import reduxThunk from "redux-thunk";

import ProtectedRoute from "./general/rutasProtegidas/ProtectedRoute";
import SubscriptionRoute from "./general/rutasProtegidas/SubscriptionProtectedRoute";
import AdminRoute from "./general/rutasProtegidas/AdminProtectedRoute";
import { loadState, saveState } from "../stateLocal";

import GoogleProfile from "./general/GoogleProfile";
import NotFoundPage from "./general/NotFoundPage";
import Navigation from "./general/Navigation";
import Footer from "./general/Footer";
import Pago from "./general/pagos/PayU";
import Home from "./home/Home";
import Login from "./Login";
import Signup from "./Signup";
import Libro from "./libros/Libro";
import DetalleLibro from "./libros/DetalleLibro";
import Planes from "./ventas/Planes";
import Tyc from "./ventas/Tyc";
import Contacto from "./ventas/Contacto";
import Tutorial from "./ventas/Tutorial";
import Integracion from "./integracion/Integracion";
import Medicamento from "./Medicamento";
import Pacientes from "./pacientes/Pacientes";
import PacientesDetail from "./pacientes/detalle/PacienteDetail";

import Admin from "./admin/Admin";
import Detalle from "./admin/Detalle";
import Lista from "./admin/Lista";
import Nuevo from "./admin/Nuevo";

import reducers from "../state/reducers";

import "../css/main.css";
import "bootstrap/dist/css/bootstrap.min.css";

const App = () => {
  const composeEnhancers =
    window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

  const initialData = loadState();
  const store = createStore(
    reducers,
    initialData,
    composeEnhancers(applyMiddleware(reduxThunk))
  );
  store.subscribe(function () {
    saveState(store.getState());
  });

  return (
    <Provider store={store}>
      <BrowserRouter>
        <Route path="/" component={Navigation} />
        <main>
          <Switch>
            <Route path="/" exact component={Home} />
            <Route path="/login" exact component={Login} />
            <Route path="/signup" exact component={Signup} />
            <Route path="/planes" exact component={Planes} />
            <Route path="/contacto" exact component={Contacto} />
            <Route path="/tyc" exact component={Tyc} />
            <Route path="/tutorial" exact component={Tutorial} />
            <ProtectedRoute path="/perfil" exact component={GoogleProfile} />
            <ProtectedRoute path="/pago" exact component={Pago} />
            <SubscriptionRoute path="/libro" exact component={Libro} />
            <SubscriptionRoute
              path="/libro/:id"
              exact
              component={DetalleLibro}
            />
            <SubscriptionRoute
              path="/integracion"
              exact
              component={Integracion}
            />
            <SubscriptionRoute
              path="/medicamento/:id"
              exact
              component={Medicamento}
            />
            <SubscriptionRoute path="/pacientes" exact component={Pacientes} />
            <SubscriptionRoute
              path="/pacientes/:id"
              exact
              component={PacientesDetail}
            />
            <AdminRoute path="/admin" exact component={Admin} />
            <AdminRoute path="/admin/:handle" exact component={Lista} />
            <AdminRoute path="/admin/:handle/:_id" exact component={Detalle} />
            <AdminRoute
              path="/admin/nuevo/registro/:handle"
              component={Nuevo}
            />
            <Route path="/" component={NotFoundPage} />
          </Switch>
        </main>
        <Footer />
      </BrowserRouter>
    </Provider>
  );
};

export default App;

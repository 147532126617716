import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPills } from "@fortawesome/free-solid-svg-icons";
import { Card } from "react-bootstrap";

import TablaMed from "./TablaMed";

const Medicamentos = () => {
  return (
    <Card>
      <Card.Header as="h3">
        Medicamentos recomendados <FontAwesomeIcon icon={faPills} />
      </Card.Header>
      <Card.Body>
        <TablaMed />
      </Card.Body>
    </Card>
  );
};

export default Medicamentos;

import React from "react";
import { Form } from "react-bootstrap";

const SearchBar = ({ title, filterFunction }) => {
  const handleSubmit = (evt) => {
    evt.preventDefault();
  };

  const handleChange = (evt) => {
    const value = evt.target.value;
    filterFunction(value);
  };

  return (
    <Form onSubmit={handleSubmit}>
      <Form.Group controlId="SearchBar">
        <Form.Label>{title}</Form.Label>
        <Form.Control type="text" placeholder={title} onChange={handleChange} />
      </Form.Group>
    </Form>
  );
};

export default SearchBar;

import React, { useState, useEffect } from "react";
import axios from "axios";
import { Modal, Button, Row, Col, ListGroup } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faPlusCircle,
  faArrowLeft,
  faSearch,
} from "@fortawesome/free-solid-svg-icons";

const ModalSintomas = (props) => {
  const [busqueda, setBusqueda] = useState("");
  const [capitulos, setCapitulos] = useState([]);
  const [capituloSeleccionado, setCapituloSeleccionado] = useState(30);
  const [nivel, setNivel] = useState(1);
  const [filtro, setFiltro] = useState([]);
  const [sintomas, setSintomas] = useState([]);
  const [busquedaGeneral, setBusquedaGeneral] = useState(null);

  const fetchData = async () => {
    const response = await axios.get("/api/libro/3/capitulo");
    setCapitulos(response.data);
  };

  useEffect(() => {
    fetchData();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const fetchSintomas = async () => {
    const responseSinto = await axios.get(
      `/api/capitulo/${capituloSeleccionado}/sintomaRep`
    );

    setSintomas(responseSinto.data);
  };

  useEffect(() => {
    fetchSintomas();
  }, [capituloSeleccionado]); // eslint-disable-line react-hooks/exhaustive-deps

  const handleSubmit = async (evt) => {
    evt.preventDefault();
    if (capituloSeleccionado === -1) {
      const response = await axios.get(`/api/sintomaRep/nombre/${busqueda}`);
      setBusquedaGeneral(response.data);
    }
  };

  const handleChange = (evt) => {
    const value = evt.target.value;
    setBusqueda(value);
  };

  const searchSintoma = () => {
    return (
      <form onSubmit={handleSubmit}>
        <div className="form-group">
          <div className="d-flex justify-content-start">
            <FontAwesomeIcon icon={faSearch} size="2x" />
            <input
              type="text"
              className="form-control"
              onChange={handleChange}
              value={busqueda}
            />
          </div>
        </div>
      </form>
    );
  };

  const resultadoBusqueda = () => {
    return (
      <div className="overflow-auto" style={{ maxHeight: "700px" }}>
        <ListGroup>
          {busquedaGeneral ? (
            busquedaGeneral.map((item) => (
              <ListGroup.Item
                key={item.codSintomaRep}
                action
                onClick={() => {
                  postRelacion(item.codSintomaRep);
                }}
              >
                <Row>
                  <Col xs={2}>{getNombreCapitulo(item.codCapitulo)}</Col>
                  <Col xs={8}>{item.nombre}</Col>
                  <Col xs={2}>Medicamentos: {item.numeroMedicamentos}</Col>
                </Row>
              </ListGroup.Item>
            ))
          ) : (
            <ListGroup.Item>
              Aqui aparecera el resultado de la busqueda
            </ListGroup.Item>
          )}
        </ListGroup>
      </div>
    );
  };

  const contains = (target, pattern) => {
    var value = 0;
    pattern.forEach(function (word) {
      value = value + target.includes(word.toLowerCase());
    });
    return value === pattern.length;
  };

  const showMoreSintoms = (i) => {
    const tempFilter = sintomas.filter(
      (d) =>
        (d.nombre.match(/:\//g) || []).length === nivel + 1 &&
        contains(d.nombre.toLowerCase(), [...filtro, i.nombre])
    );
    if (tempFilter.length > 0) {
      return (
        <Button
          variant="default"
          onClick={() => {
            setNivel(nivel + 1);
            setFiltro([...filtro, i.nombre]);
            setBusqueda("");
          }}
        >
          <FontAwesomeIcon icon={faPlusCircle} color="red" size="1x" />
        </Button>
      );
    }
    return null;
  };

  const postRelacion = async (rep) => {
    await axios.post("/api/sintoma/SintomaRep", {
      repCodSintoma: rep,
      codSintoma: props.id,
    });
    alert("Relacionado");
  };

  const getNombreCapitulo = (id) => {
    if (capitulos) {
      let nombre = "";
      capitulos.forEach((item) => {
        if (item.codCapitulo === id) {
          nombre = item.titulo;
        }
      });
      return nombre;
    }
  };

  const renderFilteredData = () => {
    if (sintomas.length > 0) {
      const dataFiltrada = sintomas.filter(
        (d) =>
          (d.nombre.match(/:\//g) || []).length === nivel &&
          contains(d.nombre.toLowerCase(), [...filtro, busqueda])
      );

      return (
        <>
          <div>
            {filtro.length > 0 ? (
              <>
                <Button
                  variant="default"
                  onClick={() => {
                    setNivel(nivel - 1);
                    setFiltro(
                      filtro.filter((e) => e !== filtro[filtro.length - 1])
                    );
                    setBusqueda("");
                  }}
                >
                  <FontAwesomeIcon icon={faArrowLeft} color="green" />
                </Button>
                {filtro[filtro.length - 1]}
              </>
            ) : null}
          </div>

          <div className="overflow-auto" style={{ maxHeight: "700px" }}>
            <ListGroup>
              {dataFiltrada.map((item) => (
                <ListGroup.Item key={item.codSintomaRep}>
                  <Row>
                    <Col xs={1}>{showMoreSintoms(item)}</Col>
                    <Col
                      xs={8}
                      onClick={() => {
                        postRelacion(item.codSintomaRep);
                      }}
                      style={{ cursor: "pointer" }}
                    >
                      {item.nombre}
                    </Col>
                    <Col xs={3}>Medicamentos: {item.numeroMedicamentos}</Col>
                  </Row>
                </ListGroup.Item>
              ))}
            </ListGroup>
          </div>
        </>
      );
    }
    return <div>Cargando</div>;
  };

  const renderCapitulos = () => {
    return (
      <div className="overflow-auto" style={{ maxHeight: "750px" }}>
        <ListGroup>
          {capitulos.map((item) => (
            <ListGroup.Item
              className={`${
                item.codCapitulo === capituloSeleccionado ? "active" : null
              }`}
              key={item.codCapitulo}
              onClick={() => {
                setCapituloSeleccionado(item.codCapitulo);
                setNivel(1);
                setFiltro([]);
                setBusqueda("");
              }}
              style={{ cursor: "pointer" }}
            >
              {item.titulo}
            </ListGroup.Item>
          ))}
        </ListGroup>
      </div>
    );
  };

  return (
    <Modal
      {...props}
      size="xl"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
          Relacionar Sintomas
        </Modal.Title>
        <Button
          variant="primary"
          size="lg"
          block
          onClick={() => {
            setCapituloSeleccionado(-1);
            setBusqueda("");
          }}
        >
          Busqueda general
        </Button>
      </Modal.Header>
      <Modal.Body>
        <Row>
          <Col xs={12} sm={5} lg={3}>
            {renderCapitulos()}
          </Col>
          <Col>
            {searchSintoma()}
            {capituloSeleccionado === -1
              ? resultadoBusqueda()
              : renderFilteredData()}
          </Col>
        </Row>
      </Modal.Body>
    </Modal>
  );
};

export default ModalSintomas;

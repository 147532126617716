import React, { useState, useEffect } from "react";
import axios from "axios";
import { Row, Col } from "react-bootstrap";

import Campo from "../detalle/Campo";
import Capitulo from "./Capitulo";
import StyledLink from "../../general/StyledLink";

const DetalleAcupuntura = ({ id }) => {
  const [detalle, setDetalle] = useState(null);

  const fetchData = async () => {
    const response = await axios.get(`/api/Medicamento/${id}`);
    setDetalle(response.data);
  };

  useEffect(() => {
    fetchData();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const validateForm = () => {
    return {};
  };

  if (detalle) {
    const camposAlterno = [
      {
        id: "nombreAlterno",
        type: "text",
        placeholder: detalle.nombreAlterno,
      },
    ];

    return (
      <Row>
        <Col sm={12}>
          <StyledLink to="/admin/Medicamento">
            Listado Medicamentos Homeosiniatria
          </StyledLink>
          <h1>Medicamento Homeosiniatria: {id}</h1>
          <br />
        </Col>
        <Col sm={12} md={6} lg={4}>
          <Campo titulo={"Nombre Medicamento"} detail={detalle.medicamento} />
        </Col>
        <Col sm={12} md={6} lg={4}>
          <Campo titulo={"Abreviación"} detail={detalle.abreviacion} />
        </Col>
        <Col sm={12} lg={4}>
          <Campo
            titulo={"Nombre Alterno"}
            detail={detalle.nombreAlterno}
            editable={true}
            campos={camposAlterno}
            validateForm={validateForm}
            apiRoute={`/api/Medicamento/${id}`}
          />
        </Col>
        <Col sm={{ span: 11, offset: 1 }}>
          {detalle.capitulo.map((chap) => {
            return (
              <Capitulo
                data={chap}
                validateForm={validateForm}
                id={id}
                key={chap.nombre}
              />
            );
          })}
        </Col>
      </Row>
    );
  }
  return <div>Cargando</div>;
};

export default DetalleAcupuntura;

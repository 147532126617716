import React from "react";

import DetalleAcupuntura from "./acupuntura/DetalleAcupuntura";
import DetalleMedicamento from "./medicamento/DetalleMedicamento";
import DetalleSintomaAcupuntura from "./sintomaAcupuntura/DetalleSintomaAcupuntura";
import DetalleSintomaHomeopatia from "./sintomaHomeopatia/DetalleSintomaHomeopatia";
import DetalleSindrome from "./Sindrome/Detalle";

const Detalle = (props) => {
  const { _id, handle } = props.match.params;
  const renderDetalle = () => {
    switch (handle) {
      case "Acupuntura":
        return <DetalleAcupuntura id={_id} />;
      case "Medicamento":
        return <DetalleMedicamento id={_id} />;
      case "Sintoma":
        return <DetalleSintomaAcupuntura id={_id} />;
      case "SintomaRep":
        return <DetalleSintomaHomeopatia id={_id} />;
      case "Sindrome":
        return <DetalleSindrome id={_id} />;
      default:
        return <DetalleAcupuntura id={_id} />;
    }
  };

  return <div className="container">{renderDetalle()}</div>;
};

export default Detalle;

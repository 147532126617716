import React from "react";
import { Button, Table } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faMinusCircle } from "@fortawesome/free-solid-svg-icons";

import { selectSintomasAcupuntura } from "../../../../state/sintomaAcupuntura/selectors";
import { borrarSintomaAcupuntura } from "../../../../state/sintomaAcupuntura/actions";

import Jerarquico from "./Jerarquico";
import Mapa from "./Mapa";

const Tabla = () => {
  const sintomasAcupuntura = useSelector(selectSintomasAcupuntura);
  const dispatch = useDispatch();

  const renderBody = () => {
    if (sintomasAcupuntura.length > 0) {
      return sintomasAcupuntura.map((item, index) => {
        return (
          <tr key={index}>
            <td>{item.nombre}</td>
            <td>
              <div className="d-flex justify-content-between">
                <Jerarquico item={item} />
                <Mapa cod={item.codSintoma} />
                <Button
                  variant="default"
                  data-toggle="tooltip"
                  data-placement="top"
                  title="Borrar"
                  onClick={() => {
                    dispatch(borrarSintomaAcupuntura(item.codSintoma));
                  }}
                >
                  <FontAwesomeIcon icon={faMinusCircle} color="red" />
                </Button>
              </div>
            </td>
          </tr>
        );
      });
    }

    return (
      <tr>
        <td>Agrega Sintomas para comenzar con el proceso de repertorización</td>
        <td></td>
      </tr>
    );
  };

  return (
    <Table striped>
      <thead>
        <tr>
          <th>Nombre</th>
          <th>opciones</th>
        </tr>
      </thead>
      <tbody>{renderBody()}</tbody>
    </Table>
  );
};

export default Tabla;

import React, { useState } from "react";
import { Form, Button, Row, Col } from "react-bootstrap";
import axios from "axios";
import _ from "lodash";

import ModalRegistro from "../ModalRegistro";

const ExamenGeneral = ({ id, values, setValues, errors }) => {
  const campo = {
    id: "general",
    label: "Estado General",

    detalle: [
      { id: "estado", label: "Estado General" },
      { id: "peso", label: "Peso *", placeholder: "Kg" },
      { id: "talla", label: "Talla *", placeholder: "mts" },
      { id: "tension", label: "Tensión", placeholder: "mm de hg" },
      {
        id: "frecCardiaca",
        label: "Frecuencia Cardiaca",
        placeholder: "latidos/minutos",
      },
      {
        id: "frecRespiratoria",
        label: "Frecuencia Respiratoria",
        placeholder: "respiraciones/minutos",
      },
      { id: "temperatura", label: "Temperatura", placeholder: "°C" },
      { id: "pulso", label: "Pulso" },
      { id: "lengua", label: "Lengua" },
      { id: "tez", label: "Tez" },
    ],
  };

  const handleChange = (evt) => {
    let copy = { ...values };
    copy[id][0]["detalle"][evt.target.name] = evt.target.value;
    setValues(copy);
  };

  return (
    <Form.Group as={Row}>
      <Form.Label htmlFor={campo.id} column sm={2} md={2}>
        {campo.label}
      </Form.Label>
      <Col sm={10} md={10}>
        <Row>
          <Col>
            <Form.Label htmlFor={campo.detalle[0].id}>
              {campo.detalle[0].label}
            </Form.Label>
            <Form.Control
              id={campo.id}
              name={campo.id}
              onChange={handleChange}
              value={values[id][campo.id]}
              placeholder={campo.placeholder}
            />
          </Col>
        </Row>
        <Form.Group as={Row}>
          <Form.Label column sm={2} md={2}>
            {campo.detalle[1].label}
          </Form.Label>
          <Col sm={4} md={2}>
            <Form.Control
              id={campo.detalle[1].id}
              name={campo.detalle[1].id}
              onChange={handleChange}
              value={values[id][campo.detalle[1].id]}
              placeholder={campo.detalle[1].placeholder}
            />
            <p className="error-small">{errors[campo.detalle[1].id]}</p>
          </Col>

          <Form.Label column sm={2} md={2}>
            {campo.detalle[2].label}
          </Form.Label>
          <Col sm={4} md={2}>
            <Form.Control
              id={campo.detalle[2].id}
              name={campo.detalle[2].id}
              onChange={handleChange}
              value={values[id][campo.detalle[2].id]}
              placeholder={campo.detalle[2].placeholder}
            />
            <p className="error-small">{errors[campo.detalle[2].id]}</p>
          </Col>
          <Form.Label column sm={2} md={2}>
            {campo.detalle[3].label}
          </Form.Label>
          <Col sm={4} md={2}>
            <Form.Control
              id={campo.detalle[3].id}
              name={campo.detalle[3].id}
              onChange={handleChange}
              value={values[id][campo.detalle[3].id]}
              placeholder={campo.detalle[3].placeholder}
            />
          </Col>
          <Form.Label column sm={2} md={2}>
            {campo.detalle[4].label}
          </Form.Label>
          <Col sm={4} md={2}>
            <Form.Control
              id={campo.detalle[4].id}
              name={campo.detalle[4].id}
              onChange={handleChange}
              value={values[id][campo.detalle[4].id]}
              placeholder={campo.detalle[4].placeholder}
            />
          </Col>

          <Form.Label column sm={2} md={2}>
            {campo.detalle[5].label}
          </Form.Label>
          <Col sm={4} md={2}>
            <Form.Control
              id={campo.detalle[5].id}
              name={campo.detalle[5].id}
              onChange={handleChange}
              value={values[id][campo.detalle[5].id]}
              placeholder={campo.detalle[5].placeholder}
            />
          </Col>
          <Form.Label column sm={2} md={2}>
            {campo.detalle[6].label}
          </Form.Label>
          <Col sm={4} md={2}>
            <Form.Control
              id={campo.detalle[6].id}
              name={campo.detalle[6].id}
              onChange={handleChange}
              value={values[id][campo.detalle[6].id]}
              placeholder={campo.detalle[6].placeholder}
            />
          </Col>
        </Form.Group>
        <Row>
          <Col>
            <Form.Label>{campo.detalle[7].label}</Form.Label>
            <Form.Control
              id={campo.detalle[7].id}
              name={campo.detalle[7].id}
              onChange={handleChange}
              value={values[id][campo.detalle[7].id]}
            />
          </Col>
        </Row>
        <Row>
          <Col>
            <Form.Label>{campo.detalle[8].label}</Form.Label>
            <Form.Control
              id={campo.detalle[8].id}
              name={campo.detalle[8].id}
              onChange={handleChange}
              value={values[id][campo.detalle[8].id]}
            />
          </Col>
        </Row>
      </Col>
    </Form.Group>
  );
};

const ExamenSistema = ({ campo, values, setValues, id }) => {
  const handleChange = (evt) => {
    let copy = { ...values };
    copy[id][campo.num] = {
      sistema: campo.id,
      detalle: evt.target.value,
    };
    setValues(copy);
  };

  return (
    <Form.Group as={Row}>
      <Form.Label htmlFor={campo.id} column sm={4} md={3}>
        {campo.label}
      </Form.Label>
      <Col sm={8} md={9}>
        <Form.Control
          as="textarea"
          rows={3}
          id={campo.id}
          name={campo.id}
          onChange={handleChange}
          value={values[id][campo.id]}
          placeholder={campo.placeholder}
        />
      </Col>
    </Form.Group>
  );
};

const RegistrarCita = ({ id, update, setUpdate }) => {
  const [show, setShow] = useState(false);
  const [respuestaFormulario, setRespuestaFormulario] = useState("");
  const [errors, setErrors] = useState({});
  const [values, setValues] = useState({
    motivo: "",
    examenSistemas: [
      {
        sistema: "general",
        detalle: {
          estado: "",
          peso: 0,
          talla: 0,
          tension: 0,
          frecCardiaca: 0,
          frecRespiratoria: 0,
          temperatura: 0,
          pulso: 0,
          lengua: "",
          tez: "",
        },
      },
      { sistema: "cabeza", detalle: "" },
      { sistema: "cuello", detalle: "" },
      { sistema: "tiroides", detalle: "" },
      { sistema: "oftalmologicos", detalle: "" },
      { sistema: "otirrinolaringologia", detalle: "" },
      { sistema: "corazon", detalle: "" },
      { sistema: "pulmones", detalle: "" },
      { sistema: "abdomen", detalle: "" },
      { sistema: "genitales", detalle: "" },
      { sistema: "osteoarticular", detalle: "" },
      { sistema: "neurologico", detalle: "" },
      { sistema: "piel", detalle: "" },
    ],
    examenEnergetico: "",
    diagnostico: "",
    comentario: "",
  });

  const campos = [
    {
      label: "Motivo consulta *",
      id: "motivo",
    },
    {
      label: "Examen Físico por Sistemas",
      id: "examenSistemas",
    },
    {
      label: "Examen Energetico",
      id: "examenEnergetico",
    },
    {
      label: "Diagnostico *",
      id: "diagnostico",
    },
    {
      label: "Comentarios",
      id: "comentario",
    },
  ];

  const validateForm = (values) => {
    const err = {};
    if (values.motivo.length === 0) {
      err.motivo = "Ingresa un motivo para la consulta";
    }
    if (values.diagnostico.length === 0) {
      err.diagnostico = "Ingresa un diagnostico";
    }
    if (isNaN(values.examenSistemas[0].detalle.peso)) {
      err.talla = "Ingresa un peso valido (solo números) ej: 72.1";
    } else if (values.examenSistemas[0].detalle.peso === 0) {
      err.peso = "Ingresa el peso del paciente";
    } else if (
      parseFloat(values.examenSistemas[0].detalle.peso) < 1 ||
      parseFloat(values.examenSistemas[0].detalle.peso) > 500
    ) {
      err.peso =
        "Revisa el peso ingresado, debe de estar en kilogramos ej: 72.1";
    }

    if (isNaN(values.examenSistemas[0].detalle.talla)) {
      err.talla = "Ingresa una altura valida (solo números) ej: 1.83";
    } else if (values.examenSistemas[0].detalle.talla === 0) {
      err.talla = "Ingresa la altura del paciente";
    } else if (
      parseFloat(values.examenSistemas[0].detalle.talla) < 0.1 ||
      parseFloat(values.examenSistemas[0].detalle.talla) > 3
    ) {
      err.talla = "Revisa la talla ingresada, debe estar en metros ej: 1.83";
    }

    return err;
  };

  const handleSubmit = async (evt) => {
    evt.preventDefault();
    let err = validateForm(values);
    setErrors(err);
    if (_.isEmpty(err)) {
      try {
        await axios.post(`/api/paciente/${id}/cita`, values);
        setRespuestaFormulario("correcto");
      } catch (err) {
        setRespuestaFormulario("Error del servidor");
      }
    } else {
      setRespuestaFormulario(
        "Revisa los campos, valida la información y vuelve a intentar"
      );
    }
  };

  const handleChange = (evt) => {
    setValues({
      ...values,
      [evt.target.name]: evt.target.value,
    });
  };

  const renderSistemas = (id) => {
    const camposSistemas = [
      {
        label: "Cabeza",
        id: "cabeza",
        num: 1,
      },
      {
        label: "Cuello",
        id: "cuello",
        num: 2,
      },
      {
        label: "Tiroides",
        id: "tiroides",
        num: 3,
      },
      {
        label: "Oftalmológicos",
        id: "oftalmologicos",
        num: 4,
      },
      {
        label: "Otirrinolaringología",
        id: "otirrinolaringologia",
        num: 5,
      },
      {
        label: "Corazón",
        id: "corazon",
        num: 6,
      },
      {
        label: "Pulmones",
        id: "pulmones",
        num: 7,
      },
      {
        label: "Abdomen",
        id: "abdomen",
        num: 8,
      },
      {
        label: "Genitales",
        id: "genitales",
        num: 9,
      },
      {
        label: "Osteoarticular",
        id: "osteoarticular",
        num: 10,
      },
      {
        label: "Neurológico",
        id: "neurologico",
        num: 11,
      },
      {
        label: "Piel y faneras",
        id: "piel",
        num: 12,
      },
    ];
    return (
      <React.Fragment>
        <ExamenGeneral
          id={id}
          values={values}
          setValues={setValues}
          errors={errors}
        />
        {camposSistemas.map((campo) => {
          return (
            <ExamenSistema
              id={id}
              campo={campo}
              values={values}
              setValues={setValues}
              key={campo.num}
            />
          );
        })}
      </React.Fragment>
    );
  };

  return (
    <React.Fragment>
      <Button onClick={() => setShow(!show)}>Registrar Control Paciente</Button>

      <ModalRegistro
        title="Registrar Control Paciente"
        show={show}
        onHide={() => {
          setShow(false);
          setUpdate(!update);
        }}
      >
        <Form onSubmit={handleSubmit}>
          <Form.Group>
            <Form.Label htmlFor={campos[0].id}>{campos[0].label}</Form.Label>
            <Form.Control
              as="textarea"
              id={campos[0].id}
              name={campos[0].id}
              className={errors[campos[0].id] ? "form-error" : ""}
              onChange={handleChange}
              rows={5}
              value={values[campos[0].id]}
            />
            <p className="error-small">{errors[campos[0].id]}</p>
          </Form.Group>
          <Form.Group>
            <Form.Label column="lg">{campos[1].label}</Form.Label>
            {renderSistemas(campos[1].id)}
          </Form.Group>
          <hr />
          <Form.Group>
            <Form.Label htmlFor={campos[2].id}>{campos[2].label}</Form.Label>
            <Form.Control
              as="textarea"
              id={campos[2].id}
              name={campos[2].id}
              className={errors[campos[2].id] ? "form-error" : ""}
              onChange={handleChange}
              rows={5}
              value={values[campos[2].id]}
            />
            <p className="error-small">{errors[campos[2].id]}</p>
          </Form.Group>
          <Form.Group>
            <Form.Label htmlFor={campos[3].id}>{campos[3].label}</Form.Label>
            <Form.Control
              as="textarea"
              id={campos[3].id}
              name={campos[3].id}
              className={errors[campos[3].id] ? "form-error" : ""}
              onChange={handleChange}
              rows={5}
              value={values[campos[3].id]}
            />
            <p className="error-small">{errors[campos[3].id]}</p>
          </Form.Group>
          <Form.Group>
            <Form.Label htmlFor={campos[4].id}>{campos[4].label}</Form.Label>
            <Form.Control
              as="textarea"
              id={campos[4].id}
              name={campos[4].id}
              className={errors[campos[4].id] ? "form-error" : ""}
              onChange={handleChange}
              rows={5}
              value={values[campos[4].id]}
            />
            <p className="error-small">{errors[campos[4].id]}</p>
          </Form.Group>
          <Button variant="primary" type="submit">
            Agregar Cita
          </Button>
          {respuestaFormulario === "correcto" ? (
            <p>Cita agregada correctamente, por favor cierra el formulario</p>
          ) : (
            <p className="error-small">{respuestaFormulario}</p>
          )}
        </Form>
      </ModalRegistro>
    </React.Fragment>
  );
};

export default RegistrarCita;

import React, { useState, useEffect } from "react";
import axios from "axios";

import HorizontalCard from "../HorizontalCard";

const Libro = () => {
  const [libros, setLibros] = useState(null);

  const fetchData = async () => {
    const response = await axios.get("/api/libro");
    setLibros(response.data);
  };

  useEffect(() => {
    fetchData();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  if (libros) {
    return (
      <div id="watermark">
        <div className="container">
          {libros.map((l) => {
            return (
              <HorizontalCard
                key={l.codLibro}
                titulo={l.titulo}
                parrafo={l.resumen}
                autor={l.autor}
                imagen={
                  l.img === ""
                    ? "https://images.vexels.com/media/users/3/194604/isolated/preview/96aa09d59cda8a3f112bada160a1d383-icono-plano-de-libro-de-texto-escolar-by-vexels.png"
                    : l.img
                }
                idLibro={l.codLibro}
              />
            );
          })}
        </div>
      </div>
    );
  }
  return <div className="container">Cargando</div>;
};

export default Libro;

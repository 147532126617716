import React from "react";

const Tyc = () => {
  return (
    <div className="my-container">
      <h1>Términos y Condiciones</h1>
      <h2>1.ACEPTACIÓN</h2>
      <p>
        En el presente documento (en adelante, el “Contrato”) se establecen los
        términos y condiciones de Aurea Medicina Integrativa Empresa con
        domicilio en calle 112 # 70 - 29, Bogotá, que serán de aplicación al
        acceso y uso por parte del Usuario de esta página web . Les rogamos lean
        atentamente el presente Contrato. Al acceder, consultar o utilizar el
        Sitio Web, los Usuarios (“Vd.”, “usted”, “Usuario”, o “usuario”) aceptan
        cumplir los términos y condiciones establecidos en este Contrato. En
        caso de que usted no acepte quedar vinculado por los presentes términos
        y condiciones, no podrá acceder a, ni utilizar, el Sitio Web. Aurea
        Medicina Integrativa y sus respectivas empresas afiliadas se reservan el
        derecho de actualizar el presente Contrato siempre que lo consideren
        oportuno. En consecuencia, recomendamos al Usuario revisar
        periódicamente las modificaciones efectuadas al Contrato.
      </p>
      <h2>2. REQUISITOS RELATIVOS AL USUARIO</h2>
      <p>
        El Sitio Web y los servicios relacionados con el mismo se ofrecen a los
        Usuarios que tengan capacidad legal para otorgar contratos legalmente
        vinculantes según la legislación aplicable. Los menores no están
        autorizados para utilizar el Sitio Web. Si usted es menor de edad, por
        favor, no utilice esta web.
      </p>
      <h2>3. LICENCIA</h2>
      <p>
        En este acto, RH otorga al Usuario una licencia limitada, no exclusiva,
        intransferible, no susceptible de cesión y revocable; para consultar y
        descargar, de forma temporal, una copia del contenido ofrecido en el
        Sitio Web, únicamente para uso personal del Usuario o dentro de su
        empresa, y nunca con fines comerciales. Todo el material mostrado u
        ofrecido en el Sitio Web, entre otros ejemplos, el material gráfico, los
        documentos, textos, imágenes, sonido, video, audio, las ilustraciones,
        el software y el código HTML (en conjunto, el “Contenido”), es de
        exclusiva propiedad de RH o de las empresas que facilitan dicho
        material. El Contenido está protegido por las leyes de copyright
        internacionales, así como por las demás leyes, reglamentos y normas
        aplicables a los derechos de propiedad intelectual. Salvo disposición
        expresa en contrario en el presente contrato, y/o salvo que por
        imperativo legal ello esté expresamente permitido por leyes derogatorias
        de las actualmente vigentes, el Usuario no podrá
      </p>
      <ol style={{ listStyleType: "upper-roman" }}>
        <li>
          utilizar, copiar, modificar, mostrar, eliminar, distribuir, descargar,
          almacenar, reproducir, transmitir, publicar, vender, revender,
          adaptar, invertir el proceso de creación o crear productos derivados a
          partir de, el Contenido. Tampoco podrá
        </li>
        <li>
          utilizar el Contenido en otras páginas Web o en cualquier medio de
          comunicación como, por ejemplo, en un entorno de red, sin la previa
          autorización por escrito en este sentido de RH. Todas las marcas
          comerciales, las marcas de servicio y los logos (en adelante, las
          “Marcas”) mostrados en el Sitio Web son propiedad exclusiva de RH y de
          sus respectivos propietarios. El Usuario no podrá utilizar las Marcas
          en modo alguno sin la previa autorización por escrito para ello de RH
          y los respectivos propietarios.
        </li>
      </ol>

      <h2>4. INFORMACIÓN FACILITADA POR EL USUARIO</h2>
      <p>
        Al facilitar o introducir la información en el Sitio Web (“Información
        del Usuario”), el Usuario otorga a RH licencia y derecho permanente, no
        exclusivo, irrevocable, libre de royalties, durante el tiempo máximo
        permitido por la legislación aplicable, pero no le impone obligación de,
        utilizar, copiar, modificar, mostrar, distribuir, descargar, almacenar,
        reproducir, transmitir, publicar, vender, revender, adaptar ni crear
        productos derivados en todo o en parte a partir de, la Información del
        Usuario, en ningún modo o manera. RH no comprueba ni controla la
        Información del Usuario. En consecuencia, RH no asume garantía alguna en
        cuanto a la fiabilidad, precisión, integridad, validez o veracidad de la
        Información remitida por los usuarios. RH se reserva el derecho de, a su
        libre JUICIO, eliminar, retirar, negarse a reflejar o bloquear toda
        Información del Usuario que RH considere como inaceptable. En caso de
        que RH reciba alguna notificación sobre la inaceptabilidad de
        determinada información facilitada por los usuarios, RH podrá, con total
        discrecionalidad, investigar dicha información. El Usuario asume y
        acepta que RH podrá conservar copia de la Información del Usuario, y
        revelar dicha información a terceros si lo considera necesario para:
      </p>
      <ol style={{ listStyleType: "upper-roman" }}>
        <li>proteger la integridad del Sitio Web</li>
        <li>proteger los derechos de RH</li>
        <li>cumplir una orden judicial</li>
        <li>cumplir cualquier trámite legal</li>
        <li>
          hacer valer los derechos y acciones que asisten a RH a tenor de este
          Contrato
        </li>
        <li>
          satisfacer cualquier petición relativa a la infracción de derechos de
          terceros
        </li>
      </ol>
      <h2>5. PROHIBICIÓN GENERAL </h2>
      <p>
        Al acceder a y utilizar el Sitio Web, el Usuario se compromete a NO:
        <ol style={{ listStyleType: "lower-alpha" }}>
          <li>
            incumplir todas las leyes, reglamentos y normas aplicables a nivel
            local, estatal, provincial, nacional, así como cualquier otra
            legislación aplicable como las leyes contra la discriminación
            laboral o las leyes por la igualdad de oportunidades en el empleo,
            cuando proceda
          </li>
          <li>
            infringir los derechos de propiedad intelectual y de privacidad,
            entre otros, los derechos de patente (copyright), los derechos sobre
            la base de datos, las marcas registradas o el know how de terceros
          </li>
          <li>
            descargar, enviar, transmitir o almacenar material que: • sea
            ilegal, ofensivo, difamatorio, fraudulento, engañoso, que induzca a
            error, dañino, amenazador, hostil, obsceno o censurable; • infrinja
            las obligaciones contractuales o de confidencialidad del Usuario; •
            perjudique o interfiera en las aplicaciones normales del Sitio Web,
            como el envío o la transmisión de virus, gusanos o troyanos, el
            envío continuado de material repetido o el envío de archivos
            desacostumbradamente grandes; o • que no esté permitido por RH,
            como, por ejemplo, material publicitario no autorizado, material
            promocional no solicitado, “correo basura”, “spams”, “cartas en
            cadena”, mensajes de venta directa piramidal, franquicias, material
            de distribución, de asociación a un club, contratos de venta o
            cualquier otro material inaceptable;
          </li>
          <li>
            vulnerar los derechos personales y de privacidad de terceros
            abusando del Contenido, como, por ejemplo, acosando o molestando
            continuadamente a dichas personas enviándoles correos electrónicos
            no solicitados, o recabando información de carácter personal
          </li>
          <li>
            contravenir, o intentar contravenir, las medidas de seguridad del
            Sitio Web
          </li>
          <li>
            utilizar cualquier aparato, procedimiento o mecanismo como, por
            ejemplo, spiders y robots de rastreo, para localizar, rescatar,
            buscar, o acceder al Sitio Web o al Contenido, sin el previo
            consentimiento por escrito de parte de RH
          </li>
          <li>
            acceder o intentar acceder a la cuenta o al login de las terceras
            personas o empresas indicadas en el Sitio Web
          </li>
          <li>
            copiar, modificar, reproducir, eliminar, distribuir, descargar,
            almacenar, transmitir, vender, revender, publicar, invertir el
            proceso de creación o crear productos derivados a partir del
            Contenido, excepto en lo que concierne al material remitido por el
            propio Usuario y que es de su propiedad, o si así lo autorizan las
            leyes de propiedad intelectual aplicables
          </li>
          <li>hacerse pasar por otra persona o empresa</li>
          <li>
            utilizar el Sitio Web de forma no autorizada o para alguna actividad
            delictiva
          </li>
          <li>
            falsificar la información de cabecera en el correo electrónico
          </li>
          <li>
            falsear los datos sobre sí mismo, sobre su asociación con terceros o
            sobre su empresa
          </li>
        </ol>
        <h2>6. UTILIZACIÓN DEL SITIO Y OBLIGACIONES CONCRETAS</h2>
        <p>
          Además de las obligaciones generales señaladas en el Artículo 5, el
          Usuario deberá:
        </p>
        <ol style={{ listStyleType: "lower-alpha" }}>
          <li>
            utilizar el Sitio Web únicamente para los fines legalmente
            permitidos de manejar un consultorio homeopatico de manera virtual
          </li>
          <li>
            facilitar y mantener los datos personales que conforman la
            Información del Usuario de forma completa, correcta, actualizada y
            veraz
          </li>
          <li>
            enviar solamente material sobre el que el Usuario tenga los
            correspondientes derechos o licencia para hacerlo
          </li>
          <li>
            utilizar su propio criterio, precaución y sentido común al gestionar
            la información conseguida a través del Sitio Web
          </li>
          <li>
            asumir el riesgo de confiar en el contenido, o utilizar la
            información, facilitados por terceros.
          </li>
        </ol>
        <h2>7. VÍNCULOS A PÁGINAS WEB DE TERCEROS</h2>
        <p>
          El Sitio Web puede tener vínculos, como hipervínculos o enlaces, que
          indican el acceso a páginas Web de terceros (los “Sitios
          Relacionados”). Aunque RH revisa el material de terceros y solamente
          permite la inclusión de los Sitios Relacionados que, a su leal saber y
          entender, no infringen la legislación aplicable, RH no puede controlar
          ni realizar un seguimiento de los Sitios Relacionados y, en
          consecuencia, no asumirá responsabilidad alguna en cuanto a la
          exactitud, seguridad o fiabilidad del material, información o
          contenido incluido en dichos Sitios Relacionados. La inclusión de los
          Sitios Relacionados en el Sitio Web no implica que haya relación o
          asociación alguna entre RH y el propietario de los Sitios
          Relacionados, ni la aprobación o promoción por parte de RH de dichos
          Sitios Relacionados. RH incluye los Sitios Relacionados solamente para
          comodidad del Usuario. Este es el único responsable del acceso a los
          Sitios Relacionados. El Usuario deberá utilizar su propio buen
          criterio, precaución y sentido común a la hora desusar los Sitios
          Relacionados y, para ello, le recomendamos revisar el condicionado de
          uso y los términos que sobre privacidad rigen en dichos Sitios
          Relacionados. RH se reserva el derecho de eliminar de su Sitio Web
          todos los Sitios Relacionados de los que tenga conocimiento real que
          están infringiendo derechos de terceros y/o cuyo contenido vulnere la
          legislación aplicable, o si así se lo exige una orden judicial o una
          ordenanza administrativa. Los vínculos a nuestra página Web no están
          permitidos sin la expresa autorización, previa y por escrito, de RH.
          El Usuario no podrá, ni solo ni en colaboración con otras personas,
          ampliar, modificar, sustituir, enmendar o limitar, en todo o en parte,
          la información ofrecida en nuestra página Web ni las aplicaciones
          funcionales de la misma. Además, el Usuario no podrá, ni solo ni en
          colaboración con otras personas, crear o instalar vínculos desde su
          propia página Web o desde webs de terceros, a la presente página Web
          (ya sea mediante vínculos de hipertexto, deep-linking, enmarcado,
          mediante códigos o de otra forma), ni en todo ni en parte, sin el
          previo consentimiento por escrito en este sentido de parte de RH.
        </p>
        <h2>8. PRIVACIDAD</h2>
        <p>
          RH procesará toda la información personal que el Usuario introduzca o
          facilite a través de nuestro Sitio Web de conformidad con la Política
          de Privacidad del Sitio Web y los términos y condiciones relativos a
          la misma en este contrato.
        </p>
        <h2>9. COOKIES</h2>
        <p>
          ¿Qué son las cookies? En este sitio web utilizamos cookies. Las
          cookies son archivos de texto que contienen pequeñas cantidades de
          información, que se descargan en su computadora o dispositivo móvil
          cuando usted visita una página web. A cada nueva visita a la misma
          página, los datos almacenados son recuperados por el servidor de
          origen. Son útiles porque permiten que un sitio web reconozca el
          equipo del usuario. Las cookies no pueden transmitir virus y tampoco
          dañan su computadora. Cookies de rendimiento Estas cookies nos
          permiten guardar el estado de su repertorización de modo que no se
          tenga que cargar la misma informacion en cada nueva visita
        </p>
        <h2>10. INDEMNIZACIÓN</h2>
        El Usuario se compromete a indemnizar, defender en pleito y exonerar de
        toda responsabilidad a RH, a la empresa matriz y a las empresas
        subsidiarias y afiliadas de ésta, así como a los directivos, encargados,
        comerciales y empleados de todas ellas, frente a las reclamaciones de
        terceros (en la indemnización se incluyen, entre otros, los gastos de
        los abogados intervinientes y los costos judiciales) interpuestas con
        base en, o derivadas de, el acceso por parte del Usuario al Sitio Web y
        el uso que este haga del mismo, la Información facilitada por el
        Usuario, o el incumplimiento por parte de este de los términos y
        condiciones de este contrato.
      </p>
      <h2>11. DESCARGO DE RESPONSABILIDAD</h2>
      <p>
        EL USUARIO RECONOCE Y ACEPTA QUE: (A) EL USUARIO ASUME TODOS LOS RIESGOS
        RELATIVOS A, O DERIVADOS DE, EL USO, CONSULTA O ACCESO POR SU PARTE AL
        SITIO WEB. EL SITIO WEB SE FACILITA "COMO ESTÁ" Y "SEGÚN
        DISPONIBILIDAD", SIN GARANTÍA ALGUNA; (B) DENTRO DE LOS LÍMITES LEGALES,
        RH DECLINA EXPRESAMENTE Y EN ESTE ACTO, TODA GARANTÍA O RESPONSABILIDAD,
        EXPRESA O IMPLÍCITA, LEGAL O DE OTRO TIPO, INCLUIDAS, ENTRE OTRAS: LAS
        GARANTÍAS IMPLÍCITAS DE CALIDAD SATISFACTORIA, IDONEIDAD PARA EL USO
        CONCRETO, PROHIBICIÓN DE USO INDEBIDO Y LAS HABITUALES EN LA PRÁCTICA
        COMERCIAL O EN LAS NEGOCIACIONES EN ESTE SECTOR; Y (C) AUNQUE RH TIENE
        SISTEMAS ANTI-VIRUS Y ADOPTA MEDIDAS DE SEGURIDAD PARA PROTEGER EL SITIO
        WEB Y SU CONTENIDO DE TODO TIPO DE ATAQUES INFORMÁTICOS CON EL FIN DE
        REDUCIR EL NIVEL DE RIESGO, EN ESTE ACTO RH DECLINA EXPRESAMENTE TODA
        GARANTÍA RESPECTO A: (I) QUE EL SITIO WEB Y SU CONTENIDO ESTARÁ SIEMPRE
        LIBRE DE ERRORES O VIRUS O NO SUFRIRÁ NUNCA ATAQUES DE TERCEROS; (II) EL
        FUNCIONAMIENTO ININTERRUMPIDO Y SIEMPRE SEGURO DEL SITIO WEB; (III) LA
        PERMANENTE DISPONIBILIDAD DEL SITIO; (IV) QUE EL SITIO WEB CUBRA O NO
        LAS NECESIDADES DEL USUARIO; Y (V) LA FIABILIDAD, EXACTITUD, INTEGRIDAD,
        VALIDEZ O VERACIDAD DE LA INFORMACIÓN FACILITADA POR EL USUARIO. 14.
        RESPONSABILIDAD Y LIMITACIÓN DE RESPONSABILIDAD EL USUARIO ACEPTA ASUMIR
        TODOS LOS RIESGOS ASOCIADOS CON, O DERIVADOS DE, EL USO DEL SITIO WEB O
        LA INFORMACIÓN FACILITADA POR EL USUARIO, INCLUYENDO, ENTRE OTROS, LOS
        RIESGOS DE PÉRDIDAS ECONÓMICAS, LOS DAÑOS A LA PROPIEDAD Y LOS GASTOS DE
        NEGOCIACIÓN CON OTROS USUARIOS DEL SITIO WEB (YA SEAN DESCONOCIDOS,
        MENORES, EXTRANJEROS O PERSONAS QUE ACTÚAN BAJO FALSA IDENTIDAD). EL
        USUARIO SE COMPROMETE TAMBIÉN A EXONERAR DE TODA RESPONSABILIDAD A RH, A
        LA EMPRESA MATRIZ Y A LAS EMPRESAS SUBSIDIARIAS Y AFILIADAS DE ESTA, ASÍ
        COMO A LOS DIRECTIVOS, ENCARGADOS, COMERCIALES Y EMPLEADOS DE TODAS
        ELLAS, FRENTE A LAS RECLAMACIONES, DEMANDAS O RECLAMACIONES DE
        INDEMNIZACIÓN POR DAÑOS Y PERJUICIOS (DIRECTOS, INDIRECTOS Y
        SOBREVENIDOS) DE CUALQUIER TIPO, CONOCIDAS O NO, QUE PUEDAN INTERPONERSE
        CON BASE EN, O QUE SE DERIVEN DEL USO DEL SITIO WEB, LA INFORMACIÓN
        FACILITADA POR EL USUARIO O LAS TRANSACCIONES QUE SE PRODUZCAN COMO
        RESULTADO DEL USO DEL SITIO WEB POR PARTE DEL USUARIO. SIEMPRE DENTRO DE
        LOS LÍMITES LEGALES, EL USUARIO ASUME Y ACEPTA QUE EN NINGÚN CASO, NI RH
        NI SU EMPRESA MATRIZ, LAS EMPRESAS SUBSIDIARIAS Y AFILIADAS DE ESTA, NI
        TAMPOCO SUS DIRECTIVOS, LOS COMERCIALES, LOS EMPLEADOS O LOS PROVEEDORES
        DE TODAS ELLAS, ASUMIRÁN RESPONSABILIDAD ALGUNA POR LAS PÉRDIDAS O LOS
        DAÑOS DIRECTOS, INDIRECTOS O SOBREVENIDOS (INCLUIDOS, ENTRE OTROS, LOS
        DAÑOS A LA PROPIEDAD Y LOS DAÑOS PURAMENTE ECONÓMICOS), NI POR LUCRO
        CESANTE, PÉRDIDA DE CARTERA DE CLIENTES, PÉRDIDA DE INGRESOS O DE DATOS
        O POR INTERRUPCIÓN DE USO, SEA CUAL SEA LA CAUSA QUE LOS HAYA PROVOCADO
        - INCUMPLIMIENTO CONTRACTUAL, RESPONSABILIDAD EXTRACONTRACTUAL (INCLUIDA
        LA NEGLIGENCIA) O INCUMPLIMIENTO DE GARANTÍA-, INCLUSO AUNQUE RH HUBIERA
        SIDO ADVERTIDA DE LA POSIBILIDAD DE OCURRENCIA DE DICHAS PÉRDIDAS O
        DAÑOS. EN CASO DE QUE EN ALGUNA JURISDICCIÓN SE PROHIBA LA EXCLUSIÓN DE
        CIERTAS GARANTÍAS, LA LIMITACIÓN DE RESPONSABILIDAD O EL DESCARGO DE
        RESPONSABILIDAD RESPECTO A CIERTOS DAÑOS, LA RESPONSABILIDAD ACUMULADA
        DE RH POR DAÑOS NO EXCEDERÁ DE USD$10.00 (DIEZ DÓLARES DE LOS ESTADOS
        UNIDOS DE AMÉRICA), SI ASÍ LO PERMITE LA LEGISLACIÓN APLICABLE. EN
        CUALQUIER CASO, LA ANTERIOR LIMITACIÓN DE RESPONSABILIDAD NO SE APLICARÁ
        A LOS CONSUMIDORES NI RESPECTO A LOS DAÑOS CAUSADOS DE FORMA
        INTENCIONADA O NEGLIGENCIA GRAVE.
      </p>
      <h2>12. RESCISIÓN</h2>
      <p>
        {" "}
        RH podrá, a su entera discreción, dar por terminados los servicios
        ofrecidos en su Sitio Web y retirar cualquier Contenido del mismo. RH
        podrá también, en cualquier momento, por cualquier motivo justificado o
        no y sin tener que notificar de forma anticipada su decisión, suspender
        el acceso del Usuario a cualquiera de, o a todos, los servicios
        ofrecidos en el Sitio Web. En caso de que el Usuario decida anular su
        cuenta, deberá dejar de acceder a, y no utilizar más, el Sitio Web. RH
        no tendrá obligación alguna de mantener o devolver la Información
        facilitada por el Usuario, su cuenta, su número de cuenta virtual y su
        contraseña. El Usuario deberá disponer siempre de una copia de la
        Información por él facilitada. 16. GENERAL El Usuario declara que entre
        él y RH no existe relación alguna de contratación laboral, asociación,
        agencia o empresa conjunta, derivadas del acceso y uso del Sitio Web. El
        presente Contrato integra todos los acuerdos a que el Usuario y RH han
        llegado en relación el acceso y uso del Sitio Web, y se suscribe de
        forma adicional a cualquier acuerdo vinculante que pueda existir entre
        el Usuario y RH. El Usuario no podrá utilizar ni exportar el Contenido
        del Sitio Web ni efectuar copia o adaptación alguna que infrinja las
        leyes, reglamentos o normas aplicables, entre las que se incluyen las
        leyes y reglamentos de exportación de los EE.UU. El hecho de que una de
        las partes no ejercite en un momento dado o se retrase en el ejercicio
        de los derechos y acciones que le asisten en virtud del presente
        Contrato, no podrá interpretarse como una renuncia a los mismos. La
        invalidez o inaplicabilidad de cualquiera de los presentes términos y
        condiciones -en todo o en parte- o de los derechos derivados de este
        Contrato, no afectará la validez o la aplicabilidad de los demás
        derechos, términos y condiciones, o al resto de la disposición en
        cuestión, que continuarán en pleno vigor y efectos, quedando excluida
        solamente la cláusula viciada (o la parte que se invalida en dicha
        cláusula).
      </p>
    </div>
  );
};

export default Tyc;

import React, { useState, useEffect } from "react";
import axios from "axios";
import { Row, Col, Button, Table, Form, ListGroup } from "react-bootstrap";

const RelacionMedicamentos = ({ id }) => {
  const [relacion, setRelacion] = useState(null);
  const [agregar, setAgregar] = useState(false);
  const [med, setMed] = useState(null);
  const [medFiltered, setMedFiltered] = useState([]);
  const [selectedMed, setSelectedMed] = useState(null);
  const [search, setSearch] = useState("");
  const [valor, setValor] = useState(1);

  const fetchRelacion = async () => {
    const response = await axios.get(`/api/valor/${id}/nombre`);
    setRelacion(response.data);
  };

  const fetchData = async () => {
    fetchRelacion();

    const medicamentos = await axios.get("/api/medicamentos/nombres");
    setMed(medicamentos.data);
    setMedFiltered(medicamentos.data);
  };

  useEffect(() => {
    fetchData();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const handleSubmit = (evt) => {
    evt.preventDefault();
  };

  const handleChange = (evt) => {
    const value = evt.target.value;

    setSearch(value);
    if (value.length > 0) {
      setMedFiltered(
        med.filter((med) =>
          med.medicamento.toLowerCase().includes(`${value}`.toLowerCase())
        )
      );
    } else {
      setMedFiltered(med);
    }
  };

  const searchMed = () => {
    return (
      <Form onSubmit={handleSubmit}>
        <Form.Group>
          <Form.Label>Buscar Medicamento</Form.Label>
          <Form.Control type="text" onChange={handleChange} value={search} />
        </Form.Group>
      </Form>
    );
  };

  const medList = () => {
    return (
      <ListGroup style={{ maxHeight: "300px", overflowY: "scroll" }}>
        {medFiltered.map((item) => {
          return (
            <ListGroup.Item
              key={item.codMedicamento}
              className={item === selectedMed ? "active" : null}
              action
              onClick={() => {
                setSelectedMed(item);
              }}
            >
              {item.medicamento}
            </ListGroup.Item>
          );
        })}
      </ListGroup>
    );
  };

  const handleSubmitValor = async (evt) => {
    evt.preventDefault();
    const newRel = {
      valor: parseInt(valor),
      codMed: selectedMed.codMedicamento,
      codRep: parseInt(id),
    };

    try {
      const res = await axios.post("/api/valor", newRel);
      alert(res.statusText);
      fetchRelacion();
    } catch (err) {
      alert("El medicamento seleccionado ya está relacionado con el sintoma");
    }
  };

  const handleChangeValor = (evt) => {
    setValor(evt.target.value);
  };

  const newValor = () => {
    if (selectedMed) {
      return (
        <Row>
          <Col>{selectedMed.medicamento}</Col>
          <Col>
            <Form onSubmit={handleSubmitValor}>
              <Form.Group>
                <Form.Label>Asignar valor</Form.Label>
                <Form.Control as="select" custom onChange={handleChangeValor}>
                  <option value={1}>1</option>
                  <option value={2}>2</option>
                  <option value={3}>3</option>
                </Form.Control>
              </Form.Group>
              <Button variant="primary" type="submit">
                Guardar
              </Button>
            </Form>
          </Col>
        </Row>
      );
    }
    return null;
  };

  if (relacion) {
    return (
      <Row>
        <Col sm={12}>
          <p style={{ fontWeight: "bold" }}>
            Relacion con Medicamentos Homeopaticos
          </p>
          <Table striped bordered hover>
            <thead>
              <tr>
                <th>Medicamento</th>
                <th>Valor</th>
              </tr>
            </thead>
            <tbody>
              {relacion.map((item) => {
                return (
                  <tr key={item.nombre}>
                    <td>{item.nombre}</td>
                    <td>{item.valor}</td>
                  </tr>
                );
              })}
            </tbody>
          </Table>

          <Button
            variant="warning"
            onClick={() => {
              setAgregar(!agregar);
            }}
          >
            Relacionar con Medicamentos de Homeopatia
          </Button>
          {agregar ? (
            <div>
              {searchMed()}
              {medList()}
              {newValor()}
            </div>
          ) : null}
        </Col>
      </Row>
    );
  }
  return null;
};

export default RelacionMedicamentos;

import React, { useState, useEffect } from 'react';
import axios from 'axios';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEdit, faTimesCircle } from '@fortawesome/free-solid-svg-icons';
import { Button, Form } from 'react-bootstrap';
import StyledLink from '../../general/StyledLink';

const SindromePadre = ({ id }) => {
  const [editar, setEditar] = useState(false);
  const [padres, setPadres] = useState(null);
  const [listado, setListado] = useState(null);
  const [nuevoPadre, setNuevoPadre] = useState(1);

  const fetchData = async () => {
    const { data } = await axios.get('/api/nombres/sindromes');
    setListado(data);

    const enfermedadPadre = await axios.get(`/api/sindrome/${id}/padre`);
    setPadres(enfermedadPadre.data.padres);
  };

  useEffect(() => {
    fetchData();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const renderFA = (icon) => {
    return (
      <Button
        onClick={() => {
          setEditar(!editar);
        }}
        variant="primary-outline"
      >
        <FontAwesomeIcon icon={icon} />
      </Button>
    );
  };

  const handleSubmit = async (evt) => {
    evt.preventDefault();
    try {
      if (padres === 'Es una enfermedad Raíz') {
        await axios.patch(`/api/sindrome/${id}/padre`, {
          nuevoPadre,
          anterior: padres,
        });
      } else {
        await axios.patch(`/api/sindrome/${id}/padre`, {
          nuevoPadre,
          anterior: padres[1].codPadre,
        });
      }

      alert('cambio exitoso');
    } catch (err) {
      alert('Se presento un error vuelva a intentar');
    }
  };

  const handleChange = (evt) => {
    setNuevoPadre(evt.target.value);
  };

  const newPadre = () => {
    if (listado) {
      return (
        <Form onSubmit={handleSubmit}>
          <Form.Group>
            <Form.Control as="select" custom onChange={handleChange}>
              {listado.map((sindrome) => {
                return (
                  <option
                    value={sindrome.codSindrome}
                    key={sindrome.codSindrome}
                  >
                    {sindrome.nombre}
                  </option>
                );
              })}
            </Form.Control>
          </Form.Group>
          <Button variant="primary" type="submit">
            Cambiar Padre
          </Button>
        </Form>
      );
    }
    return null;
  };

  const renderListaPadres = () => {
    if (padres === 'Es una enfermedad Raíz') {
      return padres;
    }
    return padres.reverse().map(({ codPadre, nombre }) => {
      return (
        <a href={`/admin/Sindrome/${codPadre}`} key={codPadre}>
          {nombre} <span> / </span>
        </a>
      );
    });
  };

  if (padres) {
    return (
      <dl>
        <dt>
          Sindrome Padre
          {renderFA(editar ? faTimesCircle : faEdit)}
        </dt>
        {editar ? newPadre() : <dd>{renderListaPadres()}</dd>}
      </dl>
    );
  }
  return null;
};

export default SindromePadre;

import React, { useState, useEffect } from "react";
import axios from "axios";
import { Row, Col } from "react-bootstrap";

import Campo from "../detalle/Campo";
import RelacionSintoma from "./RelacionSintoma";
import StyledLink from "../../general/StyledLink";

const DetalleSintomaAcupuntura = ({ id }) => {
  const [detalle, setDetalle] = useState(null);

  const fetchData = async () => {
    const response = await axios.get(`/api/Sintoma/${id}`);
    setDetalle(response.data);
  };

  useEffect(() => {
    fetchData();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const validateForm = () => {
    return {};
  };

  if (detalle) {
    const camposNombre = [
      {
        id: "nombre",
        type: "text",
        placeholder: detalle.nombre,
      },
    ];
    return (
      <Row>
        <Col sm={12}>
          <StyledLink to="/admin/Sintoma">
            Listado Sintomas Acupuntura
          </StyledLink>
          <h1>Sintoma Acupuntura: {id}</h1>
          <br />
        </Col>
        <Col sm={12} md={6} lg={4}>
          <Campo
            titulo={"nombre Sintoma"}
            detail={detalle.nombre}
            editable={true}
            campos={camposNombre}
            validateForm={validateForm}
            apiRoute={`/api/sintoma/${id}`}
          />
        </Col>
        <Col sm={{ span: 11, offset: 1 }}>
          <RelacionSintoma id={id} />
        </Col>
      </Row>
    );
  }
  return <div>Cargando</div>;
};

export default DetalleSintomaAcupuntura;

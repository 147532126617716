import React from "react";
import { Route, Redirect } from "react-router-dom";

import useAuth from "../../../customHooks/useAuth";

const ProtectedRoute = ({ component: Component, ...rest }) => {
  const { isAuth } = useAuth();
  if (isAuth === true || isAuth === false) {
    return (
      <Route
        {...rest}
        render={(props) =>
          isAuth ? <Component {...props} /> : <Redirect to="/login" />
        }
      />
    );
  }
  return null;
};

export default ProtectedRoute;

import React from "react";
import { Col, Row } from "react-bootstrap";

const Cita = ({ data }) => {
  const timestamp = data._id.toString().substring(0, 8);
  const date = new Date(parseInt(timestamp, 16) * 1000);

  return (
    <React.Fragment>
      <Row>
        <Col>
          <h3>Registro Control Cita</h3>
          <p className="text-muted">{date.toString()}</p>
        </Col>
      </Row>
      <Row>
        <Col>
          <dl>
            <dt>Motivo consulta</dt>
            <dd>{data.motivo}</dd>
          </dl>
        </Col>
      </Row>

      <Row>
        <Col>
          <dl>
            <dt>Examen fisico por sistemas</dt>
          </dl>
          {data.examenSistemas.map(({ sistema, detalle }) => {
            if (sistema !== "general")
              return (
                <Row key={sistema}>
                  <Col
                    xs={{ offset: 1, span: 11 }}
                    style={{ fontWeight: "bold" }}
                  >
                    {sistema}
                  </Col>
                  <Col xs={{ offset: 2 }}>{detalle}</Col>
                </Row>
              );
            else
              return (
                <React.Fragment key={sistema}>
                  <Row>
                    <Col
                      xs={{ offset: 1, span: 11 }}
                      style={{ fontWeight: "bold" }}
                    >
                      {sistema}
                    </Col>
                    <Col
                      xs={{ offset: 2, span: 10 }}
                      style={{ fontWeight: "bold" }}
                    >
                      Estado general
                    </Col>
                    <Col xs={{ offset: 2, span: 10 }}>{detalle.estado}</Col>
                  </Row>
                  <Row>
                    <Col xs={{ offset: 2 }} style={{ fontWeight: "bold" }}>
                      Peso
                    </Col>
                    <Col>{detalle.peso} Kilogramos</Col>

                    <Col xs={{ offset: 1 }} style={{ fontWeight: "bold" }}>
                      Talla
                    </Col>
                    <Col>{detalle.talla} metros</Col>

                    <Col xs={{ offset: 1 }} style={{ fontWeight: "bold" }}>
                      Indice de masa corporal
                    </Col>
                    <Col>{Math.round(detalle.imc * 100) / 100}</Col>
                  </Row>
                  <Row key={sistema}>
                    <Col xs={{ offset: 1 }}></Col>
                    {detalle.tension ? (
                      <Col xs={{ offset: 1 }} style={{ fontWeight: "bold" }}>
                        Tensión
                      </Col>
                    ) : null}
                    {detalle.tension ? (
                      <Col>{detalle.tension} mm de Hg.</Col>
                    ) : null}

                    {detalle.frecCardiaca ? (
                      <Col xs={{ offset: 1 }} style={{ fontWeight: "bold" }}>
                        Frecuencia cardiaca
                      </Col>
                    ) : null}
                    {detalle.frecCardiaca ? (
                      <Col>{detalle.tension} Pulsaciones/minuto.</Col>
                    ) : null}

                    {detalle.frecRespiratoria ? (
                      <Col xs={{ offset: 1 }} style={{ fontWeight: "bold" }}>
                        Frecuencia Respiratoria
                      </Col>
                    ) : null}
                    {detalle.frecRespiratoria ? (
                      <Col>
                        {detalle.frecRespiratoria} respiraciones/minuto.
                      </Col>
                    ) : null}

                    {detalle.temperatura ? (
                      <Col xs={{ offset: 1 }} style={{ fontWeight: "bold" }}>
                        Temperatura
                      </Col>
                    ) : null}
                    {detalle.temperatura ? (
                      <Col>{detalle.temperatura} °C</Col>
                    ) : null}
                  </Row>
                  {detalle.pulso ? (
                    <Row>
                      <Col
                        xs={{ offset: 2, span: 10 }}
                        style={{ fontWeight: "bold" }}
                      >
                        Pulso
                      </Col>
                      <Col xs={{ offset: 2, span: 10 }}>{detalle.pulso}</Col>
                    </Row>
                  ) : null}
                  {detalle.lengua ? (
                    <Row>
                      <Col
                        xs={{ offset: 2, span: 10 }}
                        style={{ fontWeight: "bold" }}
                      >
                        Lengua
                      </Col>
                      <Col xs={{ offset: 2, span: 10 }}>{detalle.lengua}</Col>
                    </Row>
                  ) : null}
                  {detalle.tez ? (
                    <Row>
                      <Col
                        xs={{ offset: 2, span: 10 }}
                        style={{ fontWeight: "bold" }}
                      >
                        Tez
                      </Col>
                      <Col xs={{ offset: 2, span: 10 }}>{detalle.tez}</Col>
                    </Row>
                  ) : null}
                </React.Fragment>
              );
          })}
        </Col>
      </Row>

      <Row>
        <Col>
          <dl>
            <dt>Examen Energético</dt>
            <dd>{data.examenEnergetico}</dd>
          </dl>
        </Col>
      </Row>

      <Row>
        <Col>
          <dl>
            <dt>Diagnostico</dt>
            <dd>{data.diagnostico}</dd>
          </dl>
        </Col>
      </Row>

      <Row>
        <Col>
          <dl>
            <dt>Comentarios</dt>
            <dd>{data.comentario}</dd>
          </dl>
        </Col>
      </Row>
    </React.Fragment>
  );
};

export default Cita;
